import React, { useCallback, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { showCalendarSettings } from "../../redux/settings/settings.actions";

import { motion } from "framer-motion";

import BoxLayer from "../../components/retro/box-layer/box-layer.component";
import MiniMenu from "../../components/retro/mini-menu/mini-menu.component";

import Colors from "../../components/colors/colors.component";

import {
  BsPlusLg,
  BsList,
  BsClock,
  BsClockFill,
  BsCheckCircle,
  BsCheckCircleFill,
  BsPrinter,
  BsCalendar2Week,
  BsCalendar2WeekFill,
  BsGoogle,
  BsApple,
  BsCheckLg,
  BsXCircle,
  BsGlobeAmericas,
  BsQuestionCircleFill,
  BsThreeDotsVertical,
  BsTrash3Fill,
} from "react-icons/bs";

import { FaICursor, FaFillDrip } from "react-icons/fa";

import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
import iCalendarPlugin from "@fullcalendar/icalendar";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import moment from "moment";
// import momentTzPlugin from "@fullcalendar/moment-timezone";

// import CalDavPlugin from "./caldav"

import { db, fieldValue } from "../../firebase/firebase.utils";

import IntegrateCal from "../../components/calendar/integrate-gcal.component";

import "./calendar.styles.scss";

import EventModal from "./EventModal/EventModal";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

// import Tooltip from "../../components/retro/tooltip/tooltip.component";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import {
  createNewTask,
  changeStatusTypeName,
  toggleStatus,
} from "../../firebase/firebase.utils";
// import { duration } from "moment";

import { TimeZone, aryIannaTimeZones } from "./timezones/timezones";

const CalendarView = ({ station, stationTasks, stationTasksFL }) => {
  const users = useSelector((state) => state.user.users);
  const settingsOn = useSelector(
    (state) => state.settings.showCalendarSettings
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const currentUser = useSelector((state) => state.user.currentUser);

  const spaceData = useSelector((state) => state.space.spaceData);
  const stationData = useSelector((state) => state.space.stationData);

  const [currentStation, setCurrentStation] = useState({});

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [activeEvent, setActiveEvent] = useState({});
  const [events, setEvents] = useState([]);

  const [otherProjects, setOtherProjects] = useState(false);
  const [showGCal, setShowGCal] = useState(true);
  const [showGCalModal, setShowGCalModal] = useState(false);
  const [showICalModal, setShowICalModal] = useState(false);

  const [iCalUrl, setICalUrl] = useState("");
  const [gCalId, setGCalId] = useState("");
  const [gCalName, setGCalName] = useState("");
  const [gCalColor, setGCalColor] = useState("#f6b73c");
  const [gCalType, setGcalType] = useState("project");

  const [personalGcals, setPersonalGcals] = useState([]);
  const [projectGcals, setProjectGcals] = useState([]);
  const [personalIcals, setPersonalIcals] = useState([]);
  const [projectIcals, setProjectIcals] = useState([]);

  const [calsToShow, setCalstoShow] = useState([]);
  const [holidayCals, setHolidayCals] = useState(["US"]);

  const [activeCalShow, setActiveCalShow] = useState(false);

  const [activeCalStart, setActiveCalStart] = useState("00:00:00");
  const [activeCalEnd, setActiveCalEnd] = useState("24:00:00");

  const [showWeekend, setShowWeekend] = useState(true);

  const [timeZone, setTimeZone] = useState("local");
  const [zoneVal, setZoneVal] = useState(TimeZone.UTC);
  const [timezones, setTimeZones] = useState([]);

  const [showMiniMenu, setShowMiniMenu] = useState(false);
  const [showColors, setShowColors] = useState(false);

  const eventspacenameRef = useRef("spacename");
  const eventstationnameRef = useRef("stationname");
  const calendarRef = useRef();
  const calendarDatesRef = useRef();

  const [color, setColor] = useState("");
  const [defColor, setDefColor] = useState("");

  const [calListOpen, setCalListOpen] = useState(true);

  useEffect(() => {
    if (!stationData) return;
    const data = stationData.find(
      (item) => item.stationId === currentStationId
    );
    setCurrentStation(data);
    if (data.googleCals) {
      setProjectGcals(data.googleCals);
    }
    if (data.iCals) {
      setProjectIcals(data.iCals);
    }
    setTimeZones(data.timezones);
  }, [stationData, currentStationId]);

  useEffect(() => {
    //mounts
    if (currentUser.googleCals) {
      setPersonalGcals(currentUser.googleCals);
    }
    if (currentUser.iCals) {
      setPersonalIcals(currentUser.iCals);
    }
  }, [currentSpaceId, currentStationId, stationData]);

  useEffect(() => {
    setState(station);
  }, [station]);

  const [showSettings, setShowSettings] = useState(false);
  useEffect(() => {
    if (settingsOn) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [settingsOn]);

  const handleCloseSettings = () => {
    dispatch(showCalendarSettings());
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`);
  };

  const [isOpen, SetIsOpen] = useState(false);
  const [x, setX] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setX(0);
    } else {
      setX("-170px");
    }
  }, [isOpen]);

  //Draggable Settings

  useEffect(() => {
    let containerEl = document.getElementById("external-events");

    // // initialize the external events

    new Draggable(containerEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        return {
          id: eventEl.getAttribute("id"),
          title: eventEl.getAttribute("title"),
          start: eventEl.getAttribute("start"),
          end: eventEl.getAttribute("end"),
          duration: eventEl.getAttribute("duration"),
        };
      },
    });
  }, []);

  const handleEventClick = (clickInfo) => {
    // const { id } = event.toPlainObject();
    handleOpenModal(
      "edit",
      {
        id: clickInfo.event.id,
        title: clickInfo.event.title,
        description: clickInfo.event.extendedProps.description,
        done: clickInfo.event.extendedProps.done,
        startdate: clickInfo.event.start,
        enddate: clickInfo.event.end,
        allDay: clickInfo.event.allDay,
        duration: clickInfo.event.extendedProps.durationn,
        createdBy: clickInfo.event.extendedProps.createdBy,
        createdAt: clickInfo.event.extendedProps.createdAt,
        assign: clickInfo.event.extendedProps.assign,
        phase: clickInfo.event.extendedProps.phase,
        priority: clickInfo.event.extendedProps.priority,
        progress: clickInfo.event.extendedProps.progress,
        type: clickInfo.event.extendedProps.type,
        fromSpaceId: clickInfo.event.extendedProps.fromSpaceId,
        fromStationId: clickInfo.event.extendedProps.fromStationId,
      }
    );
  };

  const handleSelect = (selectionInfo) => {
    navigate("addtask", {
      state: {
        startdate: selectionInfo.start,
        startTime: selectionInfo.startDateTime,
        enddate: selectionInfo.end,
        endTime: selectionInfo.endDateTime,
      },
    });
  };

  const handleSaveEvent = (mode, event) => {
    if (mode === "add") {
      setEvents([...events, { id: events.length + 1, ...event }]);
    } else {
      setEvents(events.map((e) => (e.id === event.id ? event : e)));
    }
    handleCloseModal();
  };

  const handleDeleteEvent = (event) => {
    setEvents(events.filter((e) => e.id !== event.id));
    handleCloseModal();
  };


  const [timelesstasks, setTimelesstasks] = useState([]);

  const transformTimelessTasks = () => {
    const array = stationTasksFL
      .filter((t) => t.startdate === null)
      .map((task) => {
        return {
          id: task.id,
          title: task.title,
          allDay: task.allDay,
          duration: task.duration,
        };
      });
    setTimelesstasks([...array]);
  };

  const [taken, setTaken] = useState([]);


  const transformTasks = () => {
    const array = stationTasksFL.map((task) => {
      return {
        id: task.id,
        title: task.title,
        description: task.description,
        done: task.done,
        start: task.startdate?.toDate(),
        end: task.enddate?.toDate(),
        allDay: task.allDay,
        durationn: task.duration,
        createdBy: task.createdBy,
        createdAt: task.createdAt,
        assign: task.assign,
        phase: task.phase,
        priority: task.priority,
        progress: task.progress,
        type: task.type,
        rrule: task.rrule,
        // className: "this_project-items",
        // color: "#2c2e33",
        fromSpaceId: task.fromSpaceId,
        fromStationId: task.fromStationId,
        backgroundColor: spaceData
          .filter((space) => space.spaceId === task.fromSpaceId)
          .map((data) => data.color),
        borderColor: spaceData
          .filter((space) => space.spaceId === task.fromSpaceId)
          .map((data) => data.color),
      };
    });
    setTaken([...array]);
  };

  useEffect(() => {
    transformTasks();
    transformTimelessTasks();
  }, [stationTasksFL]);

  const [assignedTasks, setAssignedTasks] = useState([]);
  const [assignedTaskColors, setAssignedTaskColors] = useState([]);

  useEffect(() => {
    if (!currentUser) return;
    var newtasks = db
      .collectionGroup("tasks")
      .where("assign", "==", currentUser.uid);
    // .where("fromStationId", "!=", currentStationId);
    newtasks.get().then((querySnapshot) => {
      let list = [];
      let listcolor = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().fromStationId != currentStationId) {
          db.collection("space")
            .doc(doc.data().fromSpaceId)
            .onSnapshot((spaceData) => {
              console.log("SPACEDATA is,", spaceData);
              if (spaceData.exists) {
                listcolor.push(spaceData.data().color);
              }
            });
          list.push(doc.data());
        }
      });
      setAssignedTaskColors(listcolor);
      setAssignedTasks(list);
      // setAssignedColSpaces(listspace);
    });
  }, [currentUser]);


  const [trassignedTasks, setTrAssignedTasks] = useState([]);
  useEffect(() => {
    const assignedTasksTransformed = assignedTasks.map((astask) => {
      return {
        id: astask.id,
        title: astask.title,
        description: astask.description,
        done: astask.done,
        start: astask.startdate != null && astask.startdate.toDate(),
        end: astask.enddate != null && astask.enddate.toDate(),
        allDay: astask.allDay,
        durationn: astask.duration,
        createdBy: astask.createdBy,
        createdAt: astask.createdAt,
        assign: astask.assign,
        phase: astask.phase,
        priority: astask.priority,
        progress: astask.progress,
        type: astask.type,
        // color: "#909296",
        backgroundColor: spaceData
          .filter((space) => space.spaceId === astask.fromSpaceId)
          .map((data) => data.color),
        borderColor: spaceData
          .filter((space) => space.spaceId === astask.fromSpaceId)
          .map((data) => data.color),
        // className: "other_project-items",
        fromSpaceId: astask.fromSpaceId,
        fromStationId: astask.fromStationId,
      };
    });
    setTrAssignedTasks([...assignedTasksTransformed]);
  }, [assignedTasks]);

  const [fromSpaceName, setFromSpaceName] = useState();
  const [fromStationName, setFromStationName] = useState();
  const [fromStationEmoji, setFromStationEmoji] = useState();
  const [fromSpaceColor, setFromSpaceColor] = useState();


  const handleDateClick = (e) => {
    if (e.jsEvent.altKey) {
      const title = prompt("Enter title", e.dateStr);
      const event = {
        title: title ? title : e.dateStr,
        startdate: e.date,
        allDay: true,
        duration: "",
        phase: null,
      };

      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("tasks")
        .add(event);
    }
  };

  const handleEventResize = (checkInfo) => {
    const id = checkInfo.event.id;
    db.collection("space")
      .doc(checkInfo.event.extendedProps.fromSpaceId)
      .collection("stations")
      .doc(checkInfo.event.extendedProps.fromStationId)
      .collection("tasks")
      .doc(id)
      .update({
        enddate: checkInfo.event.end,
      });
  };

  const handleEventDrop = (checkInfo) => {
    const id = checkInfo.event.id;
    db.collection("space")
      .doc(checkInfo.event.extendedProps.fromSpaceId)
      .collection("stations")
      .doc(checkInfo.event.extendedProps.fromStationId)
      .collection("tasks")
      .doc(id)
      .update({
        startdate: checkInfo.event.start,
        enddate: checkInfo.event.end,
      });
  };

  const handleEventReceive = (checkInfo) => {
    const id = checkInfo.event.id;
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(id)
      .update({
        startdate: checkInfo.event.start,
        enddate: checkInfo.event.end,
      });
  };
  const handleGCalSave = () => {
    const gcalObject = { name: gCalName, id: gCalId, color: gCalColor };
    if (gCalType === "personal" || "both") {
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          googleCals: fieldValue.arrayUnion(gcalObject),
        });
    }
    if (gCalType === "project" || "both") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          googleCals: fieldValue.arrayUnion(gcalObject),
        });
    }
    handleCloseSettings();
    setShowGCal(true);
  };
  const handleGCalRemove = (gCal, type) => {
    if (type === "personal" || "both") {
      const gcalObject = personalGcals.find(
        (g) => g.googleCalendarId === gCal.googleCalendarId
      );
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          googleCals: fieldValue.arrayRemove(gcalObject),
        });
    }
    if (type === "project" || "both") {
      const gcalObject = projectGcals.find(
        (g) => g.googleCalendarId === gCal.googleCalendarId
      );
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          googleCals: fieldValue.arrayRemove(gcalObject),
        });
    }
  };
  const handleICalSave = () => {
    const icalObject = { name: gCalName, id: iCalUrl, color: gCalColor };
    if (gCalType === "personal" || "both") {
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          iCals: fieldValue.arrayUnion(icalObject),
        });
    }
    if (gCalType === "project" || "both") {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          iCals: fieldValue.arrayUnion(icalObject),
        });
    }
    handleCloseSettings();
    setShowGCal(true);
  };
  const handleICalRemove = (iCal, type) => {
    if (type === "personal" || "both") {
      const icalObject = personalIcals.find((c) => c.id === iCal.id);
      db.collection("users")
        .doc(currentUser.uid)
        .update({
          iCals: fieldValue.arrayRemove(icalObject),
        });
    }
    if (type === "project" || "both") {
      const icalObject = projectIcals.find((c) => c.id === iCal.id);
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          iCals: fieldValue.arrayRemove(icalObject),
        });
    }
  };

  let gcalTestarray = [
    {
      googleCalendarId:
        "b6c6b076953c3e614f62f5a32a5047060b861a03b3db62105a92193c24c7f19d@group.calendar.google.com",
      className: "gCal-events",
    },
    {
      googleCalendarId: "62br1ma5rkfdn29mvdi0rtod6o@group.calendar.google.com",
      className: "gCal-events",
    },
  ];

  let allpersonal = personalGcals?.map((cal) => {
    return {
      googleCalendarId: cal.googleCalendarId,
      className: "gCal-events",
      color: cal.color,
    };
  });

  let allproject = projectGcals?.map((cal) => {
    return {
      googleCalendarId: cal.googleCalendarId,
      className: "gCal-events",
      color: cal.color,
    };
  });

  const something =
    "webcal://p142-caldav.icloud.com/published/2/MTY0MTMyNTU1NjE2NDEzMnZ7rHRjvix5W8Gtb77urOTUDPhSy118Iz2BcdI5pDqvxQtLpOeT9TP5KlqO-VU5QKy1G5Z8lQOKKYcwi07GEqw";

  let eventSourceAll = [taken, trassignedTasks];
  let eventSourceCurrent = [taken];
  let eventSourceAllwithG = [
    taken,
    otherProjects ? trassignedTasks : {},
    showGCal
      ? {
          googleCalendarId: gCalId,
          className: "gCal-events",
        }
      : {},
    holidayCals.includes("US") && {
      url: "https://ics.calendarlabs.com/76/8a0b2e2a/US_Holidays.ics",
      // url: "https://www.thunderbird.net/media/caldata/autogen/United-States-Holidays.ics",
      // url: "webcal://www.calendarlabs.com/ical-calendar/ics/76/US_Holidays.ics",
      format: "ics",
      className: "holiday-events",
    },
    {
      url: "http://p142-caldav.icloud.com/published/2/MTY0MTMyNTU1NjE2NDEzMnZ7rHRjvix5W8Gtb77urOTUDPhSy118Iz2BcdI5pDqvxQtLpOeT9TP5KlqO-VU5QKy1G5Z8lQOKKYcwi07GEqw",
      format: "ics",
      // className: "holiday-events",
    },

    ...personalGcals?.filter((c) => calsToShow?.includes(c.googleCalendarId)),
    ...projectGcals?.filter((c) => calsToShow?.includes(c.googleCalendarId)),
  ];


  var scrollTime = moment().format("HH") + ":00:00";

  const handlePrint = (e) => {
    window.print();
  };

  const print = () => window.print();

  function addHoverTitle(args) {
    args.el.title = args.event.title;
  }

  function switchWorkHours() {
    setActiveCalShow(!activeCalShow);
    if (activeCalShow) {
      setActiveCalStart("00:00:00");
      setActiveCalEnd("24:00:00");
    } else {
      setActiveCalStart("06:00:00");
      setActiveCalEnd("23:00:00");
    }
  }

  const handleSaveTimeZone = (zone) => {
    setZoneVal(zone);
    if (aryIannaTimeZones.indexOf(zone) >= 0) {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          timezones: fieldValue.arrayUnion(zone),
        });
    }
  };

  const handleRemoveTimeZone = (zone) => {
    // !!! ToDO: Return to default LOCAL TIMEZONE
    // setZoneVal(zone);
    if (aryIannaTimeZones.indexOf(zone) >= 0) {
      db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .update({
          timezones: fieldValue.arrayRemove(zone),
        });
    }
  };

  const handleChangeTimeZone = useCallback(
    (timeZone) => {
      setTimeZone(timeZone);
    },
    [setTimeZone]
  );

  const onOptionClick = (option) => console.log("clicked", option);

  const handleToggleAsShown = (gcal) => {
    const findIndex = calsToShow.findIndex(
      (cal) => cal === gcal.googleCalendarId
    );
    if (findIndex === -1) {
      setCalstoShow((prev) => [...prev, gcal.googleCalendarId]);
    } else
      setCalstoShow(calsToShow.filter((cal) => cal !== gcal.googleCalendarId));
  };

  const handleToggleHolidayAsShown = (e) => {
    const findIndex = holidayCals.findIndex((cal) => cal === e.target.value);
    if (findIndex === -1) {
      setHolidayCals((prev) => [...prev, e.target.value]);
    } else setHolidayCals(calsToShow.filter((cal) => cal !== e.target.value));
  };

  // const apiView = calendarRef?.current?.getApi().viewDidMount;

  useEffect(() => {
    const phrases = document.querySelectorAll(".fc-toolbar-title");
    for (const phrase of phrases) {
      const words = phrase.innerHTML.split(" ");
      words[1] = `<b>${words[1]}</b>`; // this would return the second word
      phrase.innerHTML = words.join(" ");
    }
  });

  function someMethod() {
    const timeout = setTimeout(() => {
      let calendarApi = calendarRef.current.getApi();
      calendarApi.updateSize();
    }, 500);
    return timeout;
  }


  function handleDatesSet() {
    const phrases = document.querySelectorAll(".fc-toolbar-title");
    for (const phrase of phrases) {
      const words = phrase.innerHTML.split(" ");
      words[words.length - 1] = `<b>${words[words.length - 1]}</b>`; // this would return the second word
      phrase.innerHTML = words.join(" ");
    }
  }

  return (
    <div className="calendarView">
      <div className="calendar__wrapper">
        <motion.div
          className="list__column"
          initial={false}
          animate={{ marginLeft: x }}
          transition={{ ease: "easeOut" }}
          // style={{zIndex: isOpen ? 1 : 0, marginRight: isOpen ? -5 : -5}}
        >
          {isOpen && (
            <div className="list__column-description">
              <h4>Unplanned</h4>
              <p>To plan, you can drag tasks/events onto the calendar...</p>
            </div>
          )}
          <div className="list__column-items">
            <div id="external-events">
              {timelesstasks.map((event) => (
                <ul className="fc-daygrid-event fc-daygrid-block-event">
                  <div className="fc-event-main">
                    <li
                      className="fc-event"
                      id={event.id}
                      key={event.id}
                      title={event.title}
                      start={event.start}
                      end={event.end}
                      duration={event.duration}
                      onClick={() => handleOpenModal("edit", event)}
                    >
                      <div>{event.title}</div>
                      <div className="calexternal_duration">
                        <p>{event.duration}</p>
                      </div>
                    </li>
                  </div>
                </ul>
              ))}
            </div>
          </div>
        </motion.div>
        <motion.div
          className="listcolumn__hide"
          animate={{ marginLeft: x }}
          transition={{ ease: "easeOut" }}
          onClick={() => SetIsOpen(!isOpen)}
        >
          <span>Unplanned</span>
          <BsList size="1em" />
        </motion.div>

        <div className="custom__calendar">
          <Fullcalendar
            ref={calendarRef}
            // events={taken}
            // events={trassignedTasks}
            timeZone={timeZone}
            googleCalendarApiKey={"AIzaSyDU2Um3qaGfGTkNt_DGfJEGXG7vMUQQlG8"}
            eventSources={eventSourceAllwithG}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
              rrulePlugin,
              googleCalendarPlugin,
              // iCalendarPlugin,
              momentTimezonePlugin,
            ]}
            headerToolbar={{
              // left: "prev,next today WorkHoursButton ProjectsButton",
              left: "prev,today,next",
              center: "title",
              // right: "timeGridDay timeGridThreeDay,timeGridWeek dayGridMonth",
              right: "timeGridDay,timeGridThreeDay,timeGridWeek,dayGridMonth",
            }}
            datesSet={handleDatesSet}
            views={{
              timeGridThreeDay: {
                type: "timeGrid",
                duration: { days: 3 },
                buttonText: "3day",
                dayHeaderFormat: {
                  weekday: "long",
                  day: "numeric",
                  omitCommas: false,
                },
                dateIncrement: { days: 1 },
                titleFormat: { year: "numeric", month: "long" },
                // titleRangeSeparator: ' to ',
              },
              day: {
                dayHeaderFormat: { weekday: "long" },
              },
              week: {
                // options apply to timeGridWeek and timeGridDay views
                dayHeaderFormat: {
                  weekday: "short",
                  day: "numeric",
                  omitCommas: true,
                },
                titleFormat: { year: "numeric", month: "long" },
              },
              dayGrid: {
                dayMaxEventRows: 4,
                // dayHeaderFormat: { weekday: "long" },
              },
            }}
            initialView="timeGridWeek"
            navLinks={true} // can click day/week names to navigate views
            nowIndicator={true}
            expandRows={true}
            updateSize={true}
            height="100%"
            // contentHeight="100%"
            // stickyHeaderDates={true}
            handleWindowResize={true}
            windowResizeDelay={10}
            // aspectRatio={1.19}
            selectMirror={true}
            eventDurationEditable={true}
            editable={true}
            droppable={true}
            // eventReceive={eventReceive}
            dateClick={handleDateClick}
            selectable={true}
            select={handleSelect}
            eventClick={handleEventClick}
            // eventLimit={3}
            slotDuration="00:15:00"
            slotLabelInterval="01:00:00"
            // displayEventTime={true}
            eventResize={handleEventResize}
            eventDrop={handleEventDrop}
            eventReceive={handleEventReceive}
            slotMinTime={activeCalStart}
            slotMaxTime={activeCalEnd}
            scrollTime={scrollTime}
            // eventColor="#303030"
            eventMouseEnter={function (info) {
              db.collection("space")
                .doc(info.event.extendedProps.fromSpaceId)
                .onSnapshot((spaceData) => {
                  if (spaceData.exists) {
                    setFromSpaceName(spaceData.data().name);
                    // eventspacenameRef.current = spaceData.data().name;
                  }
                });
              db.collection("space")
                .doc(info.event.extendedProps.fromSpaceId)
                .collection("stations")
                .doc(info.event.extendedProps.fromStationId)
                .onSnapshot((stationData) => {
                  if (stationData.exists) {
                    setFromStationName(stationData.data().name);
                    setFromStationEmoji(stationData.data().emojicon);
                    // eventstationnameRef.current = stationData.data().name;
                  }
                });
              // console.log(eventspacenameRef.current);
              info.el.title =
                "from | " +
                fromSpaceName +
                " " +
                fromStationEmoji +
                " " +
                fromStationName;
            }}
            eventClassNames={function (arg) {
              if (arg.event.extendedProps.done) {
                return ["cal__event-done"];
              }
            }}
            weekends={showWeekend}
          />
        </div>
        {/* {!isOpen && ( */}
        <motion.div
          className="calcolumn__hide"
          animate={{ marginRight: calListOpen ? "0px" : "-110px" }}
          transition={{ ease: "easeOut", duration: 0.5  }}
          onClick={() => setCalListOpen(!calListOpen) & someMethod()}
        >
          <span>Calendars</span>
          <BsCalendar2Week size="1em" />
        </motion.div>

        <motion.div
          className="calendar_filters-buttons"
          animate={{ marginRight: calListOpen ? "0px" : "-110px" }}
          transition={{ ease: "easeOut", duration: 0.1}}
        >
          <button className="calendar_filter-btn-active">
            {currentStation?.emojicon} {currentStation?.name}
          </button>
          <button
            className={
              otherProjects
                ? "calendar_filter-btn-active"
                : "calendar_filter-btn"
            }
            onClick={() => setOtherProjects(!otherProjects)}
          >
            {currentUser.userName}'s Tasks
          </button>
          {personalGcals?.length && (
            <div className="cal__gCal-label">
              <h5>Personal Cals</h5>
            </div>
          )}
          {personalGcals?.map((gcal) => (
            <button
              key={gcal.googleCalendarId}
              className={
                calsToShow.includes(gcal.googleCalendarId)
                  ? "calendar_filter-btn-active"
                  : "calendar_filter-btn"
              }
              onClick={() => handleToggleAsShown(gcal)}
            >
              <div
                style={{
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  marginRight: "5px",
                  marginLeft: "-3px",
                  backgroundColor: gcal.color,
                }}
              />{" "}
              {gcal.name}
              <div>
                <BsThreeDotsVertical
                  size="1em"
                  onClick={() => setShowMiniMenu(!showMiniMenu)}
                />
              </div>
            </button>
          ))}
          {showMiniMenu && (
            <MiniMenu setLayer={setShowMiniMenu}>
              <ul>
                <li onClick={() => setShowColors(!showColors)}>
                  <div className="tooltip">colors</div>
                  <FaFillDrip fontSize="1x" />
                </li>
                {showColors && (
                  <Colors returnColor={setColor} defColor={defColor} />
                )}
                <li
                  onClick={() => {
                    setShowMiniMenu(false);
                  }}
                >
                  <div className="tooltip">Rename</div>
                  <FaICursor />
                </li>
                <li>
                  <div className="tooltip">Delete</div>
                  <BsTrash3Fill />
                </li>
              </ul>
            </MiniMenu>
          )}
          {projectGcals?.length > 0 && (
            <div className="cal__gCal-label">
              <h5>Project Cals</h5>
            </div>
          )}
          {projectGcals?.map((gcal) => (
            <button
              key={gcal.googleCalendarId}
              className={
                calsToShow.includes(gcal.googleCalendarId)
                  ? "calendar_filter-btn-active"
                  : "calendar_filter-btn"
              }
              onClick={() => handleToggleAsShown(gcal)}
            >
              <div
                style={{
                  borderRadius: "50%",
                  width: "10px",
                  height: "10px",
                  marginRight: "5px",
                  marginLeft: "-3px",
                  backgroundColor: gcal.color,
                }}
              />{" "}
              {gcal.name}
            </button>
          ))}
          <button
            value="US"
            className={
              holidayCals.includes("US")
                ? "calendar_filter-btn-active"
                : "calendar_filter-btn"
            }
            onClick={handleToggleHolidayAsShown}
          >
            <div
              style={{
                borderRadius: "50%",
                width: "10px",
                height: "10px",
                marginRight: "5px",
                marginLeft: "-3px",
                // backgroundColor: gcal.color,
              }}
            />{" "}
            🇺🇸 holidays
          </button>
        </motion.div>

        <div className="calendar__settings__win">
          {showSettings && (
            <BoxLayer setLayer={handleCloseSettings}>
              <div className="calendar_settings-buttons">
                <div className="cal__print-btn" onClick={print}>
                  <BsPrinter />
                  <button>Print Page</button>
                </div>

                <button
                  className="cal__allprojects-btn"
                  onClick={() => setShowGCalModal(!showGCalModal)}
                >
                  <BsGoogle /> Add Google Calendar
                </button>
                <button
                  className="cal__allprojects-btn"
                  onClick={() => setShowICalModal(!showICalModal)}
                >
                  <BsApple /> Add Apple Calendar
                </button>

                <div className="cal__activehours">
                  <div className="cal__activehours-btn">
                    {activeCalShow ? <BsClockFill /> : <BsClock />}
                    <button onClick={switchWorkHours}>Work Hours</button>
                  </div>
                  {/* {activeCalShow && ( */}
                  <div className="cal__activehours-set">
                    <p>
                      Hours are shown between {activeCalStart} and{" "}
                      {activeCalEnd}
                    </p>
                    <p>To set custom hours, choose between</p>
                    <div className="cal__slotpickers">
                      <input
                        id="timeslot-start"
                        type="time"
                        name="timeslot-start"
                        value="13:30"
                        onChange={(e) =>
                          setActiveCalStart(e.target.value) &
                          setActiveCalShow(true)
                        }
                      />
                      <small>and</small>
                      <input
                        id="timeslot-end"
                        type="time"
                        name="timeslot-end"
                        value="23:30"
                        onChange={(e) =>
                          setActiveCalEnd(e.target.value) &
                          setActiveCalShow(true)
                        }
                      />
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <div>
                  <details>
                    <summary className="cal__allprojects-btn">
                      <BsGlobeAmericas />
                      Timezones
                    </summary>
                    <div className="cal__gCal-tip">
                      {/* <p>Add the related timezones of the project.</p> */}
                      <div className="cal__timezone-form">
                        <input
                          list="data"
                          onChange={(e) => handleSaveTimeZone(e.target.value)}
                          placeholder="Add timezone"
                        />
                        <datalist id="data">
                          {aryIannaTimeZones.map((op) => (
                            <option key={op} value={op}>
                              {op}
                            </option>
                          ))}
                        </datalist>
                        {/* {moment.tz(op).format('Z z')} */}
                      </div>
                      {/* <button
                onClick={() => handleChangeTimeZone(TimeZone.CDT_CST)}
                className={timeZone === TimeZone.CDT_CST ? "active" : ""}
              >
                {timeZone}
              </button> */}
                    </div>
                  </details>
                  <div className="cal__timezones">
                    {timezones?.map((zone) => (
                      <button
                        key={zone}
                        value={zone}
                        onClick={() => setTimeZone(zone)}
                        className={timeZone === zone ? "timezone-active" : ""}
                      >
                        {zone}
                        <BsXCircle
                          onClick={(e) => handleRemoveTimeZone(zone)}
                        />
                      </button>
                    ))}
                  </div>
                </div>
                <div className="cal__weekend-btn">
                  {showWeekend ? <BsCalendar2Week /> : <BsCalendar2WeekFill />}
                  <button onClick={() => setShowWeekend(!showWeekend)}>
                    {showWeekend ? "Hide weekends" : "Show weekends"}
                  </button>
                  {/* <Tooltip text="Show tasks/events that are assigned to you from all workspaces" /> */}
                </div>
                <div className="cal__unplanned-btn">
                  {otherProjects ? <BsList /> : <BsList />}
                  <button onClick={() => SetIsOpen(!isOpen)}>
                    Show unplanned
                  </button>
                  {/* <Tooltip text="Show tasks/events that are assigned to you from all workspaces" /> */}
                </div>
              </div>
            </BoxLayer>
          )}
        </div>
        {/* )} */}

        {showGCalModal && (
          <IntegrateCal
            calType="google"
            setShowGCalModal={setShowGCalModal}
            handleCloseSettings={handleCloseSettings}
            personalGcals={personalGcals}
            projectGcals={projectGcals}
            personalIcals={personalIcals}
            projectIcals={projectIcals}
          />
        )}
        {showICalModal && (
          <IntegrateCal
            calType="apple"
            setShowGCalModal={setShowGCalModal}
            setShowICalModal={setShowICalModal}
            handleCloseSettings={handleCloseSettings}
            personalGcals={personalGcals}
            projectGcals={projectGcals}
            personalIcals={personalIcals}
            projectIcals={projectIcals}
          />
        )}
      </div>
      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default CalendarView;
