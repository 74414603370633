import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Chess } from 'chess.js';

import { Chessboard } from 'react-chessboard';

import { db, fieldValue } from "../../firebase/firebase.utils";

import "./chess.main.scss";

import {
  FaChessPawn,
  FaChessKnight,
  FaChess,
  FaGamepad
} from "react-icons/fa";

import SingleChessBoard from './single.chess.component';
import OnlineChessBoard from './online.chess.component';
import CoachingChessBoard from './coaching.chess.component';

import InvitetoPremium from "../../components/marketing/invite-to-premium.component";


export default function ChessMain({ boardWidth }) {
  const userEntitlement = useSelector((state) => state.user.entitlement);

  const [gamemode, setGameMode] = useState("Single");


  // const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const currentUser = useSelector((state) => state.user.currentUser);

  const [showInvitation, setShowInvitation] = useState(false);
  const [showInvCoaching, setShowInvCoaching] = useState(false);


  return (
    <div className="chess__main" >
      {gamemode === "Single" && <SingleChessBoard boardWidth={600} />}
      {gamemode === "Online" && <OnlineChessBoard boardWidth={600} />}
      {gamemode === "Coaching" && <CoachingChessBoard boardWidth={600} />}
      {gamemode === "Other" && <div className="othergames-container"></div>}
      {showInvitation && (
        <InvitetoPremium
          setShowInvitation={setShowInvitation}
          featureName={"Online Multiplayer Chess"}
          featureDesc={"enjoy a chess game with your friends and colleagues"}
          featureImg={
            "https://images.pexels.com/photos/6114949/pexels-photo-6114949.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          }
          currentUser={currentUser}
        />
      )}
      {showInvCoaching && (
        <InvitetoPremium
          setShowInvitation={setShowInvCoaching}
          featureName={"Online Chess Coaching"}
          featureDesc={"learn the game with the help of a chess coach and analyze different moves and positions from matches"}
          featureImg={
            "https://images.unsplash.com/photo-1632785047094-8a281dc14dcd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          }
          currentUser={currentUser}
        />
      )}
      <div className="chess__options">
        <div className={gamemode === "Single" ? "chess__option-active" : "chess__option"} onClick={() => setGameMode("Single")}>
          <FaChessKnight size="2em" /> <h4>Single <br /> Chess</h4> <p>Play with <br /> computer <br /> (easy)</p>
        </div>
        {userEntitlement === "free" ? (<div className={gamemode === "Online" ? "chess__option-active" : "chess__option"} onClick={() => setShowInvitation(!showInvitation)}>
          <FaChess size="2em" /><h4>Multiplayer <br /> Chess</h4> <p>Play with your<br /> team members <br /> (online)</p>
        </div>) : <div className={gamemode === "Online" ? "chess__option-active" : "chess__option"} onClick={() => setGameMode("Online")}>
          <FaChess size="2em" /><h4>Multiplayer <br /> Chess</h4> <p>Play with your<br /> team members <br /> (online)</p>
        </div>}
        {/* {currentUser.plan === "Free" ? (<div className={gamemode === "Coaching" ? "chess__option-active" : "chess__option"} onClick={() => setShowInvCoaching(!showInvCoaching)}>
          <FaGamepad size="2em" /><h4>Other</h4> <p>New games <br /> are on the way. <br /> Stay tuned... </p>
        </div>) : (<div className={gamemode === "Coaching" ? "chess__option-active" : "chess__option"} onClick={() => setGameMode("Other")}>
          <FaGamepad size="2em" /><h4>Other <br /> Games</h4> <p>New games <br /> are on the way. <br /> Stay tuned... </p>
        </div>)} */}
        <div className="chess__table-decoration"><span></span></div>
      </div>
    </div>
  );
}