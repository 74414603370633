import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Outlet, useNavigate } from "react-router-dom";

import { showGanttSettings } from "../../redux/settings/settings.actions";

import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import { db, fieldValue } from "../../firebase/firebase.utils";

import GanttChart from "../../components/gantt/Gantt";
import Gantt from "../../components/gantt/frappe-gantt";
import { tasksSample } from "./gantt.sampledata";

import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

// import BoxLayer from "../retro/box-layer/box-layer.component";
import BoxLayer from "../../components/retro/box-layer/box-layer.component";

import InvitetoPremium from "../../components/marketing/invite-to-premium.component";
import {
  BsLink45Deg,
  BsCalendar2Week,
  BsCalendar2WeekFill,
  BsPercent,
  BsListNested,
  BsLayoutSidebar,
  BsLayoutSidebarInset,
} from "react-icons/bs";

import "./gantt.styles.scss";

const GanttView = ({ station, stationTasks, stationTasksFL }) => {
  const users = useSelector((state) => state.user.users);
  const userEntitlement = useSelector((state) => state.user.entitlement);
  const phases = useSelector((state) => state.prjphases.prjphases);
  const filter = useSelector((state) => state.filter);
  const settingsOn = useSelector((state) => state.settings.showGanttSettings);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [state, setState] = useState([]);
  const [deleteOpacity, setDeleteOpacity] = useState(0);

  const spaceData = useActiveSpaceData();

  const [viewMode, setViewMode] = useState("Day");

  const currentUser = useSelector((state) => state.user.currentUser);

  const [showInvitation, setShowInvitation] = useState(true);

  const [showSwimlane, setShowSwimlane] = useState(true);
  const [showWeekends, setShowWeekends] = useState(true);
  const [showProgress, setShowProgress] = useState(true);

  const navigate = useNavigate();

  const _svg = useRef();
  const _chart = useRef();
  // const chart = useRef();

  useEffect(() => {
    setState(station);
  }, [station]);

  const [showSettings, setShowSettings] = useState(false);
  useEffect(() => {
    if (settingsOn) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [settingsOn]);

  const handleCloseSettings = () => {
    dispatch(showGanttSettings());
  };

  const [tasks, setTasklist] = useState([]);
  const [samplemode, setSampleMode] = useState(true);
  // useEffect(() => {
  //   //mounts
  //   const unsub = db
  //     .collection("space")
  //     .doc(currentSpaceId)
  //     .collection("stations")
  //     .doc(currentStationId)
  //     .collection("tasks")
  //     .orderBy("startdate", "asc")
  //     .where("startdate", "!=", null)
  //     .onSnapshot((querySnapshot) => {
  //       if (!querySnapshot.empty) {
  //         const array = querySnapshot.docs.map((doc) => {
  //           return {
  //             start: doc.get("startdate")?.toDate(),
  //             end: doc.get("enddate")?.toDate(),
  //             name: doc.get("title"),
  //             id: doc.id,
  //             progress: doc.get("progress"),
  //             swimlane: doc.get("phase"),
  //             // sub_swimlane: doc.get("id"),
  //             editable: true,
  //             start_drag: true,
  //             end_drag: true,
  //             custom_class: doc.get("type"),
  //             dependencies: doc.get("dependencies"),
  //           };
  //         });
  //         setTasklist([...array]);
  //         _chart.current.refresh([...array]);
  //         setSampleMode(false);
  //       } else {
  //         setTasklist(tasksSample);
  //       }
  //     });

  //   return () => {
  //     unsub();
  //   };
  // }, []);

  const transformTasks = async () => {
    if (!stationTasksFL.empty) {
      const array = await stationTasksFL
        .filter((task) => task.startdate !== null)
        .sort((a, b) => (a.startdate > b.startdate ? 1 : -1))
        .map((task) => {
          const phaseRefName = phases?.find((phase) => phase.id === task.phase)?.name;
          return {
            start: task.startdate?.toDate(),
            end: task.enddate?.toDate(),
            name: task.title,
            id: task.id,
            progress: task.progress,
            swimlane: phaseRefName,
            // sub_swimlane: doc.get("id"),
            editable: true,
            start_drag: true,
            end_drag: true,
            custom_class: task.type,
            dependencies: task.dependencies,
          };
        });
      setTasklist([...array]);
      _chart.current.refresh([...array]);
      setSampleMode(false);
    } else {
      setTasklist(tasksSample);
    }
  };

  useEffect(() => {
    transformTasks();
  }, [stationTasksFL]);

  // useEffect(() => {
  //   if (stationTasksFL.length !== tasks.length) {
  //     dispatch(setFilterNull());
  //     // setTimeout(() => {

  //     // }, 500);

  //   }
  // }, []);

  const handleTaskChange = (task, start, end) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(task.id)
      .update({
        startdate: start,
        enddate: end,
      });
  };

  const handleDependencyAdd = (connection) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(connection.to)
      .update({
        dependencies: fieldValue.arrayUnion(connection.from),
      });
  };

  const handleDependencyRemove = (connection) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(connection.to)
      .update({
        dependencies: fieldValue.arrayRemove(connection.from),
      });
  };

  const handleProgressChange = async (task) => {
    // setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    // console.log("On progress change Id:" + task.id);

    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("tasks")
      .doc(task.id)
      .update({
        progress: task.progress,
      });
  };

  const handleViewModeChange = (e) => {
    setViewMode(e.target.value);
    _chart.current.change_view_mode(e.target.value);
  };

  const handleOpenTask = (event) => {
    // setViewMode(e.target.value);
    // _chart.current.change_view_mode(e.target.value);
    navigate(`task/${event.id}`);
  };

  const customPopupHtml = (task) => {
    const end_date = task._end.format("MMM D");
    this.setState({ editTask: "open" });
    return `
      <div class="details-container">
        <h5>${task.name}</h5>
        <h5>${task.start}</h5>
        <h5>${task.progress}% completed!</h5>
        <a href="google.com">LINK!</a>
        <p>6545</p>
        <p>564</p>
      </div>
    `;
  };

  function onViewChange(mode) {
    console.log(mode);
  }

  function onProgressChange(task, progress) {
    console.log(task, progress);
  }

  function onClick(task) {
    console.log(task);
  }

  function onDateChange(task, start, end) {
    console.log(task, start, end);
    // handleTaskChange(task, start, end);
  }

  useEffect(() => {
    // if (chart.current) return;

    _chart.current = new Gantt(_svg.current, tasks, {
      on_click: function (task) {
        console.log(task);
        handleOpenTask(task);
      },
      on_dependency_added: function (connection) {
        console.log(connection);
        handleDependencyAdd(connection);
      },
      on_dependency_deleted: function (connection) {
        console.log(connection);
        handleDependencyRemove(connection);
      },
      on_date_change: function (task, start, end) {
        console.log(task, start, end);
        handleTaskChange(task, start, end);
      },
      on_progress_change: function (task, progress) {
        console.log(task, progress);
        handleProgressChange(task);
      },
      on_view_change: function (mode) {
        console.log(mode);
      },
      view_mode: viewMode,
      show_weekends: showWeekends,
      // popup_trigger: "mouseover",
    });
  }, []);

  return (
    <div className="ganttView">
      <div
        className={`ganttContainer ${!showSwimlane && "noSwimlane"} ${
          !showWeekends && "noWeekends"
        }`}
      >
        {currentUser.plan === "Free" ? (
          <div onClick={() => setShowInvitation(!showInvitation)}>
            {showInvitation && (
              <div>
                <InvitetoPremium
                  setShowInvitation={setShowInvitation}
                  featureName={"Gantt View"}
                  featureDesc={
                    "arrange your tasks & milestones with ease, link them with dependencies, see the big picture"
                  }
                  featureImg={
                    "https://images.unsplash.com/photo-1577563682708-4f022ec774fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2167&q=80"
                  }
                  featureImgStyle={{ transform: "scaleX(-1)" }}
                  currentUser={currentUser}
                />
              </div>
            )}
            <div className="gantt__upperrow">
              <h4>
                This is just how a sample chart looks like... When you change a
                task or event's date, all the related task/event dates to be
                automatically updated for you. So updating your goals,
                milestones or complex project plan is easy-peasy 🙂 To upgrade
                now, visit{" "}
                <a href="https://antlogy.com/main/#pricing" target="_blank">
                  {" "}
                  here.
                  <BsLink45Deg size="1.4em" />
                </a>
                <br />
                <br />
                Additionally for our Premium or Business Plan users; 'Month',
                'Week', 'Day', '12 hours', '6 hours' views are supported in the
                timeline...
              </h4>
            </div>
            <GanttChart
              tasks={tasksSample}
              viewMode={viewMode}
              onDateChange={(task, start, end) =>
                handleTaskChange(task, start, end)
              }
              // handleProgressChange={handleProgressChange}
            />
          </div>
        ) : (
          <>
            <div className="gantt__upperrow">
              {samplemode && (
                <h4>
                  You don't have any tasks with dates yet. Until you add some,
                  this is a sample timeline for you to try out this feature...
                </h4>
              )}
              <div className="gantt__buttons">
                <div className="gantt__buttongroup">
                  <button
                    style={{
                      textDecoration:
                        viewMode === "Quarter Day" &&
                        `underline 2px ${spaceData?.color}`,
                    }}
                    className={
                      viewMode === "Quarter Day"
                        ? "gantt__button-active"
                        : "gantt__button"
                    }
                    value="Quarter Day"
                    onClick={handleViewModeChange}
                  >
                    6hours
                  </button>
                  <button
                    style={{
                      textDecoration:
                        viewMode === "Half Day" &&
                        `underline 2px ${spaceData?.color}`,
                    }}
                    className={
                      viewMode === "Half Day"
                        ? "gantt__button-active"
                        : "gantt__button"
                    }
                    value="Half Day"
                    onClick={handleViewModeChange}
                  >
                    12hours
                  </button>
                </div>
                <button
                  style={{
                    textDecoration:
                      viewMode === "Day" && `underline 2px ${spaceData?.color}`,
                  }}
                  className={
                    viewMode === "Day"
                      ? "gantt__button-active"
                      : "gantt__button"
                  }
                  value="Day"
                  onClick={handleViewModeChange}
                >
                  Day
                </button>
                <div className="gantt__buttongroup">
                  <button
                    style={{
                      textDecoration:
                        viewMode === "Week" &&
                        `underline 2px ${spaceData?.color}`,
                    }}
                    className={
                      viewMode === "Week"
                        ? "gantt__button-active"
                        : "gantt__button"
                    }
                    value="Week"
                    onClick={handleViewModeChange}
                  >
                    Week
                  </button>
                  <button
                    style={{
                      textDecoration:
                        viewMode === "Month" &&
                        `underline 2px ${spaceData?.color}`,
                    }}
                    className={
                      viewMode === "Month"
                        ? "gantt__button-active"
                        : "gantt__button"
                    }
                    value="Month"
                    onClick={handleViewModeChange}
                  >
                    Month
                  </button>
                  {/* <button
              className={
                viewMode === "Year"
                  ? "gantt__button-active"
                  : "gantt__button"
              }
              onClick={() => _chart.current.change_view_mode("Year")}
            >
              Year
            </button> */}
                </div>
              </div>
            </div>
            <div className="gantt__container">
              {/* <GanttChart
            tasks={tasklist}
            viewMode={viewMode}
            onDateChange={(task, start, end) =>
              handleTaskChange(task, start, end)
            }
            handleProgressChange={handleProgressChange}
            // onProgressChange={handleProgressChange}
          /> */}
              {tasks != [] && (
                <svg
                  ref={_svg}
                  style={{ display: "flex", height: "calc(100vh - 250px)" }}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                ></svg>
              )}
            </div>
          </>
        )}
        <div className="gantt__settings__win">
          {showSettings && (
            <BoxLayer setLayer={handleCloseSettings}>
              <div className="gantt__buttongroup-settings">
                <button onClick={() => setShowSwimlane(!showSwimlane)}>
                  {showSwimlane ? (
                    <BsLayoutSidebarInset />
                  ) : (
                    <BsLayoutSidebar />
                  )}{" "}
                  Show Status
                </button>
                <button onClick={() => setShowWeekends(!showWeekends)}>
                  {showWeekends ? <BsCalendar2WeekFill /> : <BsCalendar2Week />}{" "}
                  Show Weekends
                </button>
                <button onClick={() => setShowProgress(!showProgress)}>
                  {showProgress ? <BsPercent /> : <BsListNested />} Show
                  Progress
                </button>
                <div className="gantt__switch"></div>
              </div>
            </BoxLayer>
          )}
        </div>
      </div>
      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default GanttView;
