import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { setIdsNull } from "../../redux/history/history.actions";

import RetroButton from "../retro/button/retro-button.component";
import CreateSpace from "../create-space/create-space.component";
import Icon from "./icon/icon.component";

import InvitetoPremium from "../marketing/invite-to-premium.component";

import "./space-fly.styles.scss";

import { BsPlusLg, BsPeopleFill } from "react-icons/bs";

import { setOpen } from "../../redux/user/user.actions";

const SpaceFly = () => {
  const spaceData = useSelector((state) => state.space.spaceData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const userEntitlement = useSelector((state) => state.user.entitlement);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const [createNewSpace, setCreateNewSpace] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [showInvitation, setShowInvitation] = useState(false);

  useEffect(() => {
    if (path === "/") {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  }, [location]);

  const buttonStyle = {
    // textDecoration: `underline 4px ${space.color}`,
    transform: path === "/s/sharedwithme" ? "scale(1.2)" : "scale(1)",
    boxShadow: path === "/s/sharedwithme" ? `4px 0px 5px -2px lightgray` : "",
  };

  const handleCheckEntitlement = () => {
    if (userEntitlement === "free") {
      if (spaceData?.length < 1) {
        setCreateNewSpace(true);
      } else {
        // show invitation
        setShowInvitation(true);
      }
    } else if (userEntitlement === "antPro") {
      if (spaceData?.length < 4) {
        setCreateNewSpace(true);
      } else {
        setShowInvitation(true);
      }
    } else if (userEntitlement === "antBusiness") {
      if (spaceData?.length < 10) {
        setCreateNewSpace(true);
        // setShowInvitation(true);
      } else {
        setShowInvitation(true);
      }
    }
  };

  return (
    <>
      <div className="spaceFly">
        {/* {spaceData.length > 0 && <p>ALL</p>} */}
        {/* <div className={showArrow ? "sf__flys-main" : "sf__flys"}> */}
        <div className="sf__flys">
          {spaceData?.map((space) => {
            return (
              <div className="sf__icon" key={space.spaceId}>
                <Link to={`/s/${space.spaceId}`}>
                  <Icon space={space} />
                </Link>
              </div>
            );
          })}
        </div>
        {(spaceData?.length < 7) && (
          <div className="sf__controls-plus">
            <div className="icon__button-active">
              <button
                onClick={() => handleCheckEntitlement()}
                className="retroButton"
              >
                <div className="charAt">
                  <BsPlusLg size="0.9em" />
                  <div className="charAtFull">Create new Workspace</div>
                </div>
              </button>
            </div>
            <Link
              to="/"
              onClick={() => {
                dispatch(setIdsNull());
                dispatch(setOpen(true));
              }}
            ></Link>
          </div>
        )}
        {/* <div classname="rotated__workspace" >
          <h3>Workspaces</h3>
          </div> */}
        {currentUser?.sharedWith && (
          <div className="sf__flys-shared">
            <div className="sf__icon">
              <Link to={`/s/sharedwithme`}>
                <div className="icon__button-active">
                  <button className="retroButton" style={buttonStyle}>
                    <div className="charAt">
                      <BsPeopleFill />
                      <div className="charAtFull">Shared With Me</div>
                    </div>
                  </button>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
      {showInvitation && (
        <InvitetoPremium
          setShowInvitation={setShowInvitation}
          featureName={`Creating more than ${
            userEntitlement === "free"
              ? "1"
              : userEntitlement === "antPro"
              ? "4"
              : "10"
          } workspaces`}
          featureDesc={
            "organize your projects and ideas efficiently with multiple workspaces"
          }
          featureImg={
            "https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg"
          }
          currentUser={currentUser}
        />
      )}
      <AnimatePresence>
        {createNewSpace && <CreateSpace setLayer={setCreateNewSpace} />}
      </AnimatePresence>
    </>
  );
};

export default SpaceFly;
