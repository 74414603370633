import React, { useState, useEffect } from "react";

import { db, fieldValue } from "../../firebase/firebase.utils";

import Habit from "./habit/habit.component";
import HabitAnimation from "./habit/habit-animation.component";

import { useSelector } from "react-redux";

import InvitetoPremium from "../marketing/invite-to-premium.component";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IoChevronBackOutline } from "react-icons/io5";

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";
import { IoRepeat } from "react-icons/io5";

import moment from "moment";

import "./habits.styles.scss";

import { BsPlus } from "react-icons/bs";
import { FiSquare, FiCheckSquare } from "react-icons/fi";

import { Circle } from "rc-progress";
import Lottie from "lottie-react";
import Confetti from "./lottie/15108-confetti-banner.json";

const habitlibrary = [
  {
    name: "Bathe",
    id: "bathe",
    icon: "🛁",
  },
  {
    name: "Clean",
    id: "clean",
    icon: "🧹",
  },
  {
    name: "Skincare",
    id: "skincare",
    icon: "🧴",
  },
  {
    name: "Read",
    id: "read",
    icon: "📚",
  },
];

export default function Habits({ currentUser }) {
  const userEntitlement = useSelector((state) => state.user.entitlement);

  const [userHabits, setUserHabits] = useState([]);
  const [filteredHabits, setFilteredHabits] = useState([]);
  const [habitIcon, setHabitIcon] = useState("");
  const [habitName, setHabitName] = useState("");
  const [relatedSpaceId, setRelatedSpaceId] = useState("");
  const [relatedStationId, setRelatedStationId] = useState("");
  const [refrrule, setRefRRule] = useState(
    "RRULE:FREQ=DAILY;INTERVAL=1;COUNT=5"
  );
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(1);
  const [unit, setUnit] = useState("time(s)");

  const [habitmodalMode, setHabitModalMode] = useState("");
  const [showhabitmodal, setShowHabitModal] = useState(false);

  const [showAllHabits, setShowAllHabits] = useState(false);

  const [activeHabit, setActiveHabit] = useState("");

  const [animRefHabit, setanimRefHabit] = useState("");

  const [sliderValue, setSliderValue] = useState();
  const [showConfetti, setShowConfetti] = useState(false);

  const [showInvitation, setShowInvitation] = useState(false);

  let newDate = new Date();
  let date = ("0" + newDate.getDate()).slice(-2);
  let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
  let year = newDate.getFullYear();

  let yesterday = new Date(newDate - 86400000);

  const [selectedDate, setSelectedDate] = useState(`${year}-${month}-${date}`);
  const [pickerDate, setPickerDate] = useState(new Date());

  useEffect(() => {
    //mounts
    const unsub = db
      .collection("users")
      .doc(currentUser.uid)
      .collection("habits")
      .onSnapshot((querySnapshot) => {
        if (querySnapshot) {
          const array = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              name: doc.get("name"),
              icon: doc.get("icon"),
              history: doc.get("history"),
              relatedSpaceId: doc.get("relatedSpaceId"),
              relatedStationId: doc.get("relatedStationId"),
              refrrule: doc.get("rrule"),
              step: doc.get("step"),
              steps: doc.get("steps"),
              unit: doc.get("unit"),
            };
          });
          setUserHabits([...array]);
        }
      });

    return () => {
      unsub();
    };
  }, [currentUser]);

  // useEffect(() => {

  // }, []);

  const handleHabitClick = (habit) => {
    setHabitModalMode("edit");
    setActiveHabit(habit);
    setHabitIcon(habit.icon);
    setHabitName(habit.name);
    setRelatedSpaceId(habit.relatedSpaceId);
    setRelatedStationId(habit.relatedStationId);
    setRefRRule(habit.refrrule);
    setStep(habit.step);
    setSteps(habit.steps);
    setUnit(habit.unit);
    setShowHabitModal(true);
    // setRefRRule("")
  };

  const handleAddBtnClick = () => {
    setHabitModalMode("add");
    setHabitIcon("❔");
    setHabitName("");
    setShowHabitModal(true);
  };

  const handleSaveHabit = (e) => {
    e.preventDefault();

    const startcheckrecord = {
      date: selectedDate,
      checked: 0,
    };

    if (habitmodalMode === "add") {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("habits")
        .add({
          name: habitName,
          icon: habitIcon,
          relatedSpaceId: relatedSpaceId,
          relatedStationId: relatedStationId,
          rrule: refrrule,
          step: step,
          steps: steps,
          history: [startcheckrecord],
          unit: unit,
          reminder: "",
          created: new Date(),
          archived: false,
        });
      // .then(function (docRef) {
      //   // console.log("Document written with ID: ", docRef.id);
      //   db.collection("users")
      //     .doc(currentUser.uid)
      //     .collection("habits")
      //     .doc(docRef.id)
      //     .collection("history")
      //     .doc("history")
      //     .set(
      //       {
      //         [selectedDate]: recordobj,
      //       },
      //       { merge: true }
      //     );
      // });
    } else {
      db.collection("users")
        .doc(currentUser.uid)
        .collection("habits")
        .doc(activeHabit.id)
        .update({
          name: habitName,
          icon: habitIcon,
          relatedSpaceId: relatedSpaceId,
          relatedStationId: relatedStationId,
          rrule: refrrule,
          step: step,
          steps: steps,
          unit: unit,
          reminder: "",
        });
      // .then((activeHabit) => {
      //   db.collection("users")
      //     .doc(currentUser.uid)
      //     .collection("habits")
      //     .doc(activeHabit.id)
      //     .collection("history")
      //     .doc("history")
      //     .set(
      //       {
      //         [selectedDate]: recordobj,
      //       },
      //       { merge: true }
      //     );
      // });
    }
    setShowHabitModal(false);
  };

  const handleHabitMarkDone = (habit) => {
    //Save Steps (the goal) and the step (value of increment with each check) to the document field
    //Save percentage or fraction of how many steps completed to history {date: yyyy-mm-dd, stepdone: 60%}
    //When checked increment it by step/steps

    //steps/step = nr of steps

    const incrementalvalue = habit.step / habit.steps;

    const firstcheckrecord = {
      date: selectedDate,
      checked: incrementalvalue,
    };

    const resetcheckrecord = {
      date: selectedDate,
      checked: 0,
    };

    const historyarray = habit.history;
    //check if today's object exist
    const findIndex = historyarray.findIndex(
      (item) => item.date === selectedDate
    );
    if (findIndex === -1) {
      historyarray.push(firstcheckrecord);
    } else if (historyarray[findIndex].checked < 1) {
      const newcheckedVal =
        Number(historyarray[findIndex].checked) + Number(incrementalvalue);
      const incrementcheckrecord = {
        date: selectedDate,
        checked: Math.round(newcheckedVal * 100) / 100,
        //read the value and increment it by step / steps
      };
      historyarray.splice(findIndex, 1);
      historyarray.splice(findIndex, 0, incrementcheckrecord);
      if (newcheckedVal >= 1) {
        setanimRefHabit(habit.id);
      } else {
        document.getElementById("habit-check_tone").play();
      }
    } else if (historyarray[findIndex].checked >= 1) {
      historyarray.splice(findIndex, 1);
      historyarray.splice(findIndex, 0, resetcheckrecord);
    }

    db.collection("users")
      .doc(currentUser.uid)
      .collection("habits")
      .doc(habit.id)
      .set(
        {
          history: historyarray,
        },
        { merge: true }
      );
  };

  const handleDeleteHabit = (e) => {
    e.preventDefault();
    db.collection("users")
      .doc(currentUser.uid)
      .collection("habits")
      .doc(activeHabit.id)
      .delete();
    setShowHabitModal(false);
  };

  const handleCloseHabit = () => {
    if (habitmodalMode === "add") {
      setShowHabitModal(false);
      setHabitName("");
      setHabitIcon("");
    } else {
      setShowHabitModal(false);
      setHabitName("");
      setHabitIcon("");
    }
  };

  // function Header() {
  //   return <h2>Hello world</h2>;
  // }

  function checkTodaysHistory(habit) {
    const historyarray = habit.history;
    const findIndex = historyarray.findIndex(
      (item) => item.date === selectedDate
    );
    if (findIndex === -1 || historyarray[findIndex].checked === 0)
      return (
        <i className="habit-checkbox">
          <FiSquare />
        </i>
      );
    else if (1 > historyarray[findIndex].checked > 0)
      return (
        <i className="habit-checkbox-colored">
          <FiSquare />
        </i>
      );
    else
      return (
        <i className="habit-checkbox-done">
          <FiCheckSquare />
        </i>
      );
  }

  function checkCircleStatus(habit) {
    const historyarray = habit.history;
    const findIndex = historyarray.findIndex(
      (item) => item.date === selectedDate
    );
    const percentVal =
      findIndex === -1 ? 0 : historyarray[findIndex].checked * 100;
    // const percentVal = historyarray[findIndex].checked * 100;
    const circleSteps = habit.steps / habit.step;
    if (circleSteps > 1)
      return (
        <Circle
          percent={percentVal}
          strokeWidth={6}
          steps={{
            count: circleSteps,
            space: 10,
          }}
          strokeColor={"#1A82C4"}
        />
      );
    else
      return (
        <Circle percent={percentVal} strokeWidth={6} strokeColor={"#1A82C4"} />
      );
  }

  // {steps > 1 ? (
  //   <Circle
  //     percent={historyarray[findIndex].checked}
  //     strokeWidth={6}
  //     steps={{
  //       count: habit?.steps / habit?.step,
  //       space: steps / step > 1 ? 10 : 1,
  //     }}
  //     strokeColor={"#1A82C4"}
  //   />
  // ) : (
  //   <Circle
  //     percent={historyarray[findIndex].checked}
  //     strokeWidth={6}
  //     strokeColor={"#1A82C4"}
  //   />
  // )}

  const handleCheckEntitlement = () => {
    if (userEntitlement === "free") {
      if (userHabits?.length < 2) {
        handleAddBtnClick();
      } else {
        // show invitation
        setShowInvitation(true);
      }
    } else if (userEntitlement === "antPro") {
      handleAddBtnClick();
    } else if (userEntitlement === "antBusiness") {
      handleAddBtnClick();
    }
  };

  return (
    <div className="habits" id="habits">
      <div className="widgetbtns">
        <button
          className={
            showAllHabits ? "habit__today-btn" : "habit__today-btn-passive"
          }
          onClick={() => setShowAllHabits(true)}
        >
          All
        </button>
        <button
          className={
            !showAllHabits ? "habit__today-btn" : "habit__today-btn-passive"
          }
          onClick={() => setShowAllHabits(false)}
        >
          Today's
        </button>
      </div>
      <div className="habitcontent">
        {!userHabits.length && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              rowGap: "30px",
              color: "#cccccc",
            }}
          >
            <IoRepeat size="4em" color="#dddddd" /> No habits found.
          </div>
        )}
        {userHabits
          .filter((habit) => {
            return showAllHabits
              ? habit
              : rrulestr(habit.refrrule).between(
                  datetime(`${year}`, `${month}`, `${date}`, 0, 0),
                  datetime(`${year}`, `${month}`, `${date}`, 23, 59)
                ).length > 0;
          })
          .map((habit) => {
            const { name, icon, id, steps, refrrule } = habit;
            return (
              // <Link to={`/s/${data.spaceId}`}>
              <div key={id} className="habitline-main">
                {/* {() => checkIfToday(refrrule)} */}
                <div
                  onClick={() => handleHabitClick(habit)}
                  className="habitinfo"
                >
                  <div className="habit-progress-icon">
                    <span>{icon}</span>
                    <div
                      className="habit-progress-circle"
                      style={{ width: 40 }}
                    >
                      {checkCircleStatus(habit, steps, step)}
                    </div>
                  </div>
                  <h5>{name}</h5>
                </div>
                <i onClick={() => handleHabitMarkDone(habit)}>
                  {checkTodaysHistory(habit)}
                </i>
                <audio
                  id="habit-check_tone"
                  src="/media/mixkit-modern-technology-select-3124.wav"
                ></audio>
                <HabitAnimation
                  habit={habit}
                  animRefHabit={animRefHabit}
                  setanimRefHabit={setanimRefHabit}
                />
              </div>
              // </Link>
            );
          })}
      </div>
      <div className="widgetbtns">
        <button
          onClick={() => handleCheckEntitlement()}
          className="habitadd-btn"
        >
          <BsPlus />
          Add
        </button>
      </div>
      {showhabitmodal && (
        <Habit
          currentUser={currentUser}
          habit={activeHabit}
          habitIcon={habitIcon}
          setHabitIcon={setHabitIcon}
          habitName={habitName}
          setHabitName={setHabitName}
          setRelatedSpaceId={setRelatedSpaceId}
          setRelatedStationId={setRelatedStationId}
          setRefRRule={setRefRRule}
          step={step}
          setStep={setStep}
          steps={steps}
          setSteps={setSteps}
          unit={unit}
          setUnit={setUnit}
          handleCloseHabit={handleCloseHabit}
          handleSaveHabit={handleSaveHabit}
          handleDeleteHabit={handleDeleteHabit}
          habitmodalMode={habitmodalMode}
          setSelectedDate={setSelectedDate}
        />
      )}
      {showInvitation && (
        <InvitetoPremium
          setShowInvitation={setShowInvitation}
          featureName={`Creating more than 2 habits`}
          featureDesc={
            "fulfill your resolutions by setting goals and tracking your habits"
          }
          featureImg={
            "https://images.pexels.com/photos/29996961/pexels-photo-29996961.jpeg"
          }
          currentUser={currentUser}
        />
      )}
    </div>
  );
}
