import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { db } from "../../firebase/firebase.utils";
import { HashLink } from "react-router-hash-link";

import { setTheme } from "../../redux/theme/theme.actions";

import Stations from "../stations/stations.component";
import UserProfile from "../user-profile/user-profile.component";
import FavoriteStations from "../favorite-stations/favorite-stations.component";
import CreateSpace from "../create-space/create-space.component";
import "./mobileDrawer.scss";

import styled from "styled-components";

import { FaChevronLeft, FaHome } from "react-icons/fa";
import {
  BsSun,
  BsPlusLg,
  BsMoonStars,
} from "react-icons/bs";
import { IoChevronBack, IoLeafSharp, IoWaterSharp } from "react-icons/io5";

import RetroButton from "../retro/button/retro-button.component";

const Ul = styled.div`
  list-style: none;
  display: none;
  flex-flow: row nowrap;

  // column-gap: 20px;

  li {
    padding: 32px 20px;
    // :last-child {
    //   padding: 32px 0px;
    // }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-flow: column nowrap;
    // background-color: white;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    // opacity: ${({ open }) => (open ? "1" : "0")};
    // transform: ${({ open }) =>
      open ? "translateY(0)" : "translateY(-100%)"};
    // margin-top: 90px;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    // width: calc(100vw - 110px);
    width: 100vw;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    transition: all 0.3s ease-in-out;
    li {
      color: #fff;
      //   padding: 30px, 20px;
    }
  }
`;

const MobileDrawer = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const spaceData = useSelector((state) => state.space.spaceData);
  const theme = useSelector((state) => state.theme.theme);
  const history = useNavigate();
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];
  const [createNewSpace, setCreateNewSpace] = useState(false);
  const [homePage, setHomePage] = useState(false);
  const [showSpaceMenu, setShowSpaceMenu] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
  }, [location]);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  // const handleWorkspaceSelect = (space) => {
  //   // space.preventDefault();
  //   // setSendtomode("toworkspace");
  //   setSelectedWorkspaceId(space.spaceId);
  //   if (projectOptions[0]?.fromSpaceId === space.spaceId) {
  //     // handleSendtomode("toworkspace");
  //     setSendtomode("toworkspace");
  //     setSendto(space.spaceId);
  //     setShowGroupSelect(false);
  //     setSelectedGroupName(space.name);
  //     setSelectedGroupColor(space.color);
  //   } else {
  //     db.collection("space")
  //       .doc(space.spaceId)
  //       .collection("stations")
  //       // .doc(currentStationId)
  //       // .collection("tasks")
  //       .onSnapshot((querySnapshot) => {
  //         const array = querySnapshot.docs.map((doc) => {
  //           return {
  //             id: doc.id,
  //             name: doc.get("name"),
  //             emojicon: doc.get("emojicon"),
  //             fromSpaceId: doc.get("fromSpaceId"),
  //           };
  //         });
  //         setProjectOptions([...array]);
  //         // handleProjectSelect([...array][0].id);
  //       });
  //   }
  // };

  function onChangeTheme() {
    if (theme !== "Dark") {
      dispatch(setTheme("Dark"));
      document.body.setAttribute("data-theme", "dark-theme");
      localStorage.setItem("selectedTheme", "dark-theme");
    } else {
      dispatch(setTheme("Paper"));
      document.body.setAttribute("data-theme", "paper-theme");
      localStorage.setItem("selectedTheme", "paper-theme");
    }
  }

  return (
    <Ul open={open}>
      <div className="mobileDrawer">
        <div className="drawer__kmobile">
          <Link
            to="/"
            // onClick={() => {
            //   dispatch(setIdsNull());
            //   dispatch(setOpen(true));
            // }}
          >
            <button>
              <IoLeafSharp size="2.2em" />
            </button>
          </Link>
          <Link to="https://www.antlogy.com/">
            <img
              classname="antLogy__logo"
              src="/antlogy_logo.png"
              height="40px"
              alt=""
              style={{ objectFit: "cover", marginTop: 10 }}
            />
          </Link>
        </div>
        <div className="drawer_sections">
          <div className="drawer_section">
            <h3>Workspaces</h3>
            <div className="drawer_grpselect">
              {spaceData?.map((space) => {
                return (
                  <>
                    <button
                      key={space.spaceId}
                      // selected={selectedWorkspaceId === space.spaceId}
                      className={
                        currentSpaceId === space.spaceId
                          ? "chat_grpoption-active"
                          : "chat_grpoption"
                      }
                      style={{
                        textDecoration: `underline 2px ${space.color}`,
                      }}
                      // onClick={() => handleWorkspaceSelect(space)}
                    >
                      <div className="drawer_ws-icon">
                        <RetroButton style={{ background: space.color }}>
                          {space.name.charAt(0)}
                        </RetroButton>
                      </div>
                      <Link to={`/s/${space.spaceId}`}>{space.name}</Link>
                    </button>
                    <motion.div
                      initial={{
                        y: -50,
                        height: "0px",
                        opacity: 0,
                      }}
                      animate={{
                        y: 0,
                        height: "auto",
                        opacity: 1,
                      }}
                      transition={{
                        type: "ease-in-out",
                        duration: 0.4,
                      }}
                    >
                      {currentSpaceId === space.spaceId && <Stations />}
                    </motion.div>
                  </>
                );
              })}
              <div className="icon__button-active">
                <button onClick={() => setCreateNewSpace(true)}>
                  <div className="chat_grpoption">
                    <div
                      className="drawer_wsIcon"
                      style={{ border: "1px solid #dddddd" }}
                    >
                      <BsPlusLg size="0.9em" />
                    </div>
                    <div>Create Workspace</div>
                  </div>
                </button>
              </div>
              <AnimatePresence>
                {createNewSpace && <CreateSpace setLayer={setCreateNewSpace} />}
              </AnimatePresence>
            </div>
          </div>
          <div className="drawer_section">
            <h3>Pinned Projects</h3>
            <div className="home__favorite">
              <div className="home__favoriteStations">
                <FavoriteStations />
              </div>
            </div>
          </div>
        </div>
        <div className="drawer_section">
          {/* <h3>Settings</h3> */}
          <div className="drawer_settings">
            <div className="drawer_user">
              <UserProfile currentUser={currentUser} />
              {/* <p>Profile & Settings</p> */}
            </div>
            <button className="themeToggle" onClick={() => onChangeTheme()}>
              {theme == "Dark" ? (
                <BsMoonStars size="1.5em" />
              ) : (
                <BsSun size="1.5em" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          minWidth: 100,
          background: "#66000000",
          height: "100vh",
        }}
        onClick={() => setOpen(false)}
      >
        <p>click</p>
      </div>
    </Ul>
  );
};

export default MobileDrawer;
