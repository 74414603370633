import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { db } from "../../firebase/firebase.utils";
import { useDispatch, useSelector } from "react-redux";
import {
  addParticipant,
  removeParticipant,
  updateParticipant,
  resetParticipants,
} from "../../redux/videocall/videoCallSlice";
import DraggableStream from "./DraggableStream";

import {
  FaMicrophone,
  FaVideo,
  FaDesktop,
  FaVideoSlash,
  FaMicrophoneSlash,
  FaPhoneSlash,
  FaUsers
} from "react-icons/fa";

import { IoExit } from "react-icons/io5";

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "mobile";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};

function GroupMeeting({
  roomId,
  userId,
  userName,
  setOnCall,
  selectedGroupName,
  meetingFullScreen,
  setMeetingFullScreen
}) {
  const [localStream, setLocalStream] = useState(null);
  const [peerConnections, setPeerConnections] = useState({});
  const [hasJoined, setHasJoined] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);

  // Keep track of Firestore subscriptions so we can unsubscribe later
  const unsubscribes = useRef([]);

  const participants = useSelector((state) => state.videoCall.participants);
  const dispatch = useDispatch();

  // --------------------------------------------------------------------------
  // 1) removePeerConnection
  // --------------------------------------------------------------------------
  const removePeerConnection = useCallback(
    (participant) => {
      const pc = peerConnections[participant.userId];
      if (pc) {
        pc.close();
        const updated = { ...peerConnections };
        delete updated[participant.userId];
        setPeerConnections(updated);
      }
      dispatch(removeParticipant(participant.userId));
    },
    [peerConnections, dispatch]
  );

  // --------------------------------------------------------------------------
  // 2) createPeerConnection (Offerer side)
  // --------------------------------------------------------------------------
  const createPeerConnection = useCallback(
    async (participant, roomRef, stream) => {
      if (!stream) {
        console.warn("Local stream not ready, skipping createPeerConnection.");
        return;
      }

      console.log("Creating peer connection with:", participant.userId);
      const peerConnection = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      // Add local tracks
      stream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, stream);
      });

      // Connection doc: userId-userId
      const connectionRef = roomRef
        .collection("connections")
        .doc(`${userId}-${participant.userId}`);

      // Listen for ICE candidates (Offer side)
      peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          connectionRef
            .collection("offerCandidates")
            .add(event.candidate.toJSON())
            .then(() =>
              console.log(
                "Offer ICE candidate added to Firestore:",
                event.candidate
              )
            )
            .catch((error) =>
              console.error("Error adding Offer ICE candidate:", error)
            );
        }
      };

      // Listen for remote tracks
      peerConnection.ontrack = (event) => {
        console.log("Remote track added for:", participant.userId);
        dispatch(
          updateParticipant({
            userId: participant.userId,
            stream: event.streams[0],
          })
        );
      };

      // Create Offer
      const offerDescription = await peerConnection.createOffer();
      await peerConnection.setLocalDescription(offerDescription);

      // Write Offer and info to Firestore
      await connectionRef.set({
        offer: {
          type: offerDescription.type,
          sdp: offerDescription.sdp,
        },
        requester: userId,
        responder: participant.userId,
      });

      // Subscribe to connection doc for answer
      const connectionUnsubscribe = connectionRef.onSnapshot(
        async (snapshot) => {
          const data = snapshot.data();
          if (data?.answer && !peerConnection.currentRemoteDescription) {
            const answerDesc = new RTCSessionDescription(data.answer);
            await peerConnection.setRemoteDescription(answerDesc);
          }
        }
      );
      unsubscribes.current.push(connectionUnsubscribe);

      // Subscribe to answerCandidates subcollection
      const answerCandidatesUnsubscribe = connectionRef
        .collection("answerCandidates")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              try {
                const candidate = new RTCIceCandidate(change.doc.data());
                await peerConnection.addIceCandidate(candidate);
                console.log("Answer ICE candidate added:", candidate);
              } catch (err) {
                console.error("Error adding Answer ICE candidate:", err);
              }
            }
          });
        });
      unsubscribes.current.push(answerCandidatesUnsubscribe);

      // Store peer connection
      setPeerConnections((prev) => ({
        ...prev,
        [participant.userId]: peerConnection,
      }));
    },
    [userId, dispatch]
  );

  // --------------------------------------------------------------------------
  // 3) listenForNewParticipants (calls createPeerConnection, removePeerConnection)
  // --------------------------------------------------------------------------
  const listenForNewParticipants = useCallback(
    (roomRef, stream) => {
      const participantsCollection = roomRef.collection("participants");
      const unsubscribe = participantsCollection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const participant = change.doc.data();

          if (participant.userId !== userId) {
            if (change.type === "added") {
              // Add to Redux
              dispatch(
                addParticipant({
                  userId: participant.userId,
                  name: participant.name,
                  mic: participant.mic,
                  camera: participant.camera,
                })
              );
              // createPeerConnection defined above!
              createPeerConnection(participant, roomRef, stream);
            } else if (change.type === "modified") {
              // Update participant data in Redux
              dispatch(
                updateParticipant({
                  userId: participant.userId,
                  mic: participant.mic,
                  camera: participant.camera,
                })
              );
            } else if (change.type === "removed") {
              // removePeerConnection defined above!
              removePeerConnection(participant);
            }
          }
        });
      });
      unsubscribes.current.push(unsubscribe);
    },
    [userId, dispatch, createPeerConnection, removePeerConnection]
  );

  // --------------------------------------------------------------------------
  // 4) listenForConnections (Responder side)
  // --------------------------------------------------------------------------
  const listenForConnections = useCallback(
    (roomRef, stream) => {
      const connectionsCollection = roomRef.collection("connections");
      const unsubscribe = connectionsCollection.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          const data = change.doc.data();
          if (change.type === "added" && data.responder === userId) {
            console.log("Incoming connection from:", data.requester);

            // Create new RTCPeerConnection
            const peerConnection = new RTCPeerConnection({
              iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
            });

            // Add local tracks
            stream.getTracks().forEach((track) => {
              peerConnection.addTrack(track, stream);
            });

            // Listen for ICE candidates (Responder side)
            peerConnection.onicecandidate = (event) => {
              if (event.candidate) {
                change.doc.ref
                  .collection("answerCandidates")
                  .add(event.candidate.toJSON())
                  .then(() =>
                    console.log("Answer ICE candidate added:", event.candidate)
                  )
                  .catch((error) =>
                    console.error("Error adding Answer ICE candidate:", error)
                  );
              }
            };

            // Listen for remote track
            peerConnection.ontrack = (event) => {
              console.log("Remote track from:", data.requester);
              dispatch(
                updateParticipant({
                  userId: data.requester,
                  stream: event.streams[0],
                })
              );
            };

            // Set remote description (Offer)
            const offerDesc = new RTCSessionDescription(data.offer);
            await peerConnection.setRemoteDescription(offerDesc);

            // Create an Answer, set local description
            const answerDesc = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answerDesc);

            // Write answer to Firestore
            await change.doc.ref.update({
              answer: {
                type: answerDesc.type,
                sdp: answerDesc.sdp,
              },
            });

            // Listen for Offer ICE candidates
            const offerCandidatesUnsubscribe = change.doc.ref
              .collection("offerCandidates")
              .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach(async (candChange) => {
                  if (candChange.type === "added") {
                    try {
                      const candidate = new RTCIceCandidate(
                        candChange.doc.data()
                      );
                      await peerConnection.addIceCandidate(candidate);
                      console.log("Offer ICE candidate added:", candidate);
                    } catch (err) {
                      console.error("Error adding Offer ICE candidate:", err);
                    }
                  }
                });
              });
            unsubscribes.current.push(offerCandidatesUnsubscribe);

            // Store peer connection
            setPeerConnections((prev) => ({
              ...prev,
              [data.requester]: peerConnection,
            }));
          }
        });
      });

      unsubscribes.current.push(unsubscribe);
    },
    [userId, dispatch]
  );

  // --------------------------------------------------------------------------
  // 5) getLocalStream
  // --------------------------------------------------------------------------
  const getLocalStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      setLocalStream(stream);
      return stream;
    } catch (error) {
      console.error("Error accessing media devices:", error);
      alert(
        "Error accessing camera or microphone. Please check permissions in your browser."
      );
      return null;
    }
  }, []);

  // --------------------------------------------------------------------------
  // 6) joinRoom
  // --------------------------------------------------------------------------
  const joinRoom = useCallback(async () => {
    const stream = await getLocalStream();
    if (!stream) return;

    const roomRef = db.collection("rooms").doc(roomId);
    const participantRef = roomRef.collection("participants").doc(userId);

    // Add current user into Firestore participants
    await participantRef
      .set({ userId, name: userName, mic: true, camera: true }, { merge: true })
      .then(() => console.log("Successfully added participant:", userId))
      .catch((error) => console.error("Error adding participant:", error));

    // Update Redux store: add self as participant
    dispatch(
      addParticipant({ userId, name: userName, mic: true, camera: true })
    );

    // Start listeners
    listenForNewParticipants(roomRef, stream);
    listenForConnections(roomRef, stream);

    setHasJoined(true);
    setOnCall(true);
  }, [roomId, userId, userName, getLocalStream, dispatch, setOnCall]);

  // --------------------------------------------------------------------------
  // 7) leaveRoom
  // --------------------------------------------------------------------------
  const leaveRoom = useCallback(async () => {
    setMeetingFullScreen(false);
    // 1) Close all peer connections
    Object.values(peerConnections).forEach((pc) => pc.close());
    setPeerConnections({});

    // 2) Stop local stream
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }

    // 3) Unsubscribe from Firestore
    unsubscribes.current.forEach((unsub) => unsub());
    unsubscribes.current = [];

    // 4) Remove participant from Firestore
    const roomRef = db.collection("rooms").doc(roomId);
    await roomRef.collection("participants").doc(userId).delete();

    // 5) Remove connections involving this user
    const connectionsCollection = await roomRef.collection("connections").get();
    for (const docSnap of connectionsCollection.docs) {
      const data = docSnap.data();
      if (data.requester === userId || data.responder === userId) {
        // Delete subcollections
        const offerCandidates = await docSnap.ref
          .collection("offerCandidates")
          .get();
        for (const c of offerCandidates.docs) {
          await c.ref.delete();
        }

        const answerCandidates = await docSnap.ref
          .collection("answerCandidates")
          .get();
        for (const c of answerCandidates.docs) {
          await c.ref.delete();
        }

        // Finally delete the connection doc
        await docSnap.ref.delete();
      }
    }

    // 6) If no participants left, delete room
    const remainingParticipants = await roomRef
      .collection("participants")
      .get();
    if (remainingParticipants.empty) {
      await roomRef.delete();
    }

    // 7) Reset local states / Redux
    setHasJoined(false);
    setIsMuted(false);
    setIsCameraOn(true);
    dispatch(resetParticipants());
    setOnCall(false);
  }, [peerConnections, localStream, roomId, userId, dispatch, setOnCall]);

  // --------------------------------------------------------------------------
  // 8) toggleMute
  // --------------------------------------------------------------------------
  const toggleMute = useCallback(() => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setIsMuted(!audioTrack.enabled);

        db.collection("rooms")
          .doc(roomId)
          .collection("participants")
          .doc(userId)
          .update({ mic: audioTrack.enabled });
      }
    }
  }, [localStream, roomId, userId, isMuted]);

  // --------------------------------------------------------------------------
  // 9) toggleCamera
  // --------------------------------------------------------------------------
  const toggleCamera = useCallback(() => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        setIsCameraOn(videoTrack.enabled);

        db.collection("rooms")
          .doc(roomId)
          .collection("participants")
          .doc(userId)
          .update({ camera: videoTrack.enabled });
      }
    }
  }, [localStream, roomId, userId, isCameraOn]);

  // --------------------------------------------------------------------------
  // 10) switchCamera (web placeholder)
  // --------------------------------------------------------------------------
  const switchCamera = useCallback(() => {
    alert(
      "Camera switching is not directly supported on web. Implement as needed."
    );
  }, []);

  // --------------------------------------------------------------------------
  // RENDER REMOTE STREAMS
  // --------------------------------------------------------------------------
  const renderRemoteStreams = useMemo(() => {
    const remoteParticipants = participants.filter((p) => p.userId !== userId);

    if (remoteParticipants.length === 0) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 240,
            borderRadius: 8,
          }}
        >
          <p style={{ alignSelf: "flex-end", marginBottom: 70 }}>
            <b>~ No other user is in the room. ~</b>
          </p>
        </div>
      );
    }

    return remoteParticipants.map((p) => (
      <div
        key={p.userId}
        style={{
          position: "relative",
          margin: 4,
          // width: 320,
          // height: 240,
          backgroundColor: "#333",
          borderRadius: 8,
        }}
      >
        {p.camera && p.stream ? (
          <video
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: 6,
            }}
            autoPlay
            playsInline
            ref={(ref) => {
              if (ref && p.stream) {
                ref.srcObject = p.stream; // Attach the MediaStream directly
              }
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ alignSelf: "flex-end", marginBottom: 70 }}>
              {p.name}'s camera is off
            </p>
          </div>
        )}
        {!p.mic && (
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "white",
            }}
          >
            <p style={{ fontSize: 12, backgroundColor: "rgba(0,0,0,0.5)" }}>
              Mic Off
            </p>
          </div>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 5,
            left: 5,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: "2px 4px",
            borderRadius: 4,
          }}
        >
          {p.name}
        </div>
      </div>
    ));
  }, [participants, userId]);

  // --------------------------------------------------------------------------
  // Cleanup on unmount
  // --------------------------------------------------------------------------
  useEffect(() => {
    return () => {
      unsubscribes.current.forEach((unsub) => unsub());
      unsubscribes.current = [];
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {hasJoined ? (
        <>
          {/* REMOTE STREAMS */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              height: "calc(100% - 60px)",
              justifyContent: "space-around",
              alignItems: "center"
            }}
          >
            {renderRemoteStreams}
          </div>

          {/* LOCAL STREAM (Draggable) */}
          {localStream && (
            <DraggableStream
              stream={localStream}
              userName={userName}
              isCameraOn={isCameraOn}
              isMuted={isMuted}
            />
          )}

          {/* Bottom Controls */}
          <div
            style={{
              position: "relative",
              bottom: -10,
              left: 10,
              width: "fit-content",
              display: "flex",
              gap: 10,
            }}
          >
            {/* Toggle Mute */}
            <button
              onClick={toggleMute}
              style={{
                padding: 10,
                backgroundColor: meetingFullScreen ? "black" : "lightgray",
                borderRadius: 8,
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                columnGap: 3
              }}
            >
              {isMuted ? (
                <FaMicrophone title="UnMute" size="1.2rem" color={meetingFullScreen ? "white" : "black"} />
              ) : (
                <FaMicrophoneSlash title="Mute" size="1.2rem" color={meetingFullScreen ? "white" : "black"} />
              )} 
              {meetingFullScreen && <p style={{color: "white"}}>{isMuted ? "Unmute" : "Mute"}</p> }
            </button>

            {/* Toggle Camera */}
            <button
              onClick={toggleCamera}
              style={{
                padding: 10,
                backgroundColor: meetingFullScreen ? "black" : "lightgray",
                borderRadius: 8,
                cursor: "pointer",
                alignItems: "center",
                display: "flex",
                columnGap: 3
              }}
            >
              {isCameraOn ? (
                <FaVideoSlash title="Cam Off" size="1.2rem" color={meetingFullScreen ? "white" : "black"} />
              ) : (
                <FaVideo title="Cam On" size="1.2rem" color={meetingFullScreen ? "white" : "black"} />
              )}
              {meetingFullScreen && <p style={{color: "white"}}>{isCameraOn ? "Camera Off" : "Camera On"}</p>}
            </button>

            {/* Switch Camera (placeholder) */}
            {getDeviceType === "mobile" && (
              <button
                onClick={switchCamera}
                style={{
                  padding: 10,
                  backgroundColor: "lightgray",
                  borderRadius: 8,
                  cursor: "pointer",
                }}
              >
                Switch Camera
              </button>
            )}
          {/* Leave Room Button */}
          <button
            onClick={leaveRoom}
            style={{
              padding: 10,
              backgroundColor: "red",
              color: "white",
              borderRadius: 8,
              // position: "absolute",
              // top: 10,
              // right: 10,
              cursor: "pointer",
              alignItems: "center",
              display: "flex",
              columnGap: 3
            }}
          >
            <IoExit size="1.2rem"/>
            {meetingFullScreen && <>Leave</>}
          </button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <button
            onClick={joinRoom}
            style={{
              display: "flex",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              // margin: 10,
              // backgroundColor: "lightblue",
              borderRadius: 8,
              cursor: "pointer",
              fontSize: "1rem",
              width: "100%",
              height: 60,
              alignItems: "center",
              justifyContent: "center",
              columnGap: 10,
            }}
          >
            <FaUsers title="Join Meeting" size="1.2rem" />
            <span>Join {selectedGroupName && <strong>{selectedGroupName}</strong>} group meeting</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default GroupMeeting;
