import React, { useState, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";
import { db } from "../../firebase/firebase.utils";
import Tooltip from "../retro/tooltip/tooltip.component";

import { BsStopwatch } from "react-icons/bs";

import "./duration.styles.scss";

const Duration = ({
  task,
  open,
  duration,
  setDuration,
  setShowInvitation,
  showInvitation,
  currentUser,
  handleUpdateTaskDuration
}) => {
  const userEntitlement = useSelector((state) => state.user.entitlement);
  const [showduration, setShowDuration] = useState(false);
  // const [duration, setDuration] = useState();

  const handlesetShowDuration = (e) => {
    e.preventDefault();
    setShowDuration(!showduration);
  };

  

  // const handlesetDuration = (e) => {

  // }

  // const { createdBy } = task;

  // useEffect(() => {
  //   if (open) {
  //     setShowDuration(true);
  //   }
  // }, [open]);

  // useEffect(() => {
  //   db.collection("users")
  //     .doc(createdById)
  //     .onSnapshot((doc) => {
  //       let creatoruser = doc.data();
  //       setUser(creatoruser);
  //     });
  // }, []);

  // useMemo(() => {
  //   if (!users) return;
  //   const userIndex = users.findIndex((item) => item.uid === createdBy);
  //   setUser(users[userIndex]);
  // }, [users, createdBy]);

  // useMemo(() => {
  //   setDuration(task.duration);
  // }, [task]);

  // useMemo(() => {
  //   setState(task.subtasks);
  // }, [task]);

  // useEffect(() => {
  //   if (open) {
  //     setShowDuration(false);
  //   }
  // }, [open]);

  return (
    <div className="tm__durationest">
      {/* <BsStopwatch onClick={() => setShowDuration(!showduration)} /> */}
      <input
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
        placeholder="Duration est."
      ></input>
      {userEntitlement == "free" ? (
        <button onClick={() => setShowInvitation(!false)}>
          <BsStopwatch size="1.3em" />
        </button>
      ) : (
        <button onClick={handlesetShowDuration}>
          <BsStopwatch size="1.3em" />
        </button>
      )}

      <Tooltip text="Duration Estimate" />

      {showduration && (
        <div className="duration__container">
          <div
            className="duration__tag"
            // onClick={handlesetDuration(false)}
            onClick={() => handleUpdateTaskDuration("00:05") & setShowDuration(false)}
          >
            5m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:10") & setShowDuration(false)}
          >
            10m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:15") & setShowDuration(false)}
          >
            15m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:20") & setShowDuration(false)}
          >
            20m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:25") & setShowDuration(false)}
          >
            25m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:30") & setShowDuration(false)}
          >
            30m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("00:45") & setShowDuration(false)}
          >
            45m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("01:00") & setShowDuration(false)}
          >
            1h
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("01:15") & setShowDuration(false)}
          >
            1h 15m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("01:30") & setShowDuration(false)}
          >
            1h 30m
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("02:00") & setShowDuration(false)}
          >
            2h
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("03:00") & setShowDuration(false)}
          >
            3h
          </div>
          <div
            className="duration__tag"
            onClick={() => handleUpdateTaskDuration("04:00") & setShowDuration(false)}
          >
            4h
          </div>
        </div>
      )}
    </div>
  );
};

export default Duration;
