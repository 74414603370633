import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Picker } from "emoji-mart";
import EmojiPicker from "../emoji/emojiPicker";

import { Link } from "react-router-dom";

import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import {
  db,
  changeDescriptionOfStation,
  changeNameOfStation,
  changeEmojiconOfStation,
  deleteStation,
} from "../../firebase/firebase.utils";

import FavoriteStar from "../favorite-star/favorite-star.component";

import MiniMenu from "../retro/mini-menu/mini-menu.component";
import Loading from "../retro/loading/loading.component";
import BoxLayer from "../retro/box-layer/box-layer.component";

import GoBackButton from "../ui-elements/gobackbutton";

import EmojiPick from "../emoji/emoji.component";
import { MdOutlineEmojiFoodBeverage } from "react-icons/md";
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  BsChevronRight,
  BsChevronLeft,
  BsArrowBarLeft,
  BsThreeDotsVertical,
  BsTrash3Fill,
} from "react-icons/bs";

import { FaICursor, FaTools, FaTrash } from "react-icons/fa";
// import { SlArrowLeft, SlArrowRight } from "react-icons/sl";

import "./station-info.styles.scss";
import RetroButton from "../retro/button/retro-button.component";

const StationInfo = ({ data, currentSpaceId, currentStationId }) => {
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [showMiniMenu, setShowMiniMenu] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const navigate = useNavigate();
  const inputRefName = useRef();

  const [inputEmojicon, setInputEmojicon] = useState("");

  // const [showDescription, setshowDescription] = useState(false);

  const [spaceDataForNonSpaceMembers, setSpaceDataForNonSpaceMembers] = useState(null);
  const activeSpaceData = useActiveSpaceData();

  const handleCloseDescription = (e) => {
    setInfoIsOpen(false);
    handleDescription(e);
  };

  const handleDescription = (e) => {
    e.preventDefault();
    changeDescriptionOfStation(
      currentSpaceId,
      currentStationId,
      inputDescription
    );
  };

  const handleEmoji = (e) => {
    e.preventDefault();
    changeEmojiconOfStation(currentSpaceId, currentStationId, inputEmojicon);
  };

  const handleName = (e) => {
    e.preventDefault();
    changeNameOfStation(currentSpaceId, currentStationId, inputName);
  };

  const handleDeleteStation = async () => {
    try {
      await deleteStation(currentSpaceId, currentStationId);
    } catch (error) {
      console.log(error.message);
    } finally {
      navigate(`/s/${currentSpaceId}`);
      setShowLoading(false);
      setShowMiniMenu(false);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const defaultEmoji = {
    id: "heart_eyes",
    name: "Smiling Face with Heart-Shaped Eyes",
    short_names: ["black_circle"],
    colons: ":black_circle:",
    emoticons: [],
    unified: "1f60d",
    skin: null,
    native: "◾",
  };

  const [emoji, setEmoji] = useState(defaultEmoji);
  const onSelectEmoji = (emoji) => {
    setEmoji(emoji);
    setIsOpen(!isOpen);
    changeEmojiconOfStation(currentSpaceId, currentStationId, emoji.native);
  };

  const [infoisOpen, setInfoIsOpen] = useState(false);

  // const increaseWidth = (x) => {
  //   let numberOfCharacters = x.value.length;
  //   if (numberOfCharacters >= 10) {
  //     let length = numberOfCharacters;
  //     x.style.width;
  //   }
  // };

  const [spacenameref, setSpacenameref] = useState([]);
  useEffect(() => {
    //mounts
    const unsub = db
      .collection("space")
      .where("spaceId", "==", currentSpaceId)
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            name: doc.get("name"),
            color: doc.get("color"),
          };
        });
        setSpacenameref([...array]);
      });

    return () => {
      unsub();
    };
  }, []);

  return (
    <div className="stationInfo">
      {showLoading && <Loading />}
      {data && (
        <div className="si__title">
          <div className="si__name">
            {/* <BsChevronLeft
              size="1.8em"
              strokeWidth="0.5"
              className="si__breadcrumb_arrowleft"
            /> */}
            <GoBackButton />
            {spacenameref?.map((space) => (
              <div key={space.id} value={space.id}>
                <Link
                  className="breadcrumb__spaceName"
                  to={`/s/${currentSpaceId}`}
                >
                  {/* {space?.name} */}
                  {/* {currentSpaceId} */}
                  {activeSpaceData ? <>
                  <RetroButton style={{ background: activeSpaceData.color }}>
                          {activeSpaceData.name.charAt(0)}
                  </RetroButton>
                  <h2>
                  {activeSpaceData.name}
                  </h2>
                  </> :                   <>
                  <RetroButton style={{ background: spacenameref[0].color }}>
                          {spacenameref[0].name.charAt(0)}
                  </RetroButton>
                  <h2>
                  {spacenameref[0].name}
                  </h2>
                  </>}
                </Link>
              </div>
            ))}
            <BsChevronRight
              size="0.8em"
              strokeWidth="0.5"
              className="si__breadcrumb_arrowright"
            />
            <div className="EmojiIcon">
              <button
                className="EmojiPick"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <button> {data.emojicon}</button>
                {/* <MdOutlineEmojiFoodBeverage /> */}
              </button>
              <div className="PickerOn">
                {isOpen && (
                  <EmojiPicker onEmojiSelect={onSelectEmoji} onClickOutside={() => setIsOpen(false)} />
                )}
              </div>
            </div>
            <form
              onSubmit={(e) => handleName(e)}
              style={{ width: `${data?.name?.length + 2}ch` }}
            >
              <input
                // contenteditable="true"
                type="text"
                value={inputName}
                // onInput={(e) => increaseWidth(e.target.value)}
                placeholder={data.name}
                ref={inputRefName}
                onChange={(e) => setInputName(e.target.value)}
                // style={{width:data.name.length}}
                // style={{ width: `${inputRefName.length}ch` }}
              />
            </form>
            <div className="si__buttonsgroup">
              <div className="sm__favorite">
                <FavoriteStar data={data} />
              </div>
              <div className="sm__settings">
                <BsThreeDotsVertical
                  size="1em"
                  onClick={() => setShowMiniMenu(!showMiniMenu)}
                />
                {showMiniMenu && (
                  <MiniMenu setLayer={setShowMiniMenu}>
                    <ul>
                      <li
                        onClick={() => {
                          inputRefName.current.focus();
                          setShowMiniMenu(false);
                        }}
                      >
                        <div className="tooltip">Rename</div>
                        <FaICursor />
                      </li>
                      <li onClick={() => setshowDelete(true)}>
                        <div className="tooltip">Delete</div>
                        <BsTrash3Fill />
                      </li>
                    </ul>
                  </MiniMenu>
                )}
              </div>
              {showDelete && (
                <BoxLayer setLayer={setshowDelete}>
                  <h2>Delete project {data.name} ?</h2>
                  <div className="si__btns">
                    <RetroButton
                      mode="gray"
                      onClick={() => setshowDelete(false)}
                    >
                      Cancel
                    </RetroButton>
                    <RetroButton
                      color="danger"
                      onClick={() => handleDeleteStation()}
                    >
                      Delete
                    </RetroButton>
                  </div>
                </BoxLayer>
              )}
              {/* <div className="si__description-group">
                <AiOutlineInfoCircle
                  className="si__info_svg"
                  onClick={() => setInfoIsOpen(!infoisOpen)}
                />
                <div className="si__description">
                  {infoisOpen && (
                    <div>
                      <form onSubmit={(e) => handleDescription(e)}>
                        <div
                          className="si__description-overlay"
                          onClick={(e) => handleCloseDescription(e)}
                        ></div>
                        <div className="si__description-content">
                          <textarea
                            contenteditable="true"
                            placeholder={data.description}
                            onChange={(e) =>
                              setInputDescription(e.target.value)
                            }
                          />
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StationInfo;
