import React, { useEffect, useRef, useState } from 'react';
import { db } from '../../firebase/firebase.utils';

import {
  FaMicrophone,
  FaVideo,
  FaDesktop,
  FaVideoSlash,
  FaMicrophoneSlash,
  FaPhoneSlash,
} from "react-icons/fa";

import {
  BsFullscreen,
  BsFullscreenExit
} from "react-icons/bs";

import { IoCallOutline } from "react-icons/io5";

function Meet({ meetingId, setOnCall, currentrecipient }) {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [gettingCall, setGettingCall] = useState(false);

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const pc = useRef(null);
  const connecting = useRef(false);

  // ICE Server configuration
  const peerConstraints = {
    iceServers: [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: 'stun:stun1.l.google.com:19302' },
      { urls: 'stun:stun2.l.google.com:19302' },
      { urls: 'stun:stun3.l.google.com:19302' },
      { urls: 'stun:stun4.l.google.com:19302' },
    ],
  };

  // ==========
  // Lifecycle
  // ==========

  useEffect(() => {
    const cRef = db.collection('meet').doc(meetingId);

    // Listen for changes in the main doc (offer/answer)
    const unsubscribe = cRef.onSnapshot(async (snapshot) => {
      const data = snapshot.data();
      if (!data) return;

      // If there is an answer on the doc and PC hasn't set remoteDescription yet
      if (pc.current && !pc.current.remoteDescription && data.answer) {
        await pc.current.setRemoteDescription(
          new RTCSessionDescription(data.answer)
        );
      }

      // If there is an offer on the doc and we're not already connecting
      if (data.offer && !connecting.current) {
        setGettingCall(true);
      }
    });

    // Listen for removal in "callee" sub-collection
    const calleeSub = cRef
      .collection('callee')
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'removed') {
            hangup();
          }
        });
      });

    return () => {
      unsubscribe();
      calleeSub();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Attach local stream to video element
    if (localVideoRef.current && localStream) {
      localVideoRef.current.srcObject = localStream;
    }
  }, [localStream]);

  useEffect(() => {
    // Attach remote stream to video element
    if (remoteVideoRef.current && remoteStream) {
      remoteVideoRef.current.srcObject = remoteStream;
    }
  }, [remoteStream]);

  // ==========
  // Functions
  // ==========

  async function setupWebrtc() {
    pc.current = new RTCPeerConnection(peerConstraints);

    // Get local stream and add tracks
    const stream = await getLocalStream();
    if (stream) {
      setLocalStream(stream);
      stream.getTracks().forEach((track) => {
        pc.current.addTrack(track, stream);
      });
    }

    // When a remote track is received, set the remote stream
    pc.current.ontrack = (event) => {
      const [remoteStream] = event.streams;
      setRemoteStream(remoteStream);
    };
  }

  async function create() {
    console.log('calling');
    connecting.current = true;

    await setupWebrtc();
    const cRef = db.collection('meet').doc(meetingId);

    collectIceCandidates(cRef, 'caller', 'callee');

    if (pc.current) {
      try {
        const offerDescription = await pc.current.createOffer();
        await pc.current.setLocalDescription(offerDescription);

        const cWithOffer = {
          offer: {
            type: offerDescription.type,
            sdp: offerDescription.sdp,
          },
        };

        // In namespaced API, you can do cRef.set(...) directly
        await cRef.set(cWithOffer);
      } catch (error) {
        console.log('error', error);
      }
    }
  }

  async function join() {
    console.log('Joining the call');
    connecting.current = true;
    setGettingCall(false);

    const cRef = db.collection('meet').doc(meetingId);
    const offerDoc = await cRef.get();
    const offer = offerDoc.data()?.offer;
    if (offer) {
      await setupWebrtc();

      collectIceCandidates(cRef, 'callee', 'caller');

      if (pc.current) {
        await pc.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await pc.current.createAnswer();
        await pc.current.setLocalDescription(answer);

        const cWithAnswer = {
          answer: {
            type: answer.type,
            sdp: answer.sdp,
          },
        };

        await cRef.update(cWithAnswer);
        setOnCall(true);
      }
    }
  }

  async function hangup() {
    console.log('hangup');
    setGettingCall(false);
    connecting.current = false;
    await streamCleanUp();
    await firebaseCleanUp();
    if (pc.current) {
      pc.current.close();
    }
  }

  async function getLocalStream() {
    let isVoiceOnly = false;
    let mediaConstraints = {
      audio: true,
      video: {
        frameRate: 30,
        facingMode: 'user',
      },
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
      if (isVoiceOnly) {
        const videoTrack = stream.getVideoTracks()[0];
        if (videoTrack) {
          videoTrack.enabled = false;
        }
      }
      return stream;
    } catch (err) {
      console.log('Error getting local stream:', err);
      return null;
    }
  }

  async function streamCleanUp() {
    console.log('streamCleanUp');
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }
    setLocalStream(null);
    setRemoteStream(null);
  }

  async function firebaseCleanUp() {
    console.log('firebaseCleanUp');
    const cRef = db.collection('meet').doc(meetingId);
    const calleeCandidates = await cRef.collection('callee').get();
    calleeCandidates.forEach(async (doc) => {
      await doc.ref.delete();
    });

    const callerCandidates = await cRef.collection('caller').get();
    callerCandidates.forEach(async (doc) => {
      await doc.ref.delete();
    });

    await cRef.delete();
  }

  function collectIceCandidates(cRef, localName, remoteName) {
    const candidatesCollection = cRef.collection(localName);

    // On new ICE candidate, add it to firestore
    if (pc.current) {
      pc.current.onicecandidate = (event) => {
        if (event.candidate) {
          candidatesCollection.add(event.candidate.toJSON());
        }
      };
    }

    // Listen for remote ICE candidates
    cRef.collection(remoteName).onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'added') {
          const candidate = new RTCIceCandidate(change.doc.data());
          pc.current.addIceCandidate(candidate);
        }
      });
    });
  }

  // ==========
  // Render
  // ==========

  // If someone is calling us
  if (gettingCall) {
    return (
      <div style={styles.container}>
        <h3>Incoming Call</h3>
        <button onClick={hangup}>Reject</button>
        <button onClick={join}>Accept</button>
      </div>
    );
  }

  // If we have a local stream, show both video feeds
  if (localStream) {
    return (
      <div style={styles.container}>
        <h3>On Call</h3>
        <div style={styles.videoContainer}>
          <video
            ref={localVideoRef}
            autoPlay
            playsInline
            muted
            style={styles.video}
          />
          <video
            ref={remoteVideoRef}
            autoPlay
            playsInline
            style={styles.video}
          />
        </div>
        <button onClick={hangup}>Hang Up</button>
      </div>
    );
  }

  // If no call yet, show button to create (start) a call
  return (
    <div style={styles.callBtnContainer}>
      <IoCallOutline color="black" size="1.2rem"/>
      <button onClick={create}><p style={{fontSize: "1rem", padding: "10px 5px"}}><b>Call {currentrecipient?.userName}</b></p></button>
    </div>
  );
}

// Basic inline styling (replace with your own CSS as desired)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    // marginTop: '30px',
  },
  callBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    // marginTop: '30px',
  },
  videoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  video: {
    width: '300px',
    height: '200px',
    backgroundColor: '#000',
  },
};

export default Meet;
