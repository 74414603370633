import React, { useState, useRef } from "react";

/**
 * A simple, manual drag-and-drop for a video stream.
 * For production, consider using "react-draggable".
 */
function DraggableStream({ stream, userName, isMuted, isCameraOn }) {
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState({ x: 0, y: 0 }); // mouse offset inside the div

  // Attach the local stream once the video ref is ready
  React.useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  // MouseDown
  const onMouseDown = (e) => {
    // Only start drag on the container, not the video controls
    if (e.target === videoRef.current) return;

    setDragging(true);
    // record the mouse offset inside the element
    setRel({
      x: e.pageX - position.x,
      y: e.pageY - position.y,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  // MouseUp
  const onMouseUp = (e) => {
    setDragging(false);
    e.stopPropagation();
    e.preventDefault();
  };

  // MouseMove
  const onMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.pageX - rel.x,
      y: e.pageY - rel.y,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div
      ref={containerRef}
      // draggable="true"
      style={{
        position: "absolute",
        left: position.x,
        top: position.y,
        width: 90,
        height: 120,
        backgroundColor: "#000",
        cursor: dragging ? "grabbing" : "grab",
        borderRadius: 8,
        overflow: "hidden",
        zIndex: 9999, // to stay on top
        border: "1.5px solid black",
      }}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseUp}
    >
      {/* If camera is on, show video; otherwise, show placeholder */}
      {isCameraOn ? (
        <video
          ref={videoRef}
          autoPlay
          muted // local stream is typically muted to avoid echo
          playsInline
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "gray",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Camera Off
        </div>
      )}

      {/* Mute indicator, camera indicator, or user name overlay, etc. */}
      {/* Example: show user name at bottom left */}
      <div
        style={{
          position: "absolute",
          bottom: 2,
          left: 2,
          color: "white",
          fontSize: "0.8rem",
          backgroundColor: "rgba(0,0,0,0.4)",
          borderRadius: 4,
          padding: "2px 4px",
        }}
      >
        {userName}
      </div>

      {!isMuted ? null : (
        <div
          style={{
            position: "absolute",
            top: 2,
            right: 2,
            color: "white",
            fontSize: "0.8rem",
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: 4,
            padding: "2px 4px",
          }}
        >
          Muted
        </div>
      )}
    </div>
  );
}

export default DraggableStream;
