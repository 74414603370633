import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import {
  db,
  fieldValue,
  createMessageToWorkspace,
  createMessageToProject,
  createMessageToPerson,
} from "../../firebase/firebase.utils";
import { useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { useActiveSpaceData } from "../../hooks/useActiveSpaceData.hook";

import Meet from "../../components/meet/meet";
import GroupMeeting from "../../components/meet/GroupMeeting";

import RetroButton from "../../components/retro/button/retro-button.component";
import Avatar from "../../components/retro/avatar/avatar.component";
import Message from "../../components/message/message.component";

import { BsEmojiSmileUpsideDown, BsTrash, BsPersonCheck, BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { IoMdChatboxes } from "react-icons/io";
import { IoPeopleOutline } from "react-icons/io5";
import { AiOutlineFullscreen } from "react-icons/ai";
import { HiOutlineChatBubbleLeftEllipsis, HiXMark } from "react-icons/hi2";

import { FaPaperPlane } from "react-icons/fa";

import EmojiPicker from "../../components/emoji/emojiPicker";

import "./chat.styles.scss";

function Chat() {
  const users = useSelector((state) => state.user.users);
  const spaceData = useSelector((state) => state.space.spaceData);
  const [searchParams, setSearchParams] = useSearchParams();

  const [chatIsOpen, SetChatIsOpen] = useState(false);
  const [chatx, setChatX] = useState("-320px");
  const activeSpaceData = useActiveSpaceData();
  const currentUser = useSelector((state) => state.user.currentUser);
  const [msgs, setMsgs] = useState([]);
  const [projectMsgs, setProjectMsgs] = useState([]);
  const [personalMsgs, setPersonalMsgs] = useState([]);
  const [chatmessage, setChatMessage] = useState("");

  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const textareaRef = useRef();

  const [memberData, setMemberData] = useState([]);

  const [emojiOpen, setEmojiOpen] = useState(false);

  const [reactionpickerOpen, setReactionPickerOpen] = useState(false);
  const [sendtomode, setSendtomode] = useState("");
  const [sendto, setSendto] = useState(null);
  const [sendtoWsId, setSendtoWsId] = useState(null);
  const [currentStationName, setCurrentStationName] = useState("");
  const [currentStationIcon, setCurrentStationIcon] = useState("");
  const [buttonlook, setButtonLook] = useState("nopersonselected");
  const [contactsopen, setContactsOpen] = useState(false);
  const [currentrecipient, setCurrentRecipient] = useState("");
  const [meetingFullScreen, setMeetingFullScreen] = useState(false);
  const [showInstantChat, setShowInstantChat] = useState(true);

  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("");
  const [projectOptions, setProjectOptions] = useState([]);

  const [showGroupSelect, setShowGroupSelect] = useState(false);
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [selectedGroupColor, setSelectedGroupColor] = useState(null);
  const [selectedGroupIcon, setSelectedGroupIcon] = useState(null);

  const [onCall, setOnCall] = useState(false);

  const wschat = searchParams.get("wschat");
  const prchat = searchParams.get("prchat");
  const dmchat = searchParams.get("dmchat");

  useEffect(() => {
    if (wschat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toworkspace");
      setSendto(wschat);
      setSelectedWorkspaceId(wschat);
      setSelectedGroupName(activeSpaceData?.name);
      setSelectedGroupColor(activeSpaceData?.color);
    }
    if (prchat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toproject");
      setSendto(prchat);
      setSelectedWorkspaceId(currentSpaceId);
      setSelectedGroupName(currentStationName);
      setSelectedGroupIcon(currentStationIcon);
    }
    if (dmchat?.length > 0) {
      SetChatIsOpen(true);
      setSendtomode("toperson");
      const findSendto = () => {
        const convertedConvId = dmchat.replace(currentUser.uid, "");
        const memberIndex = memberData.findIndex(
          (member) => member.uid === convertedConvId
        );
        setSendto(convertedConvId);
        setButtonLook("personselected");
        setCurrentRecipient(memberData[memberIndex]);
        setContactsOpen(false);
      };
      findSendto();
    }
  }, [memberData, wschat, prchat, dmchat, currentStationIcon]);

  useEffect(() => {
    let reflist = [];
    if (spaceData) {
      spaceData.forEach((space) => reflist.push(...space.members));
    }

    if (users) {
      const membs = users.filter((user) => reflist.includes(user.uid));
      setMemberData(membs);
    }
  }, [spaceData, users]);

  //Read Current Station Name
  useEffect(() => {
    //mounts
    if (currentStationId) {
      const unsub = db
        .collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .onSnapshot((docSnap) => {
          const stationNameRef = docSnap.data().name;
          const stationIconRef = docSnap.data().emojicon;
          setCurrentStationName(stationNameRef);
          setCurrentStationIcon(stationIconRef);
        });

      return () => {
        unsub();
      };
    }
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    if (currentUser?.onCall) {
      SetChatIsOpen(true);
      const currentrecipientref = memberData?.filter(
        (member) => member?.uid === currentUser?.onCall
      );
      setSendtomode("toperson");
      setSendto(currentUser.onCall);
      setButtonLook("personselected");
    }
  }, [currentUser.onCall]);

  useEffect(() => {
    if (currentUser?.onCall) {
      const currentrecipientref = memberData?.filter(
        (member) => member?.uid === currentUser?.onCall
      );
      setCurrentRecipient(currentrecipientref[0]);
    }
  }, [currentUser.onCall, personalMsgs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!chatmessage) {
      alert("no message");
      return;
    }
    if (sendtomode === "toworkspace") {
      createMessageToWorkspace(
        currentSpaceId,
        chatmessage,
        currentUser?.uid,
        sendto,
        sendtomode,
        currentUser?.userName,
        currentUser.imageUrl,
        activeSpaceData?.name
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
    if (sendtomode === "toproject") {
      createMessageToProject(
        currentSpaceId,
        currentStationId,
        chatmessage,
        currentUser.uid,
        sendto,
        sendtomode,
        currentUser?.userName,
        currentUser?.imageUrl,
        currentStationName
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
    if (sendtomode === "toperson") {
      createMessageToPerson(
        chatmessage,
        currentUser?.uid,
        sendto,
        currentUser?.userName,
        currentUser?.imageUrl
      );
      textareaRef.current.value = "";
      setChatMessage("");
    }
  };

  useEffect(() => {
    if (chatIsOpen) {
      setChatX(0);
    } else {
      setChatX("-320px");
    }
  }, [chatIsOpen]);

  //Read Workspace Messages
  useEffect(() => {
    //mounts
    if (sendtomode === "toworkspace") {
      const unsub = db
        .collection("space")
        .doc(selectedWorkspaceId)
        .collection("chats")
        // .where("type", "==", "toworkspace")
        .orderBy("created", "desc")
        .limit(15)
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((msg) => {
            return {
              id: msg.id,
              created: msg.get("created"),
              from: msg.get("from"),
              fromSpaceId: msg.get("fromSpaceId"),
              message: msg.get("message"),
              reaction: msg.get("reaction"),
              to: msg.get("to"),
            };
          });
          setMsgs([...array]);
        });

      return () => {
        unsub();
      };
    }
  }, [currentSpaceId, sendtomode, sendto]);

  //Read Project Messages
  useEffect(() => {
    //mounts
    if (sendtomode === "toproject") {
      const unsub = db
        .collection("space")
        .doc(selectedWorkspaceId)
        .collection("stations")
        .doc(sendto)
        .collection("chats")
        // .where("type", "==", "toworkspace")
        .orderBy("created", "desc")
        .limit(15)
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((msg) => {
            return {
              id: msg.id,
              created: msg.get("created"),
              from: msg.get("from"),
              fromSpaceId: msg.get("fromSpaceId"),
              message: msg.get("message"),
              reaction: msg.get("reaction"),
              to: msg.get("to"),
            };
          });
          setProjectMsgs([...array]);
        });

      return () => {
        unsub();
      };
    }
  }, [currentStationId, sendtomode, sendto]);

  //Read Personal Messages
  useEffect(() => {
    //mounts
    let convRefId =
      currentUser.uid < sendto
        ? currentUser.uid + sendto
        : sendto + currentUser.uid;

    const unsub = db
      .collection("dmchat")
      .where("conversationRef", "==", convRefId)
      .orderBy("created", "desc")
      .limit(15)
      .onSnapshot((querySnapshot) => {
        const array = querySnapshot.docs.map((msg) => {
          return {
            id: msg.id,
            created: msg.get("created"),
            from: msg.get("from"),
            message: msg.get("message"),
            reaction: msg.get("reaction"),
            to: msg.get("to"),
          };
        });
        setPersonalMsgs([...array]);
      });

    return () => {
      unsub();
    };
  }, [currentrecipient, sendto]);

  const handleDeleteMessage = (msg) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("chats")
      .doc(msg.id)
      .delete();
  };
  const handleDeleteProjectMessage = (msg) => {
    db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("chats")
      .doc(msg.id)
      .delete();
  };
  const handleDeletePersonalMessage = (msg) => {
    db.collection("chats").doc(msg.id).delete();
  };

  const [reactionmessageref, setReactionMessageRef] = useState();
  const [reaction, setReaction] = useState();

  const onSelectWsReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(sendto)
      .collection("chats")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelectPrjReaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("space")
      .doc(sendtoWsId)
      .collection("stations")
      .doc(sendto)
      .collection("chats")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelect1on1Reaction = (emoji) => {
    setReaction(emoji);
    setReactionPickerOpen(!reactionpickerOpen);
    db.collection("dmchat")
      .doc(reactionmessageref)
      .update({ reaction: emoji.native });
  };

  const onSelectEmoji = (emojiObject) => {
    const emoji = emojiObject.native;
    setChatMessage(chatmessage ? chatmessage + emoji : emoji);
    setEmojiOpen(!emojiOpen);
  };

  const generateConvRefId = () => {
    let convRefId =
      currentUser.uid < sendto
        ? currentUser.uid + sendto
        : sendto + currentUser.uid;
    return convRefId;
  };

  const handleSendtomode = (mode) => {
    setSendtomode(mode);
    if (mode === "toworkspace") {
      setCurrentRecipient("");
      setSearchParams({ wschat: sendto });
    } else if (mode === "toproject") {
      setCurrentRecipient("");
      setSearchParams({ prchat: sendto });
    } else if (mode === "toperson") {
      let generatedConvRefId = generateConvRefId();
      setSearchParams({ dmchat: generatedConvRefId });
    }
  };

  const handleSendtoPerson = (member) => {
    setSendto(member?.uid);
    setButtonLook("personselected");
    setCurrentRecipient(member);
    // handleSendtomode("toperson"); //Might be problematic for the future functions, check it later...
    setContactsOpen(false);
    let convRefId =
      currentUser.uid < sendto
        ? currentUser.uid + sendto
        : sendto + currentUser.uid;
    setSearchParams({ dmchat: convRefId });
  };

  const handleChatHide = () => {
    SetChatIsOpen(!chatIsOpen);
  };

  const handleWorkspaceSelect = (space) => {
    setSelectedWorkspaceId(space.spaceId);
    if (projectOptions[0]?.fromSpaceId === space.spaceId) {
      setSendtomode("toworkspace");
      setSendto(space.spaceId);
      setShowGroupSelect(false);
      setSelectedGroupName(space.name);
      setSelectedGroupColor(space.color);
    } else {
      db.collection("space")
        .doc(space.spaceId)
        .collection("stations")
        .onSnapshot((querySnapshot) => {
          const array = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              name: doc.get("name"),
              emojicon: doc.get("emojicon"),
              fromSpaceId: doc.get("fromSpaceId"),
            };
          });
          setProjectOptions([...array]);
        });
    }
  };

  const handleProjectSelect = (project) => {
    setSendtomode("toproject");
    setButtonLook("nopersonselected");
    setSendto(project.id);
    setSendtoWsId(project.fromSpaceId);
    setShowGroupSelect(false);
    setSelectedGroupName(project.name);
    setSelectedGroupIcon(project.emojicon);
  };

  return (
    <motion.div
      className={!meetingFullScreen ? "allchat" : "allchatFS"}
      animate={{ marginRight: chatx }}
      initial={false}
      transition={{ ease: "easeOut" }}
      style={{
        zIndex: chatIsOpen ? "200" : "10",
        position: !chatIsOpen && "0px",
      }}
    >
      {!meetingFullScreen && (
        <motion.div onClick={handleChatHide}>
          <button className={chatIsOpen ? "chat__hideMoved" : "chat__hide"}>
            {chatIsOpen ? (
              <div className="chat__exit__btn">
                <HiXMark size="3em" />
              </div>
            ) : (
              <div className="chat__exit__btn-flipped">
                <HiOutlineChatBubbleLeftEllipsis size="1.8em" />
              </div>
            )}
          </button>
        </motion.div>
      )}
      {/* {chatIsOpen && ( */}
        <div className={!meetingFullScreen ? "chat" : "chatFS"} style={{display: chatIsOpen ? "flex" : "none" }}>
          <div className="meetingVideoChat-mockheader"> </div>
          <div
            className={
              !meetingFullScreen ? "meetingVideoChat" : "meetingVideoChatFS"
            }
            style={{height: onCall ? "100%" : 70}}
          >
            {sendto && sendtomode === "toperson" && dmchat && (
              <Meet
                meetingId={generateConvRefId()}
                setOnCall={setOnCall}
                currentrecipient={currentrecipient}
              />
            )}
            {sendto &&
              (sendtomode === "toworkspace" || sendtomode === "toproject") && (
                <GroupMeeting
                  roomId={sendto}
                  userId={currentUser?.uid}
                  userName={currentUser?.userName}
                  setOnCall={setOnCall}
                  selectedGroupName={selectedGroupName}
                  meetingFullScreen={meetingFullScreen}
                  setMeetingFullScreen={setMeetingFullScreen}
                />
              )}
          </div>
          <div
            className={
              meetingFullScreen
                ? "ch__breadcrumb-headerFS"
                : "ch__breadcrumb-header"
            }
          >
            {showInstantChat ? (
              <h5>{sendtomode.replace("to", "")} messages</h5>
            ) : (
              <h5></h5>
            )}
            <button onClick={() => setMeetingFullScreen(!meetingFullScreen)}>
              {meetingFullScreen ? <BsFullscreenExit size="1.5em" /> : <BsFullscreenExit size="1.5em" />}
            </button>
            <button onClick={() => setShowInstantChat(!showInstantChat)}>
              <IoMdChatboxes size="2.2em" />
            </button>
          </div>
          <div
            className={
              showInstantChat ? "ch__writeUpdate" : "ch__writeUpdateHidden"
            }
          >
            {sendtomode === "toworkspace" && (
              <div className="ch__writeUpdate-msgs">
                {msgs.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      <div
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={(e) => {
                            e.stopPropagation(); // prevent the parent's onClick from firing too
                            setReactionMessageRef(msg.id);
                          }}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </div>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeleteMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelectWsReaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            {sendtomode === "toproject" && (
              <div className="ch__writeUpdate-msgs">
                {projectMsgs?.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      <button
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={() => setReactionMessageRef(msg.id)}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </button>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeleteProjectMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelectPrjReaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            {sendtomode === "toperson" && (
              <div className="ch__writeUpdate-msgs">
                {personalMsgs?.map((msg) => {
                  return (
                    <div
                      key={msg.id}
                      className={
                        msg.from === currentUser?.uid
                          ? "ch__messageLine-Self"
                          : "ch__messageLine"
                      }
                    >
                      <div className="ch__messagewithReaction">
                        <Message key={msg.id} msg={msg} />
                        <button
                          className={
                            msg.from === currentUser?.uid
                              ? "ch__messageReaction-Self"
                              : "ch__messageReaction"
                          }
                        >
                          {msg.reaction}
                        </button>
                      </div>
                      <button
                        className="ch__EmojiPick"
                        onClick={() => {
                          setReactionPickerOpen(!reactionpickerOpen);
                        }}
                      >
                        <button
                          className="ch__EmojiPickIcon"
                          onClick={() => setReactionMessageRef(msg.id)}
                        >
                          <BsEmojiSmileUpsideDown />
                        </button>
                      </button>
                      <button
                        className="ch__MessageDelete"
                        onClick={() => handleDeletePersonalMessage(msg)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  );
                })}{" "}
                <div className="ch__ReactionPickerOn">
                  {reactionpickerOpen && (
                    <EmojiPicker
                      onEmojiSelect={onSelect1on1Reaction}
                      onClickOutside={() => setReactionPickerOpen(false)}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="ch__writeUpdate-send">
              <div className="ch__textbox">
                <div className="ch__sendtomode-tabs">
                  <div style={{ display: "flex", width: "100%" }}>
                    <button
                      className={
                        sendtomode === "toworkspace" ||
                        sendtomode === "toproject"
                          ? "ch__sendtomode-tab-active"
                          : "ch__sendtomode-tab"
                      }
                      onClick={() => setShowGroupSelect(!showGroupSelect)}
                    >
                      {sendtomode === "toworkspace" ? (
                        <div className="chat_grpoption-selected">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: selectedGroupColor,
                              borderRadius: "6px",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            {selectedGroupName?.charAt(0)}
                          </div>
                          {selectedGroupName}
                        </div>
                      ) : sendtomode === "toproject" ? (
                        <div className="chat_grpoption-selected">
                          {selectedGroupIcon} {"  "}
                          {selectedGroupName}
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            columnGap: "5px",
                          }}
                        >
                          <IoPeopleOutline size="1.4em" /> Group
                        </div>
                      )}
                    </button>
                    {showGroupSelect && (
                      <div className="chat_grpselect">
                        {spaceData?.map((space) => {
                          return (
                            <>
                              <button
                                key={space.spaceId}
                                value={space.spaceId}
                                selected={selectedWorkspaceId === space.spaceId}
                                className={
                                  sendto === space.spaceId
                                    ? "chat_grpoption-active"
                                    : "chat_grpoption"
                                }
                                style={{
                                  textDecoration: `underline 2px ${space.color}`,
                                }}
                                onClick={() => handleWorkspaceSelect(space)}
                              >
                                {space.name}
                              </button>
                              {projectOptions[0]?.fromSpaceId ===
                                space.spaceId && (
                                <motion.div
                                  initial={{
                                    y: -50,
                                    height: "0px",
                                    opacity: 0,
                                  }}
                                  animate={{
                                    y: 0,
                                    height: "auto",
                                    opacity: 1,
                                  }}
                                  transition={{
                                    type: "ease-in-out",
                                    duration: 0.4,
                                  }}
                                >
                                  {projectOptions.map((project) => (
                                    <button
                                      key={project.id}
                                      value={project.id}
                                      className={
                                        sendto === project.id
                                          ? "chat_grpsuboption-active"
                                          : "chat_grpsuboption"
                                      }
                                      onClick={() =>
                                        handleProjectSelect(project)
                                      }
                                    >
                                      {project.emojicon} {project.name}
                                    </button>
                                  ))}
                                </motion.div>
                              )}
                            </>
                          );
                        })}
                        <details className="chat_grpoption">
                          <summary>Shared With Me</summary>
                          {currentUser?.sharedWith?.map((project) => {
                            return (
                              <button
                                key={project.stationId}
                                className="chat_grpsuboption"
                              >
                                {project.stationId}
                                {/* <SharedStationItem project={project} />  */}
                              </button>
                            );
                          })}
                        </details>
                      </div>
                    )}
                  </div>

                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      className={
                        sendtomode === "toperson"
                          ? "ch__sendtomode-tab-active"
                          : "ch__sendtomode-tab"
                      }
                      onClick={() => {
                        handleSendtomode("toperson");
                        setContactsOpen(!contactsopen);
                      }}
                    >
                      {sendtomode === "toperson" ? (
                        <button
                          className="ch__personselected"
                          onClick={() => {
                            handleSendtomode("toperson");
                            setContactsOpen(!contactsopen);
                          }}
                        >
                          <Avatar
                            src={currentrecipient?.imageUrl}
                            onClick={() => setContactsOpen(!contactsopen)}
                          />
                          {currentrecipient?.userName}
                        </button>
                      ) : (
                        <button
                          className="ch__personNonselected"
                          onClick={() => {
                            handleSendtomode("toperson");
                            setContactsOpen(!contactsopen);
                          }}
                        >
                          {sendtomode === "toperson" ? (
                            <Avatar src={currentrecipient?.imageUrl} />
                          ) : (
                            <div style={{ marginLeft: 5 }}>
                              <BsPersonCheck size={18} />
                            </div>
                          )}
                          Person
                        </button>
                      )}
                    </div>
                    {sendtomode === "toperson" && (
                      <div>
                        {/* <p>Just to a Person</p> */}
                        {contactsopen && (
                          <div className="ch__selectperson">
                            {memberData
                              ?.filter(
                                (member) => member.uid !== currentUser.uid
                              )
                              .map((member) => {
                                return (
                                  <button
                                    key={member.uid}
                                    member={member}
                                    image={member.imageUrl}
                                    className="ch__selectperson_item"
                                    onClick={() => handleSendtoPerson(member)}
                                  >
                                    <Avatar src={member.imageUrl} />
                                    {member.userName}
                                  </button>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="ch__writeUpdate-message">
                  <form onSubmit={(e) => handleSubmit(e)} id="MessageForm">
                    <textarea
                      onChange={(e) => setChatMessage(e.target.value)}
                      ref={textareaRef}
                      placeholder="Write a message"
                      value={chatmessage}
                    />
                  </form>
                  <div className="ch__writeUpdate-buttongroup">
                    <div className="ch__writeUpdate-buttongroup-sub">
                      <div className="ch__EmojiIcon">
                        <button
                          className="ch__EmojiPick"
                          onClick={() => {
                            setEmojiOpen(!emojiOpen);
                          }}
                        >
                          <button>
                            <BsEmojiSmileUpsideDown size="1.2em" />
                          </button>
                        </button>
                        <div className="ch__PickerOn">
                          {emojiOpen &&
                            createPortal(
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: "95px",
                                  right: "35px",
                                  zIndex: "201",
                                }}
                              >
                                <EmojiPicker onEmojiSelect={onSelectEmoji} />
                              </div>,
                              document.body
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="ch__writeUpdate-btn">
                      <RetroButton type="submit" form="MessageForm">
                        <FaPaperPlane size="1.5em" />
                      </RetroButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </motion.div>
  );
}

export default Chat;
