import React from "react";

import { useSelector } from "react-redux";

import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";

import { BsLink45Deg } from "react-icons/bs";

import "./invite-to-premium.styles.scss";

const InvitetoPremium = ({
  setShowInvitation,
  featureName,
  featureDesc,
  featureImg,
  featureImgStyle,
  currentUser,
}) => {
  const userEntitlement = useSelector((state) => state.user.entitlement);
  return (
    <div className="invitetoPremium">
      <BoxLayer setLayer={setShowInvitation}>
        <div className="invPremium__inner">
          <div>
            {/* <h2>Invite</h2> */}
            <p>
              Dear <b>{currentUser.userName}</b>, <i>"{featureName}"</i> feature
              is not included in the <b>{userEntitlement}</b> plan that you are
              currently in.
            </p>
            <p>
              You can upgrade
              <a
                href="https://antlogy.com/#pricing"
                target="_blank"
              >
                {" "}
                here on the main page
                <BsLink45Deg size="1.4em" />
              </a>
              , {featureDesc} and gain access to
              <a
                href="https://www.antlogy.com/#collaboration"
                target="_blank"
              >
                {" "}
                many other empowering features.
              </a>
            </p>
            <p>
              We'd be happy to see you getting the most value 🙂
            </p>
          </div>
          <img
            src={featureImg}
            style={featureImgStyle ? featureImgStyle : null}
          />
        </div>
      </BoxLayer>
    </div>
  );
};

export default InvitetoPremium;
