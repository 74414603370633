import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation, Outlet } from "react-router-dom";
import { IoSyncCircleOutline, IoSyncCircleSharp } from "react-icons/io5";
import { LuMousePointer2 } from "react-icons/lu";
import { BsCursorText } from "react-icons/bs";

// FIREBASE UTILS
import { db, fieldValue } from "../../firebase/firebase.utils";

// EXCALIDRAW
import {
  Excalidraw,
  MainMenu,
  Sidebar,
  Footer,
  restoreElements,
} from "@excalidraw/excalidraw";

// OTHER COMPONENTS
import InvitetoPremium from "../../components/marketing/invite-to-premium.component";
import TaskAddButton from "../../components/task-add-btn/task-add-btn.component";

import "./concept.styles.scss";

const WHITEBOARD_TYPE = "whiteboard";
const CURSOR_THROTTLE_MS = 200;

/* -------------------------------------------------------------------------
   1. Build Library Items
------------------------------------------------------------------------- */
function getTaskLibraryItems(stationTasks = []) {
  return stationTasks.map((task, index) => {
    const uniqueSeed = Date.now() + index;
    return {
      id: `task-item-${task.id || index}-${uniqueSeed}`,
      status: "unpublished",
      elements: [
        {
          id: `container-${task.id}`,
          type: "rectangle",
          version: 1,
          versionNonce: 1,
          isDeleted: false,
          width: task.title.length * 10 + 20,
          height: 50,
          x: 0,
          y: 0,
          strokeColor: "#000000",
          backgroundColor: "#FFF8DC",
          fillStyle: "solid",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          seed: uniqueSeed,
          groupIds: [],
          strokeSharpness: "sharp",
          boundElements: [        {
            type: "text",
            id: `title-${task.id}`
          }],
          updated: Date.now(),
          angle: 0,
        },
        {
          id: `title-${task.id}`,
          type: "text",
          version: 1,
          versionNonce: 1,
          isDeleted: false,
          x: 10,
          y: 10,
          width: task.title.length * 10,
          height: 20,
          angle: 0,
          strokeColor: "#000000",
          backgroundColor: "transparent",
          fillStyle: "solid",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 0,
          opacity: 100,
          seed: uniqueSeed + 1,
          groupIds: [],
          strokeSharpness: "sharp",
          boundElements: [],
          updated: Date.now(),
          text: task.title || "Task",
          fontSize: 16,
          fontFamily: 1,
          textAlign: "center",
          verticalAlign: "middle",
          baseline: 16,
          containerId: `container-${task.id}`,
        },
      ],
    };
  });
}

/* -------------------------------------------------------------------------
   2. User Color Helper
------------------------------------------------------------------------- */
const COLORS = [
  "#70b1ec",
  "#f78da7",
  "#a3e635",
  "#facc15",
  "#f97316",
  "#7dd3fc",
  "#f87171",
];
function getUserColor(userId) {
  const hash = userId.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return COLORS[hash % COLORS.length];
}

/* -------------------------------------------------------------------------
   3. CursorOverlay Component
------------------------------------------------------------------------- */
function CursorOverlay({ absX, absY, userName, color, isTyping }) {
  return (
    <div
      style={{
        position: "fixed",
        left: absX,
        top: absY,
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        gap: 6,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <LuMousePointer2 size={21} color={color} />
        {isTyping && (
          <BsCursorText
            size={24}
            color={color}
            style={{
              animation: "blinker 1s linear infinite",
              marginTop: 5,
              textShadow: "0 0 6px rgba(0,0,0,0.8)",
            }}
          />
        )}
      </div>
      <div
        style={{
          background: color,
          color: "#fff",
          border: `1px solid ${color}`,
          borderRadius: 4,
          padding: "0px 5px",
          transform: "translateX(-10px) translateY(15px)",
          fontSize: "0.75rem",
          fontWeight: "600",
          pointerEvents: "none",
          whiteSpace: "nowrap",
        }}
      >
        {userName}
      </div>
    </div>
  );
}

/* -------------------------------------------------------------------------
   4. Action Notifications: Hook & Logger
------------------------------------------------------------------------- */
function useActionNotifications(whiteboardDocId, currentSpaceId, currentStationId, currentUser) {
  const [actionNotification, setActionNotification] = useState(null);
  useEffect(() => {
    if (!whiteboardDocId) return;
    const actionsRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .doc(whiteboardDocId)
      .collection("actions");
    const unsub = actionsRef
      .orderBy("timestamp", "desc")
      .limit(1)
      .onSnapshot((snap) => {
        snap.forEach((doc) => {
          const data = doc.data();
          setActionNotification(`${data.userName} ${data.type}`);
          setTimeout(() => setActionNotification(null), 3000);
        });
      });
    return () => unsub();
  }, [whiteboardDocId, currentSpaceId, currentStationId, currentUser]);
  return actionNotification;
}
const lastActionTimeRef = { current: 0 };
function logAction(whiteboardDocId, currentSpaceId, currentStationId, currentUser, type) {
  const now = Date.now();
  if (now - lastActionTimeRef.current < 2000) return;
  lastActionTimeRef.current = now;
  const actionsRef = db
    .collection("space")
    .doc(currentSpaceId)
    .collection("stations")
    .doc(currentStationId)
    .collection("concepts")
    .doc(whiteboardDocId)
    .collection("actions");
  actionsRef.add({
    type,
    userName: currentUser?.userName || "User",
    timestamp: fieldValue.serverTimestamp(),
  });
}

/* -------------------------------------------------------------------------
   5. ConceptView Component
------------------------------------------------------------------------- */
const ConceptView = ({ stationTasks = [] }) => {
  const excalidrawApiRef = useRef(null);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [collaborators, setCollaborators] = useState({});
  const saveTimer = useRef(null);
  const localChangeRef = useRef(false);
  const lastCursorUpdateRef = useRef(0);
  const isPanningRef = useRef(false);
  const isZoomingRef = useRef(false);
  const zoomTimeoutRef = useRef(null);
  const lastSyncPayloadRef = useRef(null);

  const containerRef = useRef(null);
  const [containerRect, setContainerRect] = useState(null);

  // Redux / Router.
  const theme = useSelector((state) => state.theme.theme);
  const currentUser = useSelector((state) => state.user.currentUser);
  const sidebarOpen = currentUser?.open;
  const location = useLocation();
  const currentSpaceId = location.pathname.split("/")[2];
  const currentStationId = location.pathname.split("/")[4];

  const [whiteboardDocId, setWhiteboardDocId] = useState(null);

  // Modes.
  const [liveMode, setLiveMode] = useState(false);
  const [zenMode, setZenMode] = useState(false);
  const [excaliTheme, setExcaliTheme] = useState("light");
  const [docked, setDocked] = useState(false);

  // Action Notification.
  const actionNotification = useActionNotifications(whiteboardDocId, currentSpaceId, currentStationId, currentUser);

  // Switch theme.
  useEffect(() => {
    setExcaliTheme(theme === "dark-theme" ? "dark" : "light");
  }, [theme]);

  // MODE SELECTION.
  useEffect(() => {
    const stationRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId);
    const unsub = stationRef.onSnapshot((doc) => {
      if (!doc.exists) return;
      const data = doc.data() || {};
      if (typeof data.liveMode === "boolean") {
        setLiveMode(data.liveMode);
        setZenMode(false);
      }
    });
    return () => unsub();
  }, [currentSpaceId, currentStationId]);

  useEffect(() => {
    const conceptsRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts");
    const findOrCreateWhiteboard = async () => {
      try {
        const snap = await conceptsRef.where("type", "==", WHITEBOARD_TYPE).limit(1).get();
        if (!snap.empty) {
          setWhiteboardDocId(snap.docs[0].id);
        } else {
          const newDocRef = await conceptsRef.add({
            title: "Whiteboard",
            type: WHITEBOARD_TYPE,
            elements: JSON.stringify([]),
            createdAt: fieldValue.serverTimestamp(),
            lastUpdatedBy: null,
          });
          setWhiteboardDocId(newDocRef.id);
        }
      } catch (err) {
        console.error("Error creating doc:", err);
      }
    };
    findOrCreateWhiteboard();
  }, [currentSpaceId, currentStationId]);

  // SCENE SYNC.
  useEffect(() => {
    if (!whiteboardDocId || !excalidrawAPI) return;
    const docRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .doc(whiteboardDocId);
    const unsub = docRef.onSnapshot((snap) => {
      if (!snap.exists) return;
      const data = snap.data() || {};
      if (
        data.lastUpdatedBy !== currentUser?.uid ||
        (excalidrawAPI.getSceneElements().length === 0 && data.elements)
      ) {
        const localAppState = excalidrawAPI.getAppState();
        if (localAppState.editingElement && localAppState.editingElement.type === "text") return;
        if (isPanningRef.current || isZoomingRef.current) return;
        let parsed = [];
        try {
          if (data.elements) parsed = restoreElements(JSON.parse(data.elements));
        } catch (err) {
          console.error("Failed to parse shapes:", err);
        }
        if (typeof excalidrawAPI.setAppState === "function" && data.appState) {
          excalidrawAPI.setAppState(data.appState);
        }
        excalidrawAPI.updateScene({
          elements: parsed,
          appState: data.appState || {},
        });
      }
    });
    return () => unsub();
  }, [whiteboardDocId, excalidrawAPI, currentSpaceId, currentStationId, currentUser?.uid]);

  // Scroll to content on Live Mode initiation.
  useEffect(() => {
    if (liveMode && excalidrawAPI && typeof excalidrawAPI.scrollToContent === "function") {
      excalidrawAPI.scrollToContent();
    }
  }, [liveMode, excalidrawAPI]);

  // Toggle Live Mode.
  const handleSwitchtoLiveMode = () => {
    const stationRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId);
    stationRef.update({ liveMode: !liveMode });
  };

  // Enhanced onChangeLive: Sync elements plus view state (active tool, selection, etc.).
  const onChangeLiveEnhanced = useCallback(
    (elements) => {
      if (!liveMode || !whiteboardDocId) return;
      const newElementsJson = JSON.stringify(elements);
      const appState = excalidrawAPI.getAppState();
      const syncAppState = {
        zoom: appState.zoom,
        scrollX: appState.scrollX,
        scrollY: appState.scrollY,
        activeTool: appState.activeTool,
        selectedElementIds: appState.selectedElementIds,
        gridSize: appState.gridSize,
      };
      const newSyncPayload = JSON.stringify({ newElementsJson, syncAppState });
      if (lastSyncPayloadRef.current === newSyncPayload) return;
      lastSyncPayloadRef.current = newSyncPayload;
      localChangeRef.current = true;
      if (saveTimer.current) clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(() => {
        const docRef = db
          .collection("space")
          .doc(currentSpaceId)
          .collection("stations")
          .doc(currentStationId)
          .collection("concepts")
          .doc(whiteboardDocId);
        docRef
          .update({
            elements: newElementsJson,
            appState: syncAppState,
            lastUpdatedBy: currentUser?.uid || null,
          })
          .then(() => {
            localChangeRef.current = false;
            logAction(whiteboardDocId, currentSpaceId, currentStationId, currentUser, "updated the board");
          })
          .catch(() => {
            localChangeRef.current = false;
          });
      }, 400);
    },
    [liveMode, whiteboardDocId, currentSpaceId, currentStationId, currentUser, excalidrawAPI]
  );

  const onChangeDefault = useCallback(() => {}, []);

  // Manual Save.
  const onSaveWhiteBoard = () => {
    if (!excalidrawAPI || !whiteboardDocId) return;
    const docRef = db
      .collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .doc(whiteboardDocId);
    const elements = excalidrawAPI.getSceneElements();
    docRef.update({
      elements: JSON.stringify(elements),
      lastUpdatedBy: currentUser?.uid || null,
    });
  };

  // Container Measurement.
  useEffect(() => {
    if (!containerRef.current) return;
    const measure = () => {
      const rect = containerRef.current.getBoundingClientRect();
      if (rect) setContainerRect(rect);
    };
    measure();
    window.addEventListener("resize", measure);
    return () => window.removeEventListener("resize", measure);
  }, []);

  // Detect panning.
  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;
    const handlePointerDown = () => { isPanningRef.current = true; };
    const handlePointerUp = () => { isPanningRef.current = false; };
    elem.addEventListener("pointerdown", handlePointerDown);
    elem.addEventListener("pointerup", handlePointerUp);
    return () => {
      elem.removeEventListener("pointerdown", handlePointerDown);
      elem.removeEventListener("pointerup", handlePointerUp);
    };
  }, []);

  // Detect zooming.
  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;
    const handleWheel = () => {
      isZoomingRef.current = true;
      clearTimeout(zoomTimeoutRef.current);
      zoomTimeoutRef.current = setTimeout(() => { isZoomingRef.current = false; }, 300);
    };
    elem.addEventListener("wheel", handleWheel);
    return () => {
      elem.removeEventListener("wheel", handleWheel);
      clearTimeout(zoomTimeoutRef.current);
    };
  }, []);

  // Pan & Zoom Sync: Poll for changes in pan/zoom and update Firestore.
  const lastSyncedAppStateRef = useRef({});
  useEffect(() => {
    if (!liveMode || !excalidrawAPI || !whiteboardDocId) return;
    const intervalId = setInterval(() => {
      if (isPanningRef.current || isZoomingRef.current) return;
      const appState = excalidrawAPI.getAppState();
      const { zoom, scrollX, scrollY, gridSize } = appState;
      if (
        lastSyncedAppStateRef.current.zoom !== zoom ||
        lastSyncedAppStateRef.current.scrollX !== scrollX ||
        lastSyncedAppStateRef.current.scrollY !== scrollY ||
        lastSyncedAppStateRef.current.gridSize !== gridSize
      ) {
        lastSyncedAppStateRef.current = { zoom, scrollX, scrollY, gridSize };
        const docRef = db.collection("space")
          .doc(currentSpaceId)
          .collection("stations")
          .doc(currentStationId)
          .collection("concepts")
          .doc(whiteboardDocId);
        docRef.update({
          appState: { zoom, scrollX, scrollY, gridSize },
          lastUpdatedBy: currentUser?.uid || null,
        });
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [liveMode, excalidrawAPI, whiteboardDocId, currentSpaceId, currentStationId, currentUser]);

  // Cursor Sync: Subscribe to Firestore cursors.
  useEffect(() => {
    if (!liveMode || !whiteboardDocId) {
      setCollaborators({});
      return;
    }
    const cursorsRef = db.collection("space")
      .doc(currentSpaceId)
      .collection("stations")
      .doc(currentStationId)
      .collection("concepts")
      .doc(whiteboardDocId)
      .collection("cursors");
    const unsub = cursorsRef.onSnapshot((snap) => {
      const updated = {};
      snap.forEach((doc) => {
        updated[doc.id] = doc.data();
      });
      setCollaborators(updated);
    });
    return () => unsub();
  }, [liveMode, whiteboardDocId, currentSpaceId, currentStationId]);

  // Cursor Update: On pointer move, update cursor document.
  const handlePointerMove = useCallback(
    (e) => {
      if (!liveMode || !whiteboardDocId || !containerRect) return;
      const now = Date.now();
      if (now - lastCursorUpdateRef.current < CURSOR_THROTTLE_MS) return;
      lastCursorUpdateRef.current = now;
      const rect = containerRef.current.getBoundingClientRect();
      const clamp = (val) => Math.max(0, Math.min(1, val));
      const rx = clamp((e.clientX - rect.left) / rect.width);
      const ry = clamp((e.clientY - rect.top) / rect.height);
      const cursorsRef = db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .doc(whiteboardDocId)
        .collection("cursors");
      const appState = excalidrawAPI.getAppState();
      const isTyping = !!(appState.editingElement && appState.editingElement.type === "text");
      cursorsRef.doc(currentUser.uid).set({
        userId: currentUser.uid,
        userName: currentUser?.userName || "User",
        rx,
        ry,
        isTyping,
        color: getUserColor(currentUser.uid),
        updatedAt: fieldValue.serverTimestamp(),
      });
    },
    [liveMode, whiteboardDocId, containerRect, currentUser, currentSpaceId, currentStationId, excalidrawAPI]
  );

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;
    elem.addEventListener("pointermove", handlePointerMove);
    return () => {
      if (elem) elem.removeEventListener("pointermove", handlePointerMove);
    };
  }, [handlePointerMove]);

  // Text Editing Sync: When editing ends, force immediate update.
  useEffect(() => {
    if (!liveMode || !excalidrawAPI || !whiteboardDocId) return;
    const intervalId = setInterval(() => {
      const appState = excalidrawAPI.getAppState();
      if (prevEditingRef2.current && !appState.editingElement) {
        const elements = excalidrawAPI.getSceneElements();
        const docRef = db.collection("space")
          .doc(currentSpaceId)
          .collection("stations")
          .doc(currentStationId)
          .collection("concepts")
          .doc(whiteboardDocId);
        docRef.update({
          elements: JSON.stringify(elements),
          lastUpdatedBy: currentUser?.uid || null,
        });
      }
      prevEditingRef2.current = appState.editingElement;
    }, 200);
    return () => clearInterval(intervalId);
  }, [liveMode, excalidrawAPI, whiteboardDocId, currentSpaceId, currentStationId, currentUser]);

  // Library Items: Update library from tasks.
  useEffect(() => {
    if (!excalidrawAPI) return;
    if (stationTasks.length > 0) {
      const items = getTaskLibraryItems(stationTasks);
      excalidrawAPI.updateLibrary({ libraryItems: items });
      excalidrawAPI.updateScene({
        appState: { isLibraryOpen: true },
      });
    }
  }, [excalidrawAPI, stationTasks]);

  // --- Cursor Update: On pointer move, update cursor document.
  const handlePointerMove2 = useCallback(
    (e) => {
      if (!liveMode || !whiteboardDocId || !containerRect) return;
      const now = Date.now();
      if (now - lastCursorUpdateRef.current < CURSOR_THROTTLE_MS) return;
      lastCursorUpdateRef.current = now;
      const rect = containerRef.current.getBoundingClientRect();
      const clamp = (val) => Math.max(0, Math.min(1, val));
      const rx = clamp((e.clientX - rect.left) / rect.width);
      const ry = clamp((e.clientY - rect.top) / rect.height);
      const cursorsRef = db.collection("space")
        .doc(currentSpaceId)
        .collection("stations")
        .doc(currentStationId)
        .collection("concepts")
        .doc(whiteboardDocId)
        .collection("cursors");
      const appState = excalidrawAPI.getAppState();
      const isTyping = !!(appState.editingElement && appState.editingElement.type === "text");
      cursorsRef.doc(currentUser.uid).set({
        userId: currentUser.uid,
        userName: currentUser?.userName || "User",
        rx,
        ry,
        isTyping,
        color: getUserColor(currentUser.uid),
        updatedAt: fieldValue.serverTimestamp(),
      });
    },
    [liveMode, whiteboardDocId, containerRect, currentUser, currentSpaceId, currentStationId, excalidrawAPI]
  );

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;
    elem.addEventListener("pointermove", handlePointerMove2);
    return () => {
      if (elem) elem.removeEventListener("pointermove", handlePointerMove2);
    };
  }, [handlePointerMove2]);

  // --- Text Editing Sync: When editing ends, force an immediate update.
  const prevEditingRef2 = useRef(null);
  useEffect(() => {
    if (!liveMode || !excalidrawAPI || !whiteboardDocId) return;
    const intervalId = setInterval(() => {
      const appState = excalidrawAPI.getAppState();
      if (prevEditingRef2.current && !appState.editingElement) {
        const elements = excalidrawAPI.getSceneElements();
        const docRef = db.collection("space")
          .doc(currentSpaceId)
          .collection("stations")
          .doc(currentStationId)
          .collection("concepts")
          .doc(whiteboardDocId);
        docRef.update({
          elements: JSON.stringify(elements),
          lastUpdatedBy: currentUser?.uid || null,
        });
      }
      prevEditingRef2.current = appState.editingElement;
    }, 200);
    return () => clearInterval(intervalId);
  }, [liveMode, excalidrawAPI, whiteboardDocId, currentSpaceId, currentStationId, currentUser]);

  // --- Library Items: Update library from tasks.
  useEffect(() => {
    if (!excalidrawAPI) return;
    if (stationTasks.length > 0) {
      const items = getTaskLibraryItems(stationTasks);
      excalidrawAPI.updateLibrary({ libraryItems: items });
      excalidrawAPI.updateScene({
        appState: { isLibraryOpen: true },
      });
    }
  }, [excalidrawAPI, stationTasks]);

  // --- Render Collaborator Cursors.
  const renderCollaborators = () => {
    if (!containerRect) return null;
    return Object.entries(collaborators).map(([uid, data]) => {
      if (uid === currentUser?.uid) return null;
      const rect = containerRect;
      const mouseAbsX = rect.left + (data.rx || 0) * rect.width;
      const mouseAbsY = rect.top + (data.ry || 0) * rect.height;
      const color = data.color || getUserColor(uid);
      return (
        <CursorOverlay
          key={uid}
          absX={mouseAbsX}
          absY={mouseAbsY}
          userName={data.userName || "User"}
          color={color}
          isTyping={data.isTyping}
        />
      );
    });
  };

  return (
    <div className="conceptView" style={{paddingLeft: sidebarOpen ? 0 : 15}}>
      <div className="conceptContainer">
        <div className="concept_container" ref={containerRef}>
          <Excalidraw
            ref={excalidrawApiRef}
            excalidrawAPI={(api) => setExcalidrawAPI(api)}
            theme={excaliTheme}
            isCollaborating={false}
            onChange={liveMode ? onChangeLiveEnhanced : onChangeDefault}
            name="Custom Whiteboard"
            zenModeEnabled={zenMode}
            initialData={{
              libraryItems: [],
              appState: { isLibraryOpen: false },
            }}
            renderTopRightUI={() => (
              <button
                style={{
                  background: "white",
                  border: "1px solid #cccccc",
                  borderRadius: "0.375rem",
                  color: "#3d3d3d",
                  padding: "4px 10px",
                  fontWeight: "bold",
                }}
                onClick={onSaveWhiteBoard}
              >
                Save
              </button>
            )}
            UIOptions={{
              dockedSidebarBreakpoint: 0,
            }}
            validateEmbeddable={() => true}
          >
            <MainMenu>
              <MainMenu.DefaultItems.LoadScene />
              <MainMenu.DefaultItems.Export />
              <MainMenu.DefaultItems.SaveAsImage />
              <MainMenu.DefaultItems.ClearCanvas />
              <MainMenu.Separator />
              <MainMenu.DefaultItems.ChangeCanvasBackground />
            </MainMenu>
            <Sidebar name="custom" docked={docked} onDock={setDocked}>
              <Sidebar.Header />
              <Sidebar.Tabs style={{ padding: "0.5rem" }}>
                <Sidebar.Tab tab="one">Tab One!</Sidebar.Tab>
                <Sidebar.Tab tab="two">Tab Two!</Sidebar.Tab>
                <Sidebar.TabTriggers>
                  <Sidebar.TabTrigger tab="one">One</Sidebar.TabTrigger>
                  <Sidebar.TabTrigger tab="two">Two</Sidebar.TabTrigger>
                </Sidebar.TabTriggers>
              </Sidebar.Tabs>
            </Sidebar>
            <Footer>
              {/* <Sidebar.Trigger
                name="custom"
                tab="one"
                style={{
                  marginLeft: "0.5rem",
                  background: "#70b1ec",
                  color: "white",
                }}
              >
                Sidebar
              </Sidebar.Trigger> */}
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "5px",
                }}
              >
                <button
                  className={liveMode ? "concept_tab-header-active" : "concept_tab-header-nonactive"}
                  onClick={handleSwitchtoLiveMode}
                >
                  {liveMode ? (
                    <IoSyncCircleSharp color="#1a82c4" />
                  ) : (
                    <IoSyncCircleOutline />
                  )}
                  {"  "}
                  Live Mode
                </button>
              </div>
            </Footer>
          </Excalidraw>
        </div>
      </div>

      {renderCollaborators()}

      {actionNotification && (
        <div
          style={{
            position: "fixed",
            top: 10,
            left: "50%",
            transform: "translateX(-50%)",
            background: "rgba(0,0,0,0.7)",
            color: "#fff",
            padding: "6px 12px",
            borderRadius: "4px",
            zIndex: 10000,
          }}
        >
          {actionNotification}
        </div>
      )}

      <Outlet />
      <TaskAddButton />
    </div>
  );
};

export default ConceptView;
