// videoCallSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  participants: [],
};

const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    addParticipant: (state, action) => {
      state.participants.push(action.payload);
    },
    removeParticipant: (state, action) => {
      state.participants = state.participants.filter(
        (p) => p.userId !== action.payload
      );
    },
    updateParticipant: (state, action) => {
      const participant = state.participants.find(
        (p) => p.userId === action.payload.userId
      );
      if (participant) {
        Object.assign(participant, action.payload);
      }
    },
    resetParticipants: (state) => {
      state.participants = [];
    },
  },
});

export const {
  addParticipant,
  removeParticipant,
  updateParticipant,
  resetParticipants,
} = videoCallSlice.actions;

export default videoCallSlice.reducer;
