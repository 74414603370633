import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import "./anteditor.scss";
import "../styles.css";

import { createPortal } from "react-dom";
import { createNewInboxTask } from "../../../firebase/firebase.utils";
import AntListens from "./plugins/antListens";
import ColorPalette from "../../colors/color-palette";
import isValidHttpUrl from "./plugins/autoLinkPlugin";

import {
  BsQuote,
  BsFileBreak,
  BsTable,
  BsCardImage,
  BsPlayBtn,
  BsEmojiSmile,
  BsPaintBucket,
} from "react-icons/bs";
import { AiOutlineHighlight } from "react-icons/ai";
import {
  TbBold,
  TbItalic,
  TbUnderline,
  TbStrikethrough,
  TbAlignLeft,
  TbAlignRight,
  TbAlignCenter,
  TbAlignJustified,
  TbIndentIncrease,
  TbIndentDecrease,
  TbList,
  TbListNumbers,
  TbListCheck,
  TbSquareToggleHorizontal,
  TbChevronDown,
  TbPlus,
} from "react-icons/tb";
import { TfiLink, TfiLayoutMediaRightAlt, TfiParagraph } from "react-icons/tfi";
import {
  HiOutlineArrowUturnLeft,
  HiOutlineArrowUturnRight,
  HiOutlineCodeBracket,
  HiPaintBrush,
} from "react-icons/hi2";
import EmojiPicker from "../../emoji/emojiPicker";

export default function AntEditor({
  mainToolbarOn,
  floatingToolbarOn,
  placeholder,
  initialValue,
  setDocContent,
  setDocImgFile,
  setDocImgFileAltText,
  docImgFileAltText,
  handleUploadDocImage,
}) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [htmll, setHTMLL] = useState("");
  const [showBlockOptions, setShowBlockOptions] = useState(false);
  const [showAlignOptions, setShowAlignOptions] = useState(false);
  const [showInsertOptions, setShowInsertOptions] = useState(false);
  const [showTextColors, setShowTextColors] = useState(false);
  const [showHighlightColors, setShowHighlightColors] = useState(false);
  const [showFloatingToolbar, setShowFloatingToolbar] = useState(false);
  const [emisOpen, setEmIsOpen] = useState(false);
  const [spokenText, setSpokenText] = useState("");
  const [showImageOptions, setShowImageOptions] = useState(false);
  const [showFileImgModal, setShowFileImgModal] = useState(false);
  const [showLinkImgModal, setShowLinkImgModal] = useState(false);
  const [showLinkVidModal, setShowLinkVidModal] = useState(false);
  const [vidFromWebLink, setVidFromWebLink] = useState(null);
  const [imgFromWebLink, setImgFromWebLink] = useState(null);
  // New modal states for table and embed insertion.
  const [showTableModal, setShowTableModal] = useState(false);
  const [tableRows, setTableRows] = useState("");
  const [tableCols, setTableCols] = useState("");
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [embedCode, setEmbedCode] = useState("");
  // New modal state for link insertion.
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkCaption, setLinkCaption] = useState("");

  const editorContainerRef = useRef(null);
  const insertDropdownButtonRef = useRef(null);
  const alignDropdownButtonRef = useRef(null);
  const selectionLocation = useRef(null);
  const targetNodeRef = useRef(null);
  const savedSelectionRef = useRef(null);

  // Dropdown container refs.
  const blockDropdownRef = useRef(null);
  const alignDropdownRef = useRef(null);
  const insertDropdownRef = useRef(null);

  // Update sanitizer to allow div and button.
  function utilizeSanitize(htmlString) {
    return sanitizeHtml(htmlString, {
      allowedTags: [
        "p",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "blockquote",
        "pre",
        "ul",
        "ol",
        "li",
        "a",
        "b",
        "i",
        "u",
        "s",
        "span",
        "img",
        "iframe",
        "input",
        "details",
        "summary",
        "table",
        "tr",
        "td",
        "div",
        "button",
      ],
      allowedAttributes: {
        a: ["href", "title", "target", "rel"],
        img: ["src", "alt"],
        iframe: ["src", "width", "height", "frameborder", "allowfullscreen"],
        input: ["type", "checked"],
        details: ["open"],
        table: ["border", "width", "style"],
        "*": ["style", "class"],
      },
      allowedSchemes: ["http", "https", "mailto"],
      // allowedIframeHostnames: ["www.youtube.com","player.vimeo.com"]
    });
  }

  // Global click handler for dropdown closure.
  useEffect(() => {
    const handleGlobalClick = (event) => {
      const blockButton = document.getElementById("blocktypeDropdown");
      if (
        showBlockOptions &&
        blockDropdownRef.current &&
        blockButton &&
        !blockDropdownRef.current.contains(event.target) &&
        !blockButton.contains(event.target)
      ) {
        setShowBlockOptions(false);
      }
      if (
        showAlignOptions &&
        alignDropdownRef.current &&
        alignDropdownButtonRef.current &&
        !alignDropdownRef.current.contains(event.target) &&
        !alignDropdownButtonRef.current.contains(event.target)
      ) {
        setShowAlignOptions(false);
      }
      if (
        showInsertOptions &&
        insertDropdownRef.current &&
        insertDropdownButtonRef.current &&
        !insertDropdownRef.current.contains(event.target) &&
        !insertDropdownButtonRef.current.contains(event.target)
      ) {
        setShowInsertOptions(false);
      }
    };
    document.addEventListener("mousedown", handleGlobalClick);
    return () => document.removeEventListener("mousedown", handleGlobalClick);
  }, [showBlockOptions, showAlignOptions, showInsertOptions]);

  useEffect(() => {
    const targetNode = targetNodeRef.current;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => console.log(mutation.target.innerHTML));
    });
    observer.observe(targetNode, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const editorElem = document.getElementById("editorr");
    document.execCommand("formatBlock", false, "p");
    const listener = () => {
      // Update each checkbox's HTML attribute to match its current state.
      editorElem
        .querySelectorAll("input[type='checkbox']")
        .forEach((checkbox) => {
          if (checkbox.checked) {
            checkbox.setAttribute("checked", "checked");
          } else {
            checkbox.removeAttribute("checked");
          }
        });
      const cleanContent = utilizeSanitize(editorElem.innerHTML);
      setHTMLL(cleanContent);
      setDocContent(cleanContent);
    };

    editorElem.addEventListener("input", listener);
    editorElem.addEventListener("click", listener);
    editorElem.addEventListener("mouseup", listener);
    return () => {
      editorElem.removeEventListener("input", listener);
      editorElem.removeEventListener("click", listener);
      editorElem.removeEventListener("mouseup", listener);
    };
  }, []);

  useEffect(() => {
    const editorElem = document.getElementById("editorr");
    editorElem.innerHTML = utilizeSanitize(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const editorElem = document.getElementById("editorr");
    const handleFocus = () => {
      if (
        editorElem.innerHTML.trim() === "" ||
        editorElem.innerHTML === "<br>"
      ) {
        editorElem.innerHTML = "<p><br></p>";
        const newRange = document.createRange();
        newRange.setStart(editorElem.firstChild, 0);
        newRange.collapse(true);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(newRange);
      }
    };
    editorElem.addEventListener("focus", handleFocus);
    return () => editorElem.removeEventListener("focus", handleFocus);
  }, []);

  useEffect(() => {
    const editorElem = document.getElementById("editorr");
    editorElem.addEventListener("paste", (event) => {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      const selection = window.getSelection();
      if (isValidHttpUrl(paste)) {
        const a = document.createElement("a");
        a.href = paste;
        a.title = paste;
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.appendChild(document.createTextNode(` ${paste} `));
        selection.getRangeAt(0).insertNode(a);
        selection.collapseToEnd();
      } else {
        if (!selection.rangeCount) return;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
      }
    });
  }, []);

  function createImageFromURL({ src, altText }) {
    const editorelement = document.getElementById("editorr");
    const img = document.createElement("img");
    img.src = src;
    img.alt = altText;
    editorelement.append(img);
    setShowLinkImgModal(false);
  }

  const onImageConfirmClick = async () => {
    const url = await handleUploadDocImage();
    createImageFromURL({ src: url, altText: docImgFileAltText });
    setShowFileImgModal(false);
  };

  function createCollapsible() {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const header = range.startContainer.closest("h1, h2, h3, h4, h5, h6");
    if (header) {
      range.setStartAfter(header);
      range.collapse(true);
    }
    const details = document.createElement("details");
    details.setAttribute("open", true);
    const summary = document.createElement("summary");
    summary.textContent = "Summary part";
    details.appendChild(summary);
    details.append("Details part");
    range.insertNode(details);
    range.setStartAfter(details);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  function toggleBold() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const bold = document.createElement("b");
    const isBold =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "b";
    if (isBold) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.replaceWith(text);
      range.insertNode(text);
    } else {
      bold.appendChild(range.extractContents());
      range.insertNode(bold);
    }
  }

  function toggleItalic() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const italic = document.createElement("i");
    const isItalic =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "i";
    if (isItalic) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.replaceWith(text);
      range.insertNode(text);
    } else {
      italic.appendChild(range.extractContents());
      range.insertNode(italic);
    }
  }

  function toggleUnderline() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const underline = document.createElement("u");
    const isUnderline =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "u";
    if (isUnderline) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.replaceWith(text);
      range.insertNode(text);
    } else {
      underline.appendChild(range.extractContents());
      range.insertNode(underline);
    }
  }

  function toggleStrike() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const strike = document.createElement("s");
    const isStrike =
      range.commonAncestorContainer.parentNode.tagName.toLowerCase() === "s";
    if (isStrike) {
      const text = range.extractContents();
      range.commonAncestorContainer.parentNode.replaceWith(text);
      range.insertNode(text);
    } else {
      strike.appendChild(range.extractContents());
      range.insertNode(strike);
    }
  }

  function handleUndo() {
    document.execCommand("undo", false, null);
  }

  function handleRedo() {
    document.execCommand("redo", false, null);
  }

  function handleAlignCommands(alignValue) {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    range.commonAncestorContainer.parentElement.style.textAlign = alignValue;
  }

  function handleChangeType(type) {
    document.execCommand("formatBlock", false, type);
  }

  function handleIndentation(type) {
    document.execCommand(type, false, null);
  }

  function handleInsertList(type) {
    document.execCommand(type, false, null);
  }

  const handleInsertChecklist = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);

    // Create the checkbox
    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.checked = false;
    checkbox.setAttribute("contenteditable", "false");

    // Create the list container (<ol>) with class "x-todo"
    const listContainer = document.createElement("ol");
    listContainer.className = "x-todo";

    // Create the checklist item as an <li>
    const listItem = document.createElement("li");
    listItem.className = "checklist-item";

    if (!selection.isCollapsed) {
      const selectedContent = range.extractContents();
      const textSpan = document.createElement("span");
      textSpan.appendChild(selectedContent);
      listItem.appendChild(checkbox);
      listItem.appendChild(textSpan);
    } else {
      listItem.appendChild(checkbox);
      listItem.appendChild(document.createTextNode(" "));
    }
    listContainer.appendChild(listItem);

    range.deleteContents();
    range.insertNode(listContainer);

    const newRange = document.createRange();
    newRange.setStartAfter(listContainer);
    newRange.collapse(true);
    selection.removeAllRanges();
    selection.addRange(newRange);

    checkbox.addEventListener("change", handleCheckboxChange);
  };

  const handleCheckboxChange = () => {
    const editorElem = targetNodeRef.current;
    const cleanContent = utilizeSanitize(editorElem.innerHTML);
    setHTMLL(cleanContent);
    setDocContent(cleanContent);
  };

  // --- Link Handling ---
  function handleLinkButtonClick(e) {
    e.stopPropagation();
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      savedSelectionRef.current = sel.getRangeAt(0);
    }
    setShowLinkModal(true);
  }

  function insertLink() {
    const editor = document.getElementById("editorr");
    editor.focus();
    const url = linkUrl;
    const caption = linkCaption || url;
    const selection = window.getSelection();
    if (selection.rangeCount > 0 && !selection.isCollapsed) {
      const range = selection.getRangeAt(0);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.title = caption;
      anchor.appendChild(range.extractContents());
      range.insertNode(anchor);
      range.selectNode(anchor);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (savedSelectionRef.current) {
      const range = savedSelectionRef.current;
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.title = caption;
      anchor.textContent = caption;
      range.deleteContents();
      range.insertNode(anchor);
      range.setStartAfter(anchor);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      savedSelectionRef.current = null;
    } else {
      document.execCommand(
        "insertHTML",
        false,
        `<a href="${url}" title="${caption}">${caption}</a>`
      );
    }
    setShowLinkModal(false);
    setLinkUrl("");
    setLinkCaption("");
  }
  // --- End Link Handling ---

  function handleUnlink() {
    document.execCommand("unlink", false, null);
  }

  function FillURLYoutube(url) {
    const match =
      /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url);
    return match && match[2].length === 11 ? match[2] : null;
  }
  function FillURLVimeo(url) {
    const match = /vimeo.*\/(\d+)/i.exec(url);
    return match ? match[1] : null;
  }

  function insertYoutube(url) {
    const editorelement = document.getElementById("editorr");
    const iframe = document.createElement("iframe");
    if (url.includes("vimeo")) {
      const id = FillURLVimeo(url);
      iframe.src = `https://player.vimeo.com/video/${id}`;
    } else {
      const id = FillURLYoutube(url);
      iframe.src = `https://www.youtube.com/embed/${id}`;
    }
    iframe.width = "560";
    iframe.height = "315";
    iframe.frameBorder = "0";
    iframe.allowFullscreen = true;
    editorelement.append(iframe);
  }

  function changeTextColor(color) {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.style.color = color;
      span.appendChild(range.extractContents());
      range.insertNode(span);
    }
    setShowTextColors(false);
  }

  function changeHighlightColor(color) {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.style.backgroundColor = color;
      span.appendChild(range.extractContents());
      range.insertNode(span);
    }
    setShowHighlightColors(false);
  }

  function removeHighlightColor() {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const parent = range.commonAncestorContainer.parentElement;
      if (parent.style.backgroundColor) {
        const textNode = document.createTextNode(parent.textContent);
        parent.replaceWith(textNode);
      }
    }
  }

  function removeTextColor() {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      const parent = range.commonAncestorContainer.parentElement;
      if (parent.style.color) {
        const textNode = document.createTextNode(parent.textContent);
        parent.replaceWith(textNode);
      }
    }
  }

  // --- Emoji Insertion ---
  function onSelectEmoji(emoji) {
    const editorElem = document.getElementById("editorr");
    const selection = window.getSelection();
  
    // Restore the saved selection if available.
    if (savedSelectionRef.current) {
      selection.removeAllRanges();
      selection.addRange(savedSelectionRef.current);
    }
  
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      range.deleteContents();
      const node = document.createTextNode(emoji.native);
      range.insertNode(node);
      range.setStartAfter(node);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      editorElem.appendChild(document.createTextNode(emoji.native));
    }
    
    setEmIsOpen(false);
    savedSelectionRef.current = null; // Clear the saved selection
  }  
  // --- End Emoji Insertion ---

  useEffect(() => {
    const editorElem = document.getElementById("editorr");
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      if (selection.toString().length > 0) {
        selectionLocation.current = selection
          .getRangeAt(0)
          .getBoundingClientRect();
        setShowFloatingToolbar(true);
      } else {
        setShowFloatingToolbar(false);
      }
    };
    editorElem.addEventListener("mouseup", handleSelectionChange);
    editorElem.addEventListener("keyup", handleSelectionChange);
    return () => {
      editorElem.removeEventListener("mouseup", handleSelectionChange);
      editorElem.removeEventListener("keyup", handleSelectionChange);
    };
  }, []);

  useEffect(() => {
    const editorElem = targetNodeRef.current;
    function handleKeyDown(event) {
      if (event.key === "Enter") {
        const selection = window.getSelection();
        if (!selection.rangeCount) return;
        const range = selection.getRangeAt(0);
        let currentNode = range.commonAncestorContainer;
        if (currentNode.nodeType === Node.TEXT_NODE) {
          currentNode = currentNode.parentNode;
        }

        // Check if we're inside a checklist item by finding an <li> with class "checklist-item"
        const checklistItem = currentNode.closest("li.checklist-item");
        if (
          checklistItem &&
          checklistItem.parentNode &&
          checklistItem.parentNode.classList.contains("x-todo")
        ) {
          event.preventDefault();
          // Create a new checklist item in the same list
          const newListItem = document.createElement("li");
          newListItem.className = checklistItem.className; // "checklist-item"

          // Create a new checkbox
          const checkbox = document.createElement("input");
          checkbox.type = "checkbox";
          checkbox.checked = false;
          checkbox.setAttribute("contenteditable", "false");
          newListItem.appendChild(checkbox);

          // Add an empty text span (or text node)
          const textSpan = document.createElement("span");
          textSpan.appendChild(document.createTextNode(" "));
          newListItem.appendChild(textSpan);

          // Insert the new list item after the current one
          checklistItem.parentNode.insertBefore(
            newListItem,
            checklistItem.nextSibling
          );

          // Place the caret inside the new text span
          const newRange = document.createRange();
          newRange.setStart(textSpan, 0);
          newRange.collapse(true);
          selection.removeAllRanges();
          selection.addRange(newRange);

          // Add checkbox change listener
          checkbox.addEventListener("change", handleCheckboxChange);
          return;
        }

        // Fallback for non-checklist items (existing logic)
        const listItem = currentNode.closest("li");
        if (listItem) {
          event.preventDefault();
          if (listItem.innerHTML === "<br>") {
            const newPara = document.createElement("p");
            newPara.innerHTML = "<br>";
            listItem.parentNode.insertBefore(newPara, listItem.nextSibling);
            listItem.remove();
            const newRange = document.createRange();
            newRange.setStart(newPara, 0);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
          } else {
            const newListItem = document.createElement("li");
            newListItem.innerHTML = "<br>";
            listItem.parentNode.insertBefore(newListItem, listItem.nextSibling);
            const newRange = document.createRange();
            newRange.setStart(newListItem, 0);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
          }
        } else if (currentNode.closest("details")) {
          return;
        } else {
          // Handle Enter outside list items
          const currentBlock = currentNode.closest("p, h1, h2, h3, h4, h5, h6");
          if (currentBlock && /^H[1-6]$/.test(currentBlock.tagName)) {
            event.preventDefault();
            const newPara = document.createElement("p");
            newPara.innerHTML = "<br>";
            currentBlock.parentNode.insertBefore(
              newPara,
              currentBlock.nextSibling
            );
            const newRange = document.createRange();
            newRange.setStart(newPara, 0);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
          } else {
            event.preventDefault();
            let block = currentNode.closest("p");
            if (!block) {
              block = document.createElement("p");
              block.innerHTML = currentNode.innerHTML;
              currentNode.parentNode.replaceChild(block, currentNode);
            }
            const newPara = document.createElement("p");
            newPara.innerHTML = "<br>";
            block.parentNode.insertBefore(newPara, block.nextSibling);
            const newRange = document.createRange();
            newRange.setStart(newPara, 0);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
          }
        }
      }
    }
    editorElem.addEventListener("keydown", handleKeyDown);
    return () => editorElem.removeEventListener("keydown", handleKeyDown);
  }, []);

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  useEffect(() => {
    if (spokenText.length > 0) {
      const editorElem = document.getElementById("editorr");
      const p = document.createElement("p");
      p.textContent = spokenText;
      editorElem.appendChild(p);
      if (spokenText.toLowerCase().includes("in my tasks")) {
        let taskTitle = spokenText.trim().substring(4, spokenText.length - 62);
        const capitalizedTask = capitalize(taskTitle);
        createNewInboxTask(currentUser.uid, capitalizedTask, null);
        const utterance = new SpeechSynthesisUtterance(
          `Sure, I added ${taskTitle} to your tasks.`
        );
        speechSynthesis.speak(utterance);
      }
    }
  }, [spokenText]);

  // Function to insert table.
  function insertTable(rows, cols) {
    let tableHTML = `<table border="1" width="100%" style="border-collapse: collapse;">`;
    for (let i = 0; i < rows; i++) {
      tableHTML += "<tr>";
      for (let j = 0; j < cols; j++) {
        tableHTML +=
          "<td style='border: 1px solid black; padding: 4px;'> </td>";
      }
      tableHTML += "</tr>";
    }
    tableHTML += "</table>";
    document.getElementById("editorr").focus();
    document.execCommand("insertHTML", false, tableHTML);
    setShowTableModal(false);
  }

  // Function to insert embed.
  function insertEmbed() {
    document.getElementById("editorr").focus();
    if (embedCode.trim().startsWith("<")) {
      document.execCommand("insertHTML", false, embedCode);
    } else {
      document.execCommand(
        "insertHTML",
        false,
        `<iframe src="${embedCode}" width="560" height="315" frameborder="0" allowfullscreen></iframe>`
      );
    }
    setShowEmbedModal(false);
    setEmbedCode("");
  }

  return (
    <div className="anteditor" ref={editorContainerRef}>
      {mainToolbarOn && (
        <div style={{ display: "flex" }} className="anteditor_toolbar">
          <button onClick={handleUndo}>
            <HiOutlineArrowUturnLeft />
          </button>
          <button onClick={handleRedo}>
            <HiOutlineArrowUturnRight />
          </button>
          <button
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => setShowBlockOptions(!showBlockOptions)}
            id="blocktypeDropdown"
            className="drop-item"
          >
            <TfiParagraph />
            <TbChevronDown />
          </button>
          {showBlockOptions &&
            createPortal(
              <div
                ref={blockDropdownRef}
                className="dropdown"
                style={{
                  position: "absolute",
                  top: `${
                    document
                      .getElementById("blocktypeDropdown")
                      ?.getBoundingClientRect().top -
                    editorContainerRef.current.getBoundingClientRect().top +
                    80
                  }px`,
                  left: `${
                    document
                      .getElementById("blocktypeDropdown")
                      ?.getBoundingClientRect().left -
                    editorContainerRef.current.getBoundingClientRect().left -
                    62
                  }px`,
                }}
              >
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("p");
                    setShowBlockOptions(false);
                  }}
                >
                  <TfiParagraph /> Paragraph
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("h2");
                    setShowBlockOptions(false);
                  }}
                >
                  <span className="icon heading-2" /> Heading 2
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("h3");
                    setShowBlockOptions(false);
                  }}
                >
                  <span className="icon heading-3" /> Heading 3
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("h4");
                    setShowBlockOptions(false);
                  }}
                >
                  <span className="icon heading-4" /> Heading 4
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("h5");
                    setShowBlockOptions(false);
                  }}
                >
                  <span className="icon heading-5" /> Heading 5
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    handleInsertList("insertUnorderedList");
                    setShowBlockOptions(false);
                  }}
                >
                  <TbList /> Bulletlist
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleInsertList("insertOrderedList");
                    setShowBlockOptions(false);
                  }}
                >
                  <TbListNumbers /> Numberedlist
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleInsertChecklist();
                    setShowBlockOptions(false);
                  }}
                >
                  <TbListCheck /> Checklist
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("blockquote");
                    setShowBlockOptions(false);
                  }}
                >
                  <BsQuote /> Quote
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleChangeType("pre");
                    setShowBlockOptions(false);
                  }}
                >
                  <HiOutlineCodeBracket /> Codeblock
                </button>
              </div>,
              editorContainerRef.current || document.body
            )}
          <button onClick={toggleBold}>
            <TbBold />
          </button>
          <button onClick={toggleItalic}>
            <TbItalic />
          </button>
          <button onClick={toggleStrike}>
            <TbStrikethrough />
          </button>
          <button onClick={toggleUnderline}>
            <TbUnderline />
          </button>
          <button onClick={() => setShowTextColors(!showTextColors)}>
            <BsPaintBucket />
          </button>
          {showTextColors && (
            <ColorPalette key="text" returnColor={changeTextColor} />
          )}
          <button onClick={() => setShowHighlightColors(!showHighlightColors)}>
            <AiOutlineHighlight />
          </button>
          {showHighlightColors && (
            <ColorPalette key="highlight" returnColor={changeHighlightColor} />
          )}
          <button
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => {
              const sel = window.getSelection();
              if (sel.rangeCount > 0) {
                savedSelectionRef.current = sel.getRangeAt(0);
              }
              setEmIsOpen(!emisOpen);
            }}
            id="emojiBtn"
          >
            <BsEmojiSmile />
          </button>
          {emisOpen &&
            createPortal(
              <div
                style={{
                  position: "absolute",
                  top:
                    document.getElementById("emojiBtn").getBoundingClientRect()
                      .top >
                    window.innerHeight / 2
                      ? `${
                          document
                            .getElementById("emojiBtn")
                            .getBoundingClientRect().top - 440
                        }px`
                      : `${
                          document
                            .getElementById("emojiBtn")
                            .getBoundingClientRect().top + 30
                        }px`,
                  left: `${
                    document.getElementById("emojiBtn").getBoundingClientRect()
                      .left
                  }px`,
                  zIndex: "150",
                }}
              >
                <EmojiPicker onEmojiSelect={onSelectEmoji} />
              </div>,
              document.body
            )}
          <button onClick={handleLinkButtonClick}>
            <TfiLink />
          </button>
          <button
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => setShowAlignOptions(!showAlignOptions)}
            id="alignDropdown"
            ref={alignDropdownButtonRef}
            className="drop-item"
          >
            <TbAlignLeft />
            <TbChevronDown />
          </button>
          {showAlignOptions &&
            createPortal(
              <div
                ref={alignDropdownRef}
                className="dropdown"
                style={{
                  position: "absolute",
                  top: `${
                    alignDropdownButtonRef.current.getBoundingClientRect().top -
                    editorContainerRef.current.getBoundingClientRect().top +
                    80
                  }px`,
                  left: `${
                    alignDropdownButtonRef.current.getBoundingClientRect()
                      .left -
                    editorContainerRef.current.getBoundingClientRect().left -
                    62
                  }px`,
                }}
              >
                <button
                  className="item"
                  onClick={() => {
                    handleAlignCommands("left");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbAlignLeft />
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleAlignCommands("center");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbAlignCenter />
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleAlignCommands("right");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbAlignRight />
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleAlignCommands("justify");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbAlignJustified />
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    handleIndentation("indent");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbIndentIncrease />
                </button>
                <button
                  className="item"
                  onClick={() => {
                    handleIndentation("outdent");
                    setShowAlignOptions(false);
                  }}
                >
                  <TbIndentDecrease />
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    handleIndentation("insertHorizontalRule");
                    setShowAlignOptions(false);
                  }}
                >
                  <BsFileBreak />
                </button>
              </div>,
              editorContainerRef.current || document.body
            )}
          <button
            onMouseDown={(e) => e.stopPropagation()}
            onClick={() => setShowInsertOptions(!showInsertOptions)}
            ref={insertDropdownButtonRef}
            className="drop-item"
          >
            <TbPlus />
            <TbChevronDown />
          </button>
          {showInsertOptions &&
            createPortal(
              <div
                ref={insertDropdownRef}
                className="dropdown"
                style={{
                  position: "absolute",
                  top: `${
                    insertDropdownButtonRef.current.getBoundingClientRect()
                      .top -
                    editorContainerRef.current.getBoundingClientRect().top +
                    80
                  }px`,
                  left: `${
                    insertDropdownButtonRef.current.getBoundingClientRect()
                      .left -
                    editorContainerRef.current.getBoundingClientRect().left -
                    62
                  }px`,
                }}
              >
                <button
                  className="item"
                  onClick={() => {
                    setShowImageOptions(!showImageOptions);
                    setShowInsertOptions(false);
                  }}
                >
                  <BsCardImage /> Image
                </button>
                <button
                  className="item"
                  onClick={() => {
                    setShowLinkVidModal(true);
                    setShowInsertOptions(false);
                  }}
                >
                  <BsPlayBtn /> Video
                </button>
                <button
                  className="item"
                  onClick={() => {
                    setShowEmbedModal(true);
                    setShowInsertOptions(false);
                  }}
                >
                  <TfiLayoutMediaRightAlt /> Embed
                </button>
                <div className="divider"></div>
                <button
                  className="item"
                  onClick={() => {
                    createCollapsible();
                    setShowInsertOptions(false);
                  }}
                >
                  <HiPaintBrush /> Drawing ..
                </button>
                <button
                  className="item"
                  onClick={() => {
                    createCollapsible();
                    setShowInsertOptions(false);
                  }}
                >
                  <TbSquareToggleHorizontal /> Collapsible
                </button>
                <button
                  className="item"
                  onClick={() => {
                    setShowTableModal(true);
                    setShowInsertOptions(false);
                  }}
                >
                  <BsTable /> Table
                </button>
              </div>,
              editorContainerRef.current || document.body
            )}
        </div>
      )}
      {floatingToolbarOn &&
        showFloatingToolbar &&
        createPortal(
          <div
            className="anteditor_mini-toolbar"
            style={{
              top: `${selectionLocation?.current?.top - 85}px`,
              left: `${selectionLocation?.current?.left}px`,
            }}
          >
            <button onClick={toggleBold}>
              <TbBold />
            </button>
            <button onClick={toggleItalic}>
              <TbItalic />
            </button>
            <button onClick={() => handleChangeType("blockquote")}>
              <BsQuote />
            </button>
          </div>,
          document.body
        )}
      <div
        ref={targetNodeRef}
        id="editorr"
        contentEditable={true}
        className="anteditor_inner"
        placeholder={placeholder}
      />
      <AntListens setSpokenText={setSpokenText} />

      {/* Link Modal */}
      {showLinkModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowLinkModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="url"
              placeholder="Enter URL"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
            <input
              type="text"
              placeholder="Caption (optional)"
              value={linkCaption}
              onChange={(e) => setLinkCaption(e.target.value)}
            />
            <button onClick={insertLink}>Insert Link</button>
          </div>
        </div>
      )}

      {/* Table Modal */}
      {showTableModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowTableModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="number"
              placeholder="Rows"
              value={tableRows}
              onChange={(e) => setTableRows(e.target.value)}
            />
            <input
              type="number"
              placeholder="Columns"
              value={tableCols}
              onChange={(e) => setTableCols(e.target.value)}
            />
            <div
              className="table-preview"
              style={{
                margin: "8px 0",
                maxHeight: "150px",
                overflowY: "auto",
                border: "1px solid #ccc",
                padding: "4px",
              }}
            >
              {tableRows && tableCols && (
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  {Array.from({ length: parseInt(tableRows, 10) }).map(
                    (_, i) => (
                      <tr key={i}>
                        {Array.from({ length: parseInt(tableCols, 10) }).map(
                          (_, j) => (
                            <td
                              key={j}
                              style={{
                                border: "1px solid black",
                                padding: "4px",
                              }}
                            >
                              &nbsp;
                            </td>
                          )
                        )}
                      </tr>
                    )
                  )}
                </table>
              )}
            </div>
            <button
              onClick={() => {
                document.getElementById("editorr").focus();
                insertTable(parseInt(tableRows, 10), parseInt(tableCols, 10));
                setTableRows("");
                setTableCols("");
              }}
            >
              Insert Table
            </button>
          </div>
        </div>
      )}

      {/* Embed Modal */}
      {showEmbedModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowEmbedModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              placeholder="Paste embed code or URL"
              value={embedCode}
              onChange={(e) => setEmbedCode(e.target.value)}
            />
            <button
              onClick={() => {
                document.getElementById("editorr").focus();
                insertEmbed();
              }}
            >
              Insert Embed
            </button>
          </div>
        </div>
      )}

      {/* Image Modal */}
      {showImageOptions && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowImageOptions(false)}
          ></div>
          <div className="editor_imagelink">
            <button
              className="item"
              onClick={() =>
                setShowFileImgModal(!showFileImgModal) &
                setShowInsertOptions(false) &
                setShowLinkImgModal(false)
              }
            >
              Upload File
            </button>
            <button
              className="item"
              onClick={() =>
                setShowLinkImgModal(!showLinkImgModal) &
                setShowInsertOptions(false) &
                setShowFileImgModal(false)
              }
            >
              From URL
            </button>
          </div>
        </div>
      )}
      {showFileImgModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowFileImgModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="file"
              id="image-upload"
              name="image-upload"
              accept="image/*"
              onChange={(e) => setDocImgFile(e.target.files[0])}
            />
            <input
              type="text"
              name="alttext"
              placeholder="Alt Text"
              onChange={(e) => setDocImgFileAltText(e.target.value)}
            />
            <button onClick={onImageConfirmClick}>Insert Image</button>
          </div>
        </div>
      )}
      {showLinkImgModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowLinkImgModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="url"
              name="imagelink"
              placeholder="Image Link"
              autoFocus
              onChange={(e) => setImgFromWebLink(e.target.value)}
            />
            <input
              type="text"
              name="alttext"
              placeholder="Alt Text"
              onChange={(e) => setDocImgFileAltText(e.target.value)}
            />
            <button
              onClick={() =>
                createImageFromURL({
                  src: imgFromWebLink,
                  altText: docImgFileAltText,
                })
              }
            >
              Insert Image
            </button>
          </div>
        </div>
      )}
      {showLinkVidModal && (
        <div className="editor_modal">
          <div
            className="editor_modal-overlay"
            onClick={() => setShowLinkVidModal(false)}
          ></div>
          <div
            className="editor_imagelink"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="url"
              name="videolink"
              placeholder="Youtube or Vimeo Link"
              autoFocus
              onChange={(e) => setVidFromWebLink(e.target.value)}
            />
            <button onClick={() => insertYoutube(vidFromWebLink)}>
              Insert Video
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
