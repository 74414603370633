import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BoxLayer from "../retro/box-layer/box-layer.component";
import "./Paywall.scss";
import { BsPlusLg } from "react-icons/bs";
import { IoChevronBack } from "react-icons/io5";

function Paywall() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const urlparams = new URLSearchParams(window.location.search);
  const interestedInRef = urlparams.get("interested");

  const navigate = useNavigate();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showingPlan, setShowingPlan] = useState("pro");

  useEffect(() => {
    if (interestedInRef) {
      setShowingPlan(interestedInRef);
      setShowPaymentModal(true);
    }
  }, [interestedInRef]);

  // const currentURL =  window.frames['iframe'].location.href

  // useEffect(() => {
  //   const currentURL = document?.getElementById("iframe")?.src
  //   console.log(currentURL);
  // }, [showPaymentModal])

  const [iframeURL, setIframeURL] = useState("");
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    const currentURL = document?.getElementById("iframe")?.src;
    setIframeURL(currentURL);
  }, []);

  const refreshPage = () => {
    navigate(0);
  };

  // useEffect(() => {
  //   // const checkIfSuccess = iframeURL?.indexOf("success") !== -1;
  //   if (iframeURL?.indexOf("success") !== -1) {
  //     console.log("redirected to SUCCESS PAGE");
  //     setRedirected(true);
  //     if (!redirected) {
  //       setTimeout(() => {
  //         refreshPage();
  //       }, 1000);
  //     }
  //   }
  // }, [iframeURL]);

  return (
    <>
      {showPaymentModal && (
        <div className="paywall_container">
          <BoxLayer setLayer={setShowPaymentModal}>
            <div className="paywall_columns">
              <div className="paywall_columnleft">
                <div className="paywall_navheader">
                  {/* <button
                  className="paywall__exit__button"
                  onClick={() => setShowPaymentModal(false)}
                >
                  <IoChevronBack size="3.2em" />
                </button> */}
                  <a
                    className="paywall__exit__button"
                    // onClick={() => setShowPaymentModal(false)}
                    href="https://www.antlogy.com/#pricing"
                  >
                    <IoChevronBack size="2.5em" />
                  </a>
                  <img
                    // classname="antLogy__logo"
                    src="/antlogy_logo_full.png"
                    // height={20}
                    // width={190}
                    alt="Antlogy Logo"
                    style={{
                      objectFit: "contain",
                      maxWidth: 140,
                      height: 56,
                      marginRight: 10,
                    }}
                  />
                </div>
                <h3>
                  Let's step up <br></br> your game.
                </h3>
                <div className="paywall__plans">
                  <button
                    onClick={() => setShowingPlan("pro")}
                    className={
                      showingPlan === "pro" ? "selectedPlanOpt" : "planOption"
                    }
                  >
                    Ant Pro Plan
                  </button>
                  <ul>
                    <li>4 Workspaces</li>
                    <li>24 Projects</li>
                    <li>Unlimited Tasks</li>
                    <li>Unlimited Notes</li>
                    <li>Premium Features</li>
                  </ul>
                  <button
                    onClick={() => setShowingPlan("business")}
                    className={
                      showingPlan === "business"
                        ? "selectedPlanOpt"
                        : "planOption"
                    }
                  >
                    Business Plan
                  </button>
                  <ul>
                    <li>10 Workspaces</li>
                    <li>Unlimited Projects</li>
                    <li>Unlimited Tasks</li>
                    <li>Unlimited Notes</li>
                    <li>Premium Features</li>
                  </ul>
                </div>
              </div>
              <div className="paywall_columnright">
                {showingPlan === "pro" ? (
                  <iframe
                    src={
                      "https://pay.rev.cat/gcquznkgqlskznav/" +
                      currentUser.uid +
                      `?email=${currentUser.email}`
                    }
                    // width="100%"
                    height="724px"
                    id="iframe"
                  ></iframe>
                ) : (
                  <iframe
                    src={
                      "https://pay.rev.cat/yvqjztqguvklmerr/" +
                      currentUser.uid +
                      `?email=${currentUser.email}`
                    }
                    width="100%"
                    height="724px"
                  ></iframe>
                )}
              </div>
            </div>
          </BoxLayer>
        </div>
      )}
    </>
  );
}

export default Paywall;
