import React, { useState } from "react";
import "./progress-bar.styles.scss";

const ProgressBar = (props) => {
    const { bgcolor, completed, setProgress, progress, handleUpdateTaskProgress } = props;
    const [level, setLevel] = useState(progress);

    const maincontainerStyles = {
        height: 0,
        width: '100%',
        marginBottom: '20px',
    }

    const containerStyles = {
        height: 3,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 2,
        // margin: 2
    }

    const fillerStyles = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'all, 3s, ease'
    }

    const labelStyles = {
        display: 'inline',
        padding: 0,
        // position: 'absolute',
        // color: 'black',
        fontSize: '10px',
        fontWeight: 'bold',
        margin: 2,
        // marginLeft: '-24px',
    }

    const handleSetProgress = (e) => {
        e.preventDefault();
        // setLevel(e.target.value);
        console.log(e.target.value);
        handleUpdateTaskProgress(e.target.value);
    };

    // label {
    //     font-size: 10px;
    //     color: $primary;
    //     padding: 2px 12px;
    //   }

    return (
        <div style={maincontainerStyles}>
            <div className="progress__nodes">
                
                <input value={0} onClick={handleSetProgress}></input>
                <input value={5} onClick={handleSetProgress}></input>
                <input value={10} onClick={handleSetProgress}></input>
                <input value={15} onClick={handleSetProgress}></input>
                <input value={20} onClick={handleSetProgress}></input>
                <input value={25} onClick={handleSetProgress}></input>
                <input value={30} onClick={handleSetProgress}></input>
                <input value={35} onClick={handleSetProgress}></input>
                <input value={40} onClick={handleSetProgress}></input>
                <input value={45} onClick={handleSetProgress}></input>
                <input value={50} onClick={handleSetProgress}></input>
                <input value={55} onClick={handleSetProgress}></input>
                <input value={60} onClick={handleSetProgress}></input>
                <input value={65} onClick={handleSetProgress}></input>
                <input value={70} onClick={handleSetProgress}></input>
                <input value={75} onClick={handleSetProgress}></input>
                <input value={80} onClick={handleSetProgress}></input>
                <input value={85} onClick={handleSetProgress}></input>
                <input value={90} onClick={handleSetProgress}></input>
                <input value={95} onClick={handleSetProgress}></input>
            </div>
            <div style={containerStyles}>
                <div style={fillerStyles}>
                    {/* <span style={labelStyles}><p>progress</p>{`${completed}%`}</span> */}
                    <span className="labelStyles">
                        {/* <p>progress</p> */}
                        {`${progress}%`}</span>
                </div>
            </div>
        </div>
    );
};

export default ProgressBar;