import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTheme } from "../../redux/theme/theme.actions";
import { setEntitlement } from "../../redux/user/user.actions";
import {
  auth,
  updateUser,
  updateUserNotfSettings,
  storage,
} from "../../firebase/firebase.utils";

import "./user-profile.styles.scss";

import Avatar from "../retro/avatar/avatar.component";

import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroInput from "../retro/input/input.component";
import RetroButton from "../retro/button/retro-button.component";
import Loading from "../retro/loading/loading.component";
import { MdLogout } from "react-icons/md";
import {
  BsBookmarkPlus,
  BsBookmarkHeart,
  BsBookmarkCheck,
  BsBookmarkStar,
} from "react-icons/bs";
import AvatarCreator from "../avatar-creator/AvatarCreator";

import { Purchases } from "@revenuecat/purchases-js";

import useEntitlement from "../../hooks/useMembership";

const UserProfile = ({ currentUser }) => {

  const currentEntitlement = useSelector((state) => state.user.entitlement);

  const [memberShipUrl, setMembershipUrl] = useState(null);

  const [showPage, setShowPage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [notfSettings, setNotfSettings] = useState({
    commentAssignedMob: true,
    commentAssignedWeb: true,
    commentCreatedMob: true,
    commentCreatedWeb: true,
    meetingCancelMob: true,
    meetingCancelWeb: true,
    meetingCancelEmail: true,
    meetingMovedMob: true,
    meetingMovedWeb: true,
    meetingMovedEmail: true,
    meetingNewMob: true,
    meetingNewWeb: true,
    meetingNewEmail: true,
    meetingStartMob: true,
    meetingStartWeb: true,
    meetingStartEmail: true,
    noteAssignedMob: true,
    noteAssignedWeb: true,
    privateMsgMob: true,
    privateMsgWeb: true,
    projectMsgMob: true,
    projectMsgWeb: true,
    taskAssignedMob: true,
    taskAssignedWeb: true,
    taskEndMob: true,
    taskEndWeb: true,
    taskStartMob: true,
    taskStartWeb: true,
    workspaceMsgMob: true,
    workspaceMsgWeb: true,
  });
  const navigate = useNavigate();


  const { entitlement, loading } = useEntitlement();
  // console.log("ENTITLEMENT is,", entitlement);

  const CustomerInfo = async() => {
    try {
      const customerInfo = await Purchases.getSharedInstance().getCustomerInfo();
      console.log("Customer Info:", JSON.stringify(customerInfo, null, 2));
      // console.log("Customer entitlements", customerInfo.entitlements)
      let newEntitlement = "free";
      if (customerInfo.entitlements.active["antPro"]) {
        newEntitlement = "antPro";
        dispatch(setEntitlement('antPro'));
        // console.log("User is in antPro plan")
        }
      else if (customerInfo.entitlements.active["antBusiness"]) {
        newEntitlement = "antBusiness";
        dispatch(setEntitlement('antBusiness'));
      // console.log("User is in antBusiness plan")
      }
      console.log("User plan is", newEntitlement);
      let newMembUrl = null;
      if (customerInfo.managementURL) {
        newMembUrl = customerInfo.managementURL;
      }
      setMembershipUrl(newMembUrl);
      // access latest customerInfo
    } catch (e) {
     // Error fetching customer info
    }    
  }

  useEffect(() => {
    CustomerInfo();
  }, [currentUser]) 

  useEffect(() => {
    setNotfSettings(currentUser.notfSettings);
  }, [currentUser]);

  const [newUser, setNewUser] = useState({
    userName: currentUser?.userName,
    imageUrl: currentUser?.imageUrl,
  });

  useEffect(() => {}, [currentUser]);

  function handleLogout() {
    auth.signOut();
    navigate("/signin");
  }

  const handleSave = () => {
    try {
      updateUser(currentUser.uid, newUser);
      updateUserNotfSettings(currentUser.uid, notfSettings);
    } catch (error) {
      console.log(error.message);
    } finally {
      currentUser.userName = newUser.userName;
      currentUser.notfSettings = notfSettings;
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    const storageRef = storage.ref("profilePictures");
    const fileRef = storageRef.child(file.name);
    setShowLoading(true);
    await fileRef.put(file);
    const fileUrl = await fileRef.getDownloadURL();
    const newUser2 = {
      imageUrl: fileUrl,
    };
    updateUser(currentUser.uid, newUser2);
    setNewUser({ ...newUser, imageUrl: fileUrl });
    setShowLoading(false);
  };

  const body = document.body;

  // const [theme, setTheme] = useState("silver-theme");
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();

  if (theme === "dark") {
    body.classList.add("dark-mode");
  } else {
    body.classList.remove("dark-mode");
  }

  // function onChangeTheme(event) {
  //   if (event.target.value === "Craft") {
  //     dispatch(setTheme("craft-theme"));
  //     document.body.setAttribute("data-theme", "craft-theme");
  //     localStorage.setItem("selectedTheme", "craft-theme");
  //   }
  //   if (event.target.value === "Paper") {
  //     dispatch(setTheme("paper-theme"));
  //     document.body.setAttribute("data-theme", "paper-theme");
  //     localStorage.setItem("selectedTheme", "paper-theme");
  //   }
  //   if (event.target.value === "Rouge") {
  //     dispatch(setTheme("rouge-theme"));
  //     document.body.setAttribute("data-theme", "rouge-theme");
  //     localStorage.setItem("selectedTheme", "rouge-theme");
  //   }
  //   if (event.target.value === "Dark") {
  //     dispatch(setTheme("dark-theme"));
  //     document.body.setAttribute("data-theme", "dark-theme");
  //     localStorage.setItem("selectedTheme", "dark-theme");
  //   }
  // }

  function onChangeTheme(thm) {
    if (thm === "Craft") {
      dispatch(setTheme("craft-theme"));
      document.body.setAttribute("data-theme", "craft-theme");
      localStorage.setItem("selectedTheme", "craft-theme");
    }
    if (thm === "Paper") {
      dispatch(setTheme("paper-theme"));
      document.body.setAttribute("data-theme", "paper-theme");
      localStorage.setItem("selectedTheme", "paper-theme");
    }
    if (thm === "Rouge") {
      dispatch(setTheme("rouge-theme"));
      document.body.setAttribute("data-theme", "rouge-theme");
      localStorage.setItem("selectedTheme", "rouge-theme");
    }
    if (thm === "Dark") {
      dispatch(setTheme("dark-theme"));
      document.body.setAttribute("data-theme", "dark-theme");
      localStorage.setItem("selectedTheme", "dark-theme");
    }
  }

  const selectedTheme = localStorage.getItem("selectedTheme");

  return (
    <div className="userProfile">
      <div className="userProfile__icon" onClick={() => setShowPage(!showPage)}>
        <Avatar src={currentUser?.imageUrl} />
        <div className="userProfile__name">{currentUser?.userName}</div>
      </div>
      {showPage && (
        <BoxLayer setLayer={setShowPage}>
          <div className="userProfile__page">
            <div className="userProfile__heading">
              <h2>My Profile</h2>
              <div>
                <div className="userProfile__plan">
                  <div className="userProfile__planRow">
                    <p>Plan: <b>{currentEntitlement}</b></p>
                    {/* <p>Plan: {currentUser.plan}</p> */}
                    <button className="userProfile__badge">
                      {currentUser.plan === "Free" && (
                        <BsBookmarkPlus size={"1.2em"} />
                      )}
                      {currentUser.plan === "Freemium" && (
                        <BsBookmarkHeart size={"1.2em"} />
                      )}
                      {currentUser.plan === "Premium" && (
                        <BsBookmarkCheck size={"1.2em"} />
                      )}
                      {currentUser.plan === "Business" && (
                        <BsBookmarkStar size={"1.2em"} />
                      )}
                    </button>
                  </div>
                  {currentEntitlement === "free" ? (
                    <button
                      className="userProfile__manageMembership"
                      // href="https://www.antlogy.com/#pricing"
                      onClick={() => navigate("/?interested=pro")}
                      // target="_blank"
                    >
                      Upgrade
                    </button>
                  ) : <a
                  className="userProfile__manageMembership"
                  href={memberShipUrl}
                  target="_blank"
                >
                  Manage <br/> Membership
                </a>}
                </div>
              </div>
            </div>
            <div className="userProfile__info">
              <div>
              <div className="userProfile__info-avatar">
                {showLoading && <Loading />}
                <Avatar src={newUser.imageUrl} />
                <input
                  type="file"
                  className="userProfile__info-input"
                  onChange={(e) => handleUploadImage(e)}
                />
              </div>
              <AvatarCreator currentUser={currentUser} />
              </div>
              <div className="userProfile__info-user">
                <div>
                  <div className="userProfile__info-row">
                    <p>Name</p>
                    <RetroInput
                      value={newUser.userName}
                      onChange={(e) =>
                        setNewUser({ ...newUser, userName: e.target.value })
                      }
                    />
                  </div>
                  <div className="userProfile__info-row">
                    <p>Email</p>
                    <div className="userProfile__info-email">
                      <p>{currentUser.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="userSettings">
              <h3>Theme</h3>
              <div>
                {" "}
                <div
                  className="settingsTheme__buttongroup"
                  // onChange={onChangeTheme}
                >
                  {/* <div
                    className="settingsTheme__button theme__silver"
                    style={{
                      background:
                        selectedTheme === "silver-theme" ? "#cccccc" : "",
                    }}
                  >
                    <input type="radio" value="Silver" name="theme" checked={theme === "silver-theme"} />{" "}
                    <label>Silver</label>
                  </div> */}
                  <button
                    className="settingsTheme__button theme__craft"
                    onClick={() => onChangeTheme("Craft")}
                    style={{
                      background:
                        selectedTheme === "craft-theme" ? "#e7c078" : "",
                    }}
                  >
                    <input type="radio" value="Craft" name="theme" checked={theme === "craft-theme"}  />{" "}
                    <label>Craft</label>
                  </button>
                  <button
                    className="settingsTheme__button theme__paper"
                    onClick={() => onChangeTheme("Paper")}
                    style={{
                      background:
                        (selectedTheme === "paper-theme" || !selectedTheme) ? "#ebebeb" : "",
                    }}
                  >
                    <input type="radio" value="Paper" name="theme" checked={theme === "paper-theme" || !selectedTheme} />{" "}
                    <label>Paper</label>
                  </button>
                  <button
                    className="settingsTheme__button theme__rouge"
                    onClick={() => onChangeTheme("Rouge")}
                    style={{
                      background:
                        selectedTheme === "rouge-theme" ? "#ebebeb" : "",
                    }}
                  >
                    <input type="radio" value="Rouge" name="theme" checked={theme === "rouge-theme"} />{" "}
                    <label>Plum</label>
                  </button>
                  <button
                    className="settingsTheme__button theme__dark"
                    onClick={() => onChangeTheme("Dark")}
                    style={{
                      background:
                        selectedTheme === "dark-theme" ? "#1a1b1e" : "",
                      color: selectedTheme === "dark-theme" ? "white" : "",
                    }}
                  >
                    <input type="radio" value="Dark" name="theme" checked={theme === "dark-theme"} />{" "}
                    <label>Dark</label>
                  </button>
                </div>
              </div>
              <h3>Preferences</h3>
              <div>
                <table>
                  {/* <caption>Major settings</caption> */}
                  <tr>
                    <th></th>
                    <th scope="col">Web</th>
                    <th scope="col">Mobile</th>
                    <th scope="col">Email</th>
                  </tr>
                  <tr>
                    <th scope="row">Notifications</th>
                    <td>
                      <input
                        type="checkbox"
                        value={notfSettings}
                        checked={notfSettings}
                      />
                    </td>
                    <td>
                      <input type="checkbox" checked />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                  {/* <tr>
                      <th scope="row">Sounds</th>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>
                        <input type="checkbox" checked />
                      </td>
                    </tr> */}
                </table>
              </div>
              <details>
                <summary>Message notifications</summary>
                <div>
                  <table>
                    {/* <caption>Major settings</caption> */}
                    <tr>
                      <th></th>
                      <th scope="col">Web</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                    </tr>
                    <tr>
                      <th scope="row">Private messages</th>
                      <td>
                        <input
                          type="checkbox"
                          // value={notfSettings.privateMsgWeb}
                          checked={notfSettings.privateMsgWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              privateMsgWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          // value={notfSettings.privateMsgMob}
                          checked={notfSettings.privateMsgMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              privateMsgMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Project messages</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.projectMsgWeb}
                          checked={notfSettings.projectMsgWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              projectMsgWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.projectMsgMob}
                          checked={notfSettings.projectMsgMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              projectMsgMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Workspace messages</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.workspaceMsgWeb}
                          checked={notfSettings.workspaceMsgWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              workspaceMsgWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.workspaceMsgMob}
                          checked={notfSettings.workspaceMsgMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              workspaceMsgMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">New comment on a task assigned to you</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.commentAssignedWeb}
                          checked={notfSettings.commentAssignedWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              commentAssignedWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.commentAssignedMob}
                          checked={notfSettings.commentAssignedMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              commentAssignedMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">New comment on a task you created</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.commentCreatedWeb}
                          checked={notfSettings.commentCreatedWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              commentCreatedWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.commentCreatedMob}
                          checked={notfSettings.commentCreatedMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              commentCreatedMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </details>
              <details>
                <summary>Task & Note notifications</summary>
                <div>
                  <table>
                    {/* <caption>Major settings</caption> */}
                    <tr>
                      <th></th>
                      <th scope="col">Web</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                    </tr>
                    <tr>
                      <th scope="row">Task Assigned to you</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskAssignedWeb}
                          checked={notfSettings.taskAssignedWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskAssignedWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskAssignedMob}
                          checked={notfSettings.taskAssignedMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskAssignedMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Note Assigned to you</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.noteAssignedWeb}
                          checked={notfSettings.noteAssignedWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              noteAssignedWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.noteAssignedMob}
                          checked={notfSettings.noteAssignedMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              noteAssignedMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Task Start Time</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskStartWeb}
                          checked={notfSettings.taskStartWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskStartWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskStartMob}
                          checked={notfSettings.taskStartMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskStartMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Task End Time</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskEndWeb}
                          checked={notfSettings.taskEndWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskEndWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.taskEndMob}
                          checked={notfSettings.taskEndMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              taskEndMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </details>
              <details>
                <summary>Meeting notifications</summary>
                <div>
                  <table>
                    {/* <caption>Major settings</caption> */}
                    <tr>
                      <th></th>
                      <th scope="col">Web</th>
                      <th scope="col">Mobile</th>
                      <th scope="col">Email</th>
                    </tr>
                    <tr>
                      <th scope="row">
                        New meeting scheduled that you are an attendee
                      </th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingNewWeb}
                          checked={notfSettings.meetingNewWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingNewWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingNewMob}
                          checked={notfSettings.meetingNewMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingNewMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingNewEmail}
                          checked={notfSettings.meetingNewEmail}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingNewEmail: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Start of a meeting that you are an attendee
                      </th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingStartWeb}
                          checked={notfSettings.meetingStartWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingStartWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingStartMob}
                          checked={notfSettings.meetingStartMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingStartMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingStartEmail}
                          checked={notfSettings.meetingStartEmail}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingStartEmail: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <th scope="row">Cancelled meeting</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingCancelWeb}
                          checked={notfSettings.meetingCancelWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingCancelWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingCancelMob}
                          checked={notfSettings.meetingCancelMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingCancelMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingCancelMob}
                          checked={notfSettings.meetingCancelMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingCancelMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Postponed meeting</th>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingMovedWeb}
                          checked={notfSettings.meetingMovedWeb}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingMovedWeb: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingMovedMob}
                          checked={notfSettings.meetingMovedMob}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingMovedMob: e.target.checked,
                            })
                          }
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          value={notfSettings.meetingMovedEmail}
                          checked={notfSettings.meetingMovedEmail}
                          onChange={(e) =>
                            setNotfSettings({
                              ...notfSettings,
                              meetingMovedEmail: e.target.checked,
                            })
                          }
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </details>
            </div>
            <div className="userProfile__buttons">
              <div className="userProfile__logout">
                <RetroButton onClick={() => handleLogout()}>
                  <MdLogout size="1.5em" />
                  Logout
                </RetroButton>
              </div>
              <div className="userProfile__saveChanges">
                <RetroButton
                  mode={
                    newUser.userName === currentUser.userName ||
                    (notfSettings === currentUser.userName && "cancel")
                  }
                  onClick={() => handleSave()}
                >
                  Save Changes
                </RetroButton>
              </div>
            </div>
          </div>
        </BoxLayer>
      )}
    </div>
  );
};

export default UserProfile;
