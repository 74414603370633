import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { motion } from "framer-motion";
import "./mobile-menu.styles.scss";

import FocusApp from "../focus/focus.component";

import { FaChevronLeft, FaHome } from "react-icons/fa";
import {
  BsCalendar2Check,
  BsJournalBookmarkFill,
  BsArrowRepeat,
  BsChatLeftDots,
  BsStopwatch,
  BsRecordCircle,
} from "react-icons/bs";
import { IoChevronBack, IoLeafSharp, IoWaterSharp } from "react-icons/io5";

import RetroButton from "../retro/button/retro-button.component";

const MobileMenu = () => {
  const [homePage, setHomePage] = useState(false);
  const [showSpaceMenu, setShowSpaceMenu] = useState(false);
  const history = useNavigate();
  const location = useLocation();

  const [countdownVal, setCountdownVal] = useState("Hello World");
  const countdownRef = (timer) => {
    setCountdownVal(timer);
  };

  const [isOpen, SetIsOpen] = useState(false);
  const [x, setX] = useState(0);

  const handleTimerOpen = () => {
    // dispatch(setOpen(!open));
    SetIsOpen(!isOpen);
    // setTimerOpen(currentUser.uid, isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setX("55px");
    } else {
      setX("-300px");
    }
  }, [isOpen]);

  useEffect(() => {
    if (location.pathname !== "/") {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
  }, [location]);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className="mobileMenu">
      {/* {homePage && (
        <RetroButton onClick={() => history.goBack()}>
          <IoChevronBack />
        </RetroButton>
      )} */}
      {/* <RetroButton onClick={() => history.push("/")}>
        <IoLeafSharp strokeWidth="0.1" />
      </RetroButton> */}
      <HashLink to="/#minical" smooth scroll={scrollWithOffset}>
        <RetroButton>
          <BsCalendar2Check strokeWidth="0.1" />
        </RetroButton>
      </HashLink>
      <HashLink to="/#journal" smooth scroll={scrollWithOffset}>
        <RetroButton>
          <BsJournalBookmarkFill strokeWidth="0.1" />
        </RetroButton>
      </HashLink>
      <HashLink to="/#habits" smooth scroll={scrollWithOffset}>
        <RetroButton>
          <BsArrowRepeat strokeWidth="0.1" />
        </RetroButton>
      </HashLink>
      <RetroButton onClick={handleTimerOpen}>
        <div className="timer__hide-mobile">
          {!isOpen && countdownVal !== "25:00" ? <label>{countdownVal}</label> :  <BsStopwatch strokeWidth="0.1"/>}
        </div>
      </RetroButton>
      <motion.div
        className="Focus__timer"
        animate={{ left: x }}
        transition={{ ease: "easeOut" }}
      >
        {/* {isOpen && (
              <div>
                <div> */}
        <FocusApp
          countdownRef={countdownRef}
          setCountdownVal={setCountdownVal}
          isOpen={isOpen}
          handleTimerOpen={handleTimerOpen}
          style={{
            display: isOpen ? "flex" : "none",
          }}
        />
        {/* </div>
              </div>
            )} */}
      </motion.div>
      {/* {!homePage && (
        <RetroButton>
          <BsChatLeftDots />
        </RetroButton>
      )}
      {homePage && (
        <RetroButton>
          <BsChatLeftDots />
        </RetroButton>
      )} */}
      {/* <RetroButton onClick={() => history.push("/")}>
        <IoWaterSharp strokeWidth="0.1" />
      </RetroButton> */}
    </div>
  );
};

export default MobileMenu;
