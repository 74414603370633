import React, { useEffect, useState, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

import {
  db,
  updateTaskAsDone,
  fieldValue,
  deleteTask,
} from "../../../../firebase/firebase.utils";
import { useNavigate, useParams } from "react-router-dom";

import {
  personFilterFunction,
  statusFilterFunction,
  timeFilterFunction,
  setTaskClassDone,
  autoUpdateTasksToUser,
  filterLogicFunction,
} from "../../task.util";
import "./task-board.styles.scss";

// import TaskPullInfo from "../task-pull-info/task-pull-info.component";

import { ContextMenu } from "../../../context-menu/ContextMenu";
import { contextMenuItems } from "../../../context-menu/menus/contextMenuItems";

import LoadModule from "../../../modules/load-module.component.jsx/load-module.component";
import BoxRight from "../../../retro/box-right/box-right.component";
import LargeTask from "../../../large-task/large-task.component";
import CheckBox from "../../../retro/check-box/check-box.component";
import Subtasks from "../../subtasks/subtasks.component";

import { FiSquare, FiCheckSquare } from "react-icons/fi";

const TaskBoard = ({
  task,
  index,
  status,
  multiSelectMode,
  setMultiSelectMode,
  multiSelectItems,
  setMultiSelectItems,
  stationTasksFL,
}) => {
  const activeModules = useSelector((state) => state.space.activeModulesData);
  const users = useSelector((state) => state.user.users);
  const currentUser = useSelector((state) => state.user.currentUser);
  const filter = useSelector((state) => state.filter);

  const activeSpaceId = useSelector((state) => state.history.spaceId);
  const activeStationId = useSelector((state) => state.history.stationId);

  const [taskref, setTaskRef] = useState(false);
  const [taskdone, setTaskDone] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState("edit");
  const [activeEvent, setActiveEvent] = useState({});

  const [showLargeTask, setShowLargeTask] = useState(false);
  // const [statusFilter, setStatusFilter] = useState(true);
  // const [timeFilter, setTimeFilter] = useState(true);
  // const [personFilter, setPersonFilter] = useState(true);
  const [taskClass, setTaskClass] = useState("");
  // const [filterLogic, setFilterLogic] = useState(true);
  // const [msgs, setMsgs] = useState(0);

  const { taskId } = useParams();

  const [activeItem, setActiveItem] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [multiselectedItem, setMultiSelectedItem] = useState(false);

  const navigate = useNavigate();

  // const activeSpaceId = useSelector((state) => state.history.spaceId);
  // const activeStationId = useSelector((state) => state.history.stationId);

  // const [taskref, setTaskRef] = useState();

  // useEffect(() => {
  //   db.collection("space")
  //     .doc("mKXpX3ffMATbwmApThhO")
  //     .collection("stations")
  //     .doc("gZOhrZfh4XfraVCnY21j")
  //     .collection("tasks")
  //     .doc(task)
  //     .onSnapshot((taskitem) => {
  //       let currenttask = taskitem.data();
  //       setTaskRef(currenttask);
  //     });
  // });

  // useEffect(() => {
  //   setStatusFilter(statusFilterFunction(task, filter));
  //   setTimeFilter(timeFilterFunction(task, filter));
  //   setPersonFilter(personFilterFunction(task, filter));
  // }, [task, filter]);

  useEffect(() => {
    db.collection("space")
      .doc(activeSpaceId)
      .collection("stations")
      .doc(activeStationId)
      .collection("tasks")
      .doc(task)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let currenttask = doc.data();
          setTaskRef(currenttask);
          setTaskDone(currenttask.done);
        }
      });
  }, [task]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMode("");
    setActiveEvent({});
  };

  const handleOpenModal = (mode, event) => {
    // setModalMode(mode);
    // setActiveEvent(event);
    // setModalIsOpen(true);
    navigate(`task/${event.id}`);
  };

  const handleEventClick = (taskref) => {
    // console.log("id of the selected task:", task);
    if (multiSelectMode) {
      console.log("we are in multiselectmode");
      if (!multiSelectItems.includes(taskref)) {
        multiSelectItems.push(taskref);
        setMultiSelectedItem(true);
      } else {
        const index = multiSelectItems.indexOf(taskref);
        if (index > -1) {
          // only splice array when item is found
          multiSelectItems.splice(index, 1); // 2nd parameter means remove one item only
        }
        setMultiSelectedItem(false);
      }
    } else if (taskref.startdate === null)
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startdate: taskref.startdate,
          enddate: taskref.enddate,
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          assign: taskref.assign,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
    else
      handleOpenModal(
        "edit",
        {
          id: taskref.id,
          title: taskref.title,
          description: taskref.description,
          done: taskref.done,
          startdate: taskref.startdate.toDate(),
          enddate: taskref.enddate.toDate(),
          allDay: taskref.allDay,
          duration: taskref.duration,
          done: taskref.done,
          createdBy: taskref.createdBy,
          createdAt: taskref.createdAt,
          assign: taskref.assign,
          phase: taskref.phase,
          priority: taskref.priority,
          progress: taskref.progress,
          type: taskref.type,
          fromSpaceId: taskref.fromSpaceId,
          fromStationId: taskref.fromStationId,
          subtasks: taskref.subtasks,
        }
        // events.find((e) => e.id.toString() === id.toString())
      );
    console.log("multiselectitems are;", multiSelectItems);
  };

  useEffect(() => {
    autoUpdateTasksToUser(users, task);
    setTaskClass(setTaskClassDone(task));
  }, [task]);

  // useEffect(() => {
  //   setFilterLogic(filterLogicFunction(statusFilter, timeFilter, personFilter));
  // }, [statusFilter, timeFilter, personFilter]);

  const taskrefinfo = {
    fromSpaceId: activeSpaceId,
    fromStationId: activeStationId,
    id: task,
    done: !taskdone,
  };

  const handleCheck = () => {
    document.getElementById("done_tone").play();
    if (taskdone) {
      updateTaskAsDone(activeSpaceId, activeStationId, task, false);
    } else {
      updateTaskAsDone(activeSpaceId, activeStationId, task, true);
    }
    if (taskref.assign) {
      const assignedUserRef = db.collection("users").doc(taskref.assign);
      assignedUserRef.get().then((userRefData) => {
        let assignedTasks = userRefData.data().assignedTasks;
        const findIndex = assignedTasks.findIndex((item) => item.id === task);
        assignedUserRef.update({
          assignedTasks: fieldValue.arrayRemove(assignedTasks[findIndex]),
        });
        assignedUserRef.update({
          assignedTasks: fieldValue.arrayUnion(taskrefinfo),
        });
      });
    }
  };

  const onRightClick = (event, taskref) => {
    event.preventDefault();
    const top = event.clientY;
    const left = event.clientX;

    setActiveItem(taskref);
    setShowContextMenu(true);
    setContextMenuPosition({ top, left });
  };

  const onContextMenuItemClick = (menuItemLabel) => {
    switch (menuItemLabel) {
      case "Multiselect":
        console.log("clicled MultiSelect");
        setMultiSelectMode(!multiSelectMode);
        console.log("MultiSelect Mode is", multiSelectMode);
        // setListData(listData.filter((item) => item.id !== activeItem));
        break;
      case "Delete":
        console.log("clicled Delete");
        // setListData(listData.filter((item) => item.id !== activeItem));
        if (multiSelectMode) {
          multiSelectItems.forEach((item) => {
            deleteTask(
              item.fromSpaceId,
              item.fromStationId,
              item.phase,
              currentUser.uid,
              item
            );
          });
        } else
          deleteTask(
            activeItem.fromSpaceId,
            activeItem.fromStationId,
            activeItem.phase,
            currentUser.uid,
            activeItem
          );
        break;
      case "Copy":
        console.log("Copy done");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const closeContextMenu = () => setShowContextMenu(false);
    window.addEventListener("click", closeContextMenu);
    return () => window.removeEventListener("click", closeContextMenu);
  }, []);

  const variants = {
    open: {
      position: "fixed",
      opacity: 1,
      width: "360px", 
      height: "calc(100vh - 121px)",
      top: "60px", 
      borderRadius: "6px", 
      left: "calc(100vw - 360px)", 
      backgroundColor: "#fff",
      borderRadius: "6px",
      zIndex: 50,
    },
    closed: {
      position: "fixed",
      width: "240px", 
      height: "60px", 
      opacity: 0,
      backgroundColor: "#cccccc",
      borderRadius: "6px",
    },
  };

  return (
    <Draggable draggableId={task} index={index}>
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          opacity: status.open ? "1" : "0",
          height: status.open ? "auto" : "10px",
        };
        return (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            className="task__logic"
          >
            <motion.div
              // className="board_animation-card"
              initial={false}
              animate={taskId === task ? "open" : "closed"}
              variants={variants}
              transition={{ type: "ease-in-out", duration: 0.5 }}
            />
            {stationTasksFL.findIndex((item) => item.id === task) !== -1 && (
              <div
                className={`task ${taskClass}`}
                {...provided.dragHandleProps}
                style={{
                  border:
                    (taskId === task || multiselectedItem) && "1px solid #34b5e4",
                  background:
                    (taskId === task || multiselectedItem) &&
                    " rgba(52, 181, 228, 0.05)",
                }}
              >
                <div
                  // task={task}
                  className="task__expandPlace"
                  onClick={() => handleEventClick(taskref)}
                  onContextMenu={(event) => onRightClick(event, taskref)}
                />
                <div className="task__header">
                  <div className="task__taskName">
                    {/* <p>{task}</p> */}
                    <div
                      className={
                        taskref.done ? "task__card-done" : "task__card"
                      }
                    >
                      {/* <input type="checkbox"></input> */}
                      <i onClick={handleCheck}>
                        {taskref.done ? <FiCheckSquare /> : <FiSquare />}
                      </i>
                      <audio
                        id="done_tone"
                        src="/media/mixkit-cool-interface-click-tone-2568.wav"
                      ></audio>
                      <p>{taskref.title}</p>
                    </div>
                    {/* <TaskPullInfo task={task} /> */}
                  </div>
                  {/* <CheckBox task={task} style={{ opacity: task.done ? 1 : 0 }} /> */}
                </div>
                <Subtasks task={taskref} />
                <div className="task__modules">
                  {activeModules?.map((module) => {
                    return (
                      <LoadModule
                        module={module}
                        key={module.name}
                        task={taskref}
                        style="box"
                      />
                    );
                  })}
                </div>
                {showContextMenu && (
                  <ContextMenu
                    top={contextMenuPosition.top}
                    left={contextMenuPosition.left}
                    menuItems={contextMenuItems}
                    onMenuItemClick={onContextMenuItemClick}
                  />
                )}
              </div>
            )}
            {/* {filterLogic && ( */}

            {/* )} */}
            {/* <AnimatePresence>
              {showLargeTask && (
                <BoxRight setLayer={setShowLargeTask}>
                  <LargeTask
                    task={task}
                    // msgs={msgs}
                  />
                </BoxRight>
              )}
            </AnimatePresence> */}
            {/* <AnimatePresence> */}
            {/* </AnimatePresence> */}
          </div>
        );
      }}
    </Draggable>
  );
};

export default TaskBoard;
