import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import RetroButton from "../retro/button/retro-button.component";
import CreateSpace from "../create-space/create-space.component";

import InvitetoPremium from "../marketing/invite-to-premium.component";

import "./space-data.styles.scss";

import { FaPlusSquare } from "react-icons/fa";

function SpaceData() {
  const spaceData = useSelector((state) => state.space.spaceData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const userEntitlement = useSelector((state) => state.user.entitlement);
  const [createNewSpace, setCreateNewSpace] = useState(false);

  const [showInvitation, setShowInvitation] = useState(false);

  const handleCheckEntitlement = () => {
    if (userEntitlement === "free") {
      if (spaceData?.length < 1) {
        setCreateNewSpace(true);
      } else {
        // show invitation
        setShowInvitation(true);
      }
    } else if (userEntitlement === "antPro") {
      if (spaceData?.length < 4) {
        setCreateNewSpace(true);
      } else {
        setShowInvitation(true);
      }
    } else if (userEntitlement === "antBusiness") {
      if (spaceData?.length < 10) {
        setCreateNewSpace(true);
        // setShowInvitation(true);
      } else {
        setShowInvitation(true);
      }
    }
  };

  return (
    <div className="spaceData">
      <div className="sd__data">
        {spaceData.map((data) => {
          const { spaceId, color, name } = data;
          function changeBackground(e) {
            e.target.style.background = color;
          }
          function removeBackground(e) {
            e.target.style.background = "none";
          }
          return (
            <Link to={`/s/${data.spaceId}`}>
              <div key={spaceId} className="sd__btn">
                <div
                  className="sd__btnClick"
                  onMouseOver={changeBackground}
                  onMouseOut={removeBackground}
                >
                  {/* <RetroButton style={{ background: color }}>
                    {name.charAt(0)}
                  </RetroButton> */}
                  <h4 style={{ textDecoration: `underline ${color} 2px` }}>
                    {name}
                  </h4>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
      <div className="sd__createNewSpace">
        <RetroButton mode="create" onClick={() => handleCheckEntitlement()}>
          Create new Workpace
        </RetroButton>
      </div>
      {showInvitation && (
        <InvitetoPremium
          setShowInvitation={setShowInvitation}
          featureName={`Creating more than ${
            userEntitlement === "free"
              ? "1"
              : userEntitlement === "antPro"
              ? "4"
              : "10"
          } workspaces`}
          featureDesc={
            "organize your projects and ideas efficiently with multiple workspaces"
          }
          featureImg={
            "https://images.pexels.com/photos/3184423/pexels-photo-3184423.jpeg"
          }
          currentUser={currentUser}
        />
      )}
      <AnimatePresence>
        {createNewSpace && <CreateSpace setLayer={setCreateNewSpace} />}
      </AnimatePresence>
    </div>
  );
}

export default SpaceData;
