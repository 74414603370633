import React, { useEffect, useRef } from "react";
import Gantt from "./frappe-gantt";

export default function GanttChart({ tasks, viewMode, onDateChange }) {
  const ref = useRef();
  const chart = useRef();

  function onViewChange(mode) {
    console.log(mode);
  }

  // function onDateChange(task, start, end) {
  //   console.log(task, start, end);
  // }

  function onProgressChange(task, progress) {
    console.log(task, progress);
  }

  function onClick(task) {
    console.log(task);
  }

  function popup(task) {
    const end_date = task._end.format("MMM D");
    return `
        <div class="details-container">
          <h5>${task.name}</h5>
          <p>Expected to finish by ${end_date}</p>
          <p>${task.progress}% completed!</p>
        </div>
      `;
  }

  useEffect(() => {
    if (chart.current) return;

    const gantt = new Gantt(ref.current, tasks, {
      on_view_change: onViewChange,
      on_date_change: onDateChange,
      on_progress_change: onProgressChange,
      on_click: onClick,
      view_mode: viewMode,
    });
  }, [tasks, ref, viewMode]);

  return <svg ref={ref} xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"></svg>;
}